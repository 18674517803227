
import { useRef, useState } from 'react';

const useCalendar = () => {
  const calendarRef = useRef(null);

  const [scheduleView, setScheduleView] = useState('month');
  const [currentDate, setCurrentDate] = useState(new Date());

  const handlePrevious = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      setCurrentDate(calendarApi.getDate());
    }
  };

  const handleNext = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      setCurrentDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView) => {
    setScheduleView(newView);
    if (calendarRef.current) {
      calendarRef.current.getApi().changeView(
        newView === 'week' ? 'timeGridWeek' : 'dayGridMonth'
      );
    }
  };

  return {
    calendarRef,
    scheduleView,
    currentDate,
    handlePrevious,
    handleNext,
    handleViewChange,
  };
};

export default useCalendar;
