// Module dependencies
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useBasicModal } from './useBasicModal';
import {
  getDocuments,
  uploadDocument,
  deleteDocument,
  getDocumentUrl,
} from '../features/company/companySlice';

// Utils
import wording from 'utils/wording';

const {
  DOCUMENTS_DOWNLOAD_FAIL,
  DOCUMENTS_DOWNLOAD_SUCCESS,
  DOCUMENTS_DOWNLOADING,
  DOCUMENTS_UPLOAD_FAIL,
  DOCUMENTS_UPLOAD_SUCCESS,
  DOCUMENTS_DELETE_FAIL,
  DOCUMENTS_DELETE_SUCCESS,
 } = wording;

const useCompanyDocuments = () => {
  const dispatch = useDispatch();
  const {
    documents,
    isLoading,
    isUploadingDocument,
    isDeletingDocument,
  } = useSelector(
    (state) => state.company
  );

  const { register, handleSubmit, formState: { isValid }, reset } = useForm();

  const [documentToDelete, setDocumentToDelete] = useState(null);

  const {
    showModal: showUploadDialog,
    handleOpenModal: handleOpenUploadModal,
    handleCloseModal: handleCloseUploadModal,
  } = useBasicModal();

  const {
    showModal: showDeleteDocumentModal,
    handleOpenModal: handleOpenDeleteDocumentModal,
    handleCloseModal: handleCloseDeleteDocumentModal,
  } = useBasicModal();

  useEffect(() => {
    dispatch(getDocuments());
  }, [dispatch]);

  const onSubmit = async ({ files, description }) => {
    const file = files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('description', description);

      await dispatch(
        uploadDocument(formData)
      ).unwrap();

      toast.success(DOCUMENTS_UPLOAD_SUCCESS);

      dispatch(getDocuments());

      reset();

      handleCloseUploadModal();
    } catch (error) {
      toast.error(DOCUMENTS_UPLOAD_FAIL);
    }
  };

  const handleDeleteButtonClick = (document) => {
    setDocumentToDelete(document);

    handleOpenDeleteDocumentModal();
  };

  const handleDelete = async () => {
    try {
      await dispatch(
        deleteDocument(documentToDelete._id)
      ).unwrap();

      toast.success(DOCUMENTS_DELETE_SUCCESS);

      dispatch(getDocuments());

      handleCloseDeleteDocumentModal();
    } catch (error) {
      toast.error(DOCUMENTS_DELETE_FAIL);
    }
  };

  const handleDownload = async (documentId, fileName) => {
    try {
      toast.success(DOCUMENTS_DOWNLOADING);

      const { url } = await dispatch(
        getDocumentUrl(documentId)
      ).unwrap();

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success(DOCUMENTS_DOWNLOAD_SUCCESS)
    } catch (error) {
      toast.error(DOCUMENTS_DOWNLOAD_FAIL);
    }
  };

  return {
    documents,
    isLoading,
    isUploadingDocument,
    isDeletingDocument,
    handleDelete,
    handleDownload,

    // Upload
    register,
    handleSubmit,
    onSubmit,
    isValid,
    showUploadDialog,
    handleOpenUploadModal,
    handleCloseUploadModal,

    // Delete
    showDeleteDocumentModal,
    handleOpenDeleteDocumentModal,
    handleCloseDeleteDocumentModal,
    handleDeleteButtonClick,
    documentToDelete,
  };
};

export default useCompanyDocuments;
