import { createSlice } from "@reduxjs/toolkit";

const initialUIState = {
  isDarkMode: (() => {
    const storedTheme = localStorage.getItem("nimax_theme");

    const isDark = storedTheme === "dark" ||
    (!storedTheme &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)

    const html = document.documentElement;

    if (isDark) {
      html.classList.add("dark");
    } else {
      html.classList.remove("dark");
    }

    return isDark;
  })(),
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialUIState,
  reducers: {
    toggleDarkMode: (state) => {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem("nimax_theme", state.isDarkMode ? "dark" : "light");
      const html = document.documentElement;
      if (state.isDarkMode) {
        html.classList.add("dark");
      } else {
        html.classList.remove("dark");
      }
    },
    setDarkMode: (state, action) => {
      state.isDarkMode = action.payload;
      localStorage.setItem("nimax_theme", action.payload ? "dark" : "light");
      const html = document.documentElement;
      if (action.payload) {
        html.classList.add("dark");
      } else {
        html.classList.remove("dark");
      }
    },
  },
});

export const { toggleDarkMode, setDarkMode } = uiSlice.actions;
export default uiSlice.reducer;
