// UI
import { Text } from 'components/catalyst/text';

// Utils
import wording from 'utils/wording';

const Copyright = () => {
  const { COPYRIGHT_TEXT } = wording;

  const currentYear = new Date().getFullYear();

  return (
    <div className="mt-8">
      <Text className='text-center'>
        {COPYRIGHT_TEXT(currentYear)}
      </Text>
    </div>
  );
};

export default Copyright;
