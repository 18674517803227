// Module dependencies
import { Controller } from "react-hook-form";

// UI
import { Checkbox, CheckboxField } from "components/catalyst/checkbox";
import { ErrorMessage, Field, Fieldset, Label, Legend } from "components/catalyst/fieldset";
import { Strong } from "components/catalyst/text";
import { Textarea } from "components/catalyst/textarea";

// Utils
import { areasList } from "./utils";
import wording from "utils/wording";

const AreasOfConcernForm = ({
  register,
  control,
  errors,
  validateAreas,
}) => {
  const {
    CREATE_WO_AREAS_OF_CONCERN,
    CREATE_WO_OTHER_CONCERNS,
    CREATE_WO_OTHER_CONCERNS_PLACEHOLDER,
  } = wording;

  return (
    <div className="space-y-4">
      <Strong>
        {CREATE_WO_AREAS_OF_CONCERN}
      </Strong>

      <Fieldset>
        <Legend className='!font-medium pb-3'>Areas</Legend>
        <div className="grid grid-cols-2 gap-2">
          {areasList.map(({ label, value }) => (
            <CheckboxField key={label}>
              <Controller
                name={value}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value}
                    onChange={(checked) => field.onChange(checked)}
                  />
                )}
              />

              <Label className='capitalize'>{label}</Label>
            </CheckboxField>
          ))}
        </div>
      </Fieldset>

      <Field>
        <Label>
          {CREATE_WO_OTHER_CONCERNS}
        </Label>

        <Textarea 
          rows={3}
          placeholder={CREATE_WO_OTHER_CONCERNS_PLACEHOLDER}
          {...register('areaOfConcern.other')}
        />

        {errors.areaOfConcern?.other && (
          <ErrorMessage>{errors.areaOfConcern.other.message}</ErrorMessage>
        )}
      </Field>
    </div>
  );
};

export default AreasOfConcernForm;
