// UI
import { Heading } from "components/catalyst/heading";
import { Button } from "components/catalyst/button";
import { MapPinIcon, CalendarDaysIcon, PlusIcon } from '@heroicons/react/24/outline';

// Utils
import wording from "utils/wording";

const WorkOrdersHeader = ({
  handleCreateWorkOrder,
  handleViewGlobalSchedule,
  handleViewPendingLocations,
}) => {
  const { 
    WORK_ORDERS_TITLE,
    WORK_ORDERS_SHOW_PENDING_LOCATIONS,
    WORK_ORDERS_GLOBAL_SCHEDULE,
    WORK_ORDERS_CREATE_BUTTON,
  } = wording;

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
      <Heading>{WORK_ORDERS_TITLE}</Heading>
      
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 w-full sm:w-fit">
        <Button
          outline
          onClick={handleViewPendingLocations}
          className='w-full sm:w-fit'
        >
          <MapPinIcon className="w-5 h-5" />

          {WORK_ORDERS_SHOW_PENDING_LOCATIONS}
        </Button>

        <Button
          outline
          onClick={handleViewGlobalSchedule}
          className='w-full sm:w-fit'
        >
          <CalendarDaysIcon className="w-5 h-5" />

          {WORK_ORDERS_GLOBAL_SCHEDULE}
        </Button>

        <Button
          onClick={handleCreateWorkOrder}
          className='cursor-pointer'
          color='green'
        >
          <PlusIcon />
          {WORK_ORDERS_CREATE_BUTTON}
        </Button>
      </div>
    </div>
  );
};

export default WorkOrdersHeader;
