import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { companyService } from './companyService';
import { getErrorMessage } from 'utils/commons';

const initialState = {
  companyDetails: null,
  documents: [],
  isLoading: false,
  isUploadingDocument: false,
  isDeletingDocument: false,
  isError: false,
  isSuccess: false,
  message: '',
};

export const getCompanyDetails = createAsyncThunk(
  'company/getDetails',
  async (_, thunkAPI) => {
    try {
      return await companyService.getCompanyDetails();
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateCompany = createAsyncThunk(
  'company/update',
  async (companyData, thunkAPI) => {
    try {
      return await companyService.updateCompany(companyData);
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const uploadDocument = createAsyncThunk(
  'company/uploadDocument',
  async (formData, thunkAPI) => {
    try {
      return await companyService.uploadDocument(formData);
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getDocuments = createAsyncThunk(
  'company/getDocuments',
  async (_, thunkAPI) => {
    try {
      return await companyService.getDocuments();
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteDocument = createAsyncThunk(
  'company/deleteDocument',
  async (documentId, thunkAPI) => {
    try {
      return await companyService.deleteDocument(documentId);
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getDocumentUrl = createAsyncThunk(
  'company/getDocumentUrl',
  async (documentId, thunkAPI) => {
    try {
      return await companyService.getDocumentUrl(documentId);
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorMessage(error));
    }
  }
);

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.companyDetails = action.payload;
      })
      .addCase(getCompanyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.companyDetails = action.payload;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(uploadDocument.pending, (state) => {
        state.isUploadingDocument = true;
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.isUploadingDocument = false;
        state.isSuccess = true;
        state.documents.push(action.payload);
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.isUploadingDocument = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDocuments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.documents = action.payload;
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteDocument.pending, (state) => {
        state.isDeletingDocument = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.isDeletingDocument = false;
        state.isSuccess = true;
        state.documents = state.documents.filter(
          (doc) => doc._id !== action.meta.arg
        );
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.isDeletingDocument = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDocumentUrl.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocumentUrl.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(getDocumentUrl.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = companySlice.actions;
export default companySlice.reducer;
