// UI
import { BadgeButton } from 'components/catalyst/badge';
import { forwardRef } from 'react';

const StatusBadge = forwardRef(
  ({status}, ref) => {
    const getStatusColor = (status) => {
      const statusColors = {
        done: 'green',
        pending: 'yellow',
        sold: 'blue',
        'pre-construction': 'purple',
        permitting: 'orange',
        construction: 'indigo',
        completed: 'green',
        'in-progress': 'blue',
        cancelled: 'red',
        'on-schedule': 'purple',
        high: 'red',
        medium: 'yellow',
        low: 'green',
      };
    
      return statusColors[status?.toLowerCase()] || 'zinc';
    };
  
    return (
      <BadgeButton
        color={getStatusColor(status)}
        className='capitalize'
        ref={(ref) => {
          if (!ref) return;

          ref.onclick = (e) => {
            e.stopPropagation();
            e.preventDefault();
          };
        }}
      >
        {String(status) || '-'}
      </BadgeButton>
    );
  }
);

export default StatusBadge;
