// Module dependencies
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from 'features/auth/authSlice';

const useLogin = () => {
  const { isLoading, isError, message } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(login(formData));
  
    if (!result.error) {
      navigate('/dashboard');
    }
  };

  return {
    formData,
    handleInputChange,
    handleSubmit,
    isLoading,
    isError,
    message,
  };
};

export default useLogin;
