// Moduel dependencies
import { useSelector } from 'react-redux';
import { useBasicModal } from 'hooks/useBasicModal';

// UI
import { Button } from 'components/catalyst/button';
import { SidebarItem } from 'components/catalyst/sidebar';
import LogoutModal from 'components/LogoutModal';
import { Avatar } from 'components/catalyst/avatar';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from 'components/catalyst/dropdown'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
  UserIcon,
  KeyIcon,
} from '@heroicons/react/16/solid'
import ChangePasswordModal from 'components/auth/ChangePasswordModal';

// Utils
import wording from 'utils/wording';

const UserMenu = ({ type }) => {
  const { user } = useSelector((state) => state.auth);

  const {
    showModal,
    handleCloseModal,
    handleOpenModal,
  } = useBasicModal();

  const {
    showModal: showChangePassModal,
    handleCloseModal: handleCloseChangePassModal,
    handleOpenModal: handleOpenChangePassModal,
  } = useBasicModal();

  const {
    SIDEBAR_MY_PROFILE,
    SIDEBAR_SIGN_OUT,
    PASSWORD_CHANGE_BUTTON,
  } = wording;

  return (
    <>
      <Dropdown>
        <DropdownButton as={type === "sidebar" ? SidebarItem : Button} plain={type === "sidebar" ? undefined : true}>
          <span className="flex min-w-0 items-center gap-3">
            <Avatar className="size-10 p-2" square alt="">
              <UserIcon className="text-zinc-500 dark:text-zinc-400" />
            </Avatar>

            {type === "sidebar" && (
              <span className="min-w-0">
                <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                  {`${user?.user?.firstName} ${user?.user?.lastName}`}
                </span>

                <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                  {user?.user?.email}
                </span>
              </span>
            )}
          </span>

          {type === "sidebar" && <ChevronUpIcon />}    
        </DropdownButton>

        <DropdownMenu className="min-w-64" anchor="top start">
          <DropdownItem href="/my-profile" disabled>
            <UserIcon />
            <DropdownLabel>{SIDEBAR_MY_PROFILE}</DropdownLabel>
          </DropdownItem>

          <DropdownItem onClick={handleOpenChangePassModal}>
            <KeyIcon />
            <DropdownLabel>{PASSWORD_CHANGE_BUTTON}</DropdownLabel>
          </DropdownItem>

          <DropdownDivider />

          <DropdownItem onClick={handleOpenModal}>
            <ArrowRightStartOnRectangleIcon />
            <DropdownLabel>{SIDEBAR_SIGN_OUT}</DropdownLabel>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <LogoutModal
        isOpen={showModal}
        handleCloseModal={handleCloseModal}
      />

      <ChangePasswordModal
        isOpen={showChangePassModal}
        onClose={handleCloseChangePassModal}
      />      
    </>
  );
};

export default UserMenu;
