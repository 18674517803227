// Module dependencies
import { useDispatch, useSelector } from 'react-redux';
import { toggleDarkMode } from 'features/ui/uiSlice';

// UI
import { Button } from 'components/catalyst/button';
import { MoonIcon, SunIcon } from '@heroicons/react/24/solid';

const ThemeSelector = () => {
  const { isDarkMode } = useSelector((state) => state.ui);
  
  const dispatch = useDispatch();

  const handleToggleTheme = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <div>
      <Button
        outline
        className='rounded-full cursor-pointer'
        onClick={handleToggleTheme}
      >
        {isDarkMode ? (
          <SunIcon className="fill-zinc-300" />
        ) : (
          <MoonIcon className="fill-zinc-900" />
        )}
      </Button>
    </div>
  );
};

export default ThemeSelector;
