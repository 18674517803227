// UI
import { Heading } from 'components/catalyst/heading';
import Card from 'components/common/Card';
import { Text } from 'components/catalyst/text';
import LoadingButton from 'components/common/LoadingButton';
import WODeleteModal from './WODeleteModal';
import { TrashIcon } from '@heroicons/react/24/outline';

// Utils
import wording from 'utils/wording';

const WODetailManageTab = ({
  isDeletingWorkOrder,
  showDeleteWOModal,
  handleOpenDeleteWOModal,
  handleCloseDeleteWOModal,
  handleDeleteWorkOrder,
  workOrder,
}) => {
  const {
    WO_DETAIL_MANAGE_DELETE_DESCRIPTION,
    WO_DETAIL_MANAGE_DELETE,
    WO_DETAIL_MANAGE,
    WO_DETAIL_MANAGE_DELETING,
  } = wording;
  
  return (
    <>
      <Card>
        <Heading level={2} className='pb-2'>
          {WO_DETAIL_MANAGE}
        </Heading>

        <div className='flex justify-between items-center w-full gap-16'>
          <Text>
            {WO_DETAIL_MANAGE_DELETE_DESCRIPTION}
          </Text>

          <LoadingButton
            disabled={isDeletingWorkOrder}
            isLoading={isDeletingWorkOrder}
            label={WO_DETAIL_MANAGE_DELETE}
            loadingLabel={WO_DETAIL_MANAGE_DELETING}
            startIcon={<TrashIcon />}
            onClick={handleOpenDeleteWOModal}
            color='rose'
            className='whitespace-nowrap'
          />
        </div>
      </Card>

      <WODeleteModal
        showDeleteWOModal={showDeleteWOModal}
        handleDeleteWorkOrder={handleDeleteWorkOrder}
        handleCloseDeleteWOModal={handleCloseDeleteWOModal}
        workOrder={workOrder}
      />
    </>
    
  );
};

export default WODetailManageTab;
