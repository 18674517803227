// Module Dependencies
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDarkMode } from 'features/ui/uiSlice';

// UI
import { SidebarLayout } from 'components/catalyst/sidebar-layout';
import SidebarMain from './SidebarMain';
import SidebarNavbar from './SidebarNavbar';

const MainLayout = () => {
  const { isDarkMode } = useSelector((state) => state.ui);
  
  const dispatch = useDispatch();

  const handleToggleTheme = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <SidebarLayout
      navbar={
        <SidebarNavbar
          isDarkMode={isDarkMode}
          toggleDarkMode={handleToggleTheme}
        />
      }
      sidebar={
        <SidebarMain
          isDarkMode={isDarkMode}
          toggleDarkMode={handleToggleTheme}
        />
      }
    >
      <Outlet />
    </SidebarLayout>
  );
};

export default MainLayout;
