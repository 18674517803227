// Module dependencies
import useCreateWorkOrderForm from 'hooks/useCreateWorkOrderForm';

// UI
import Breadcrumb from 'components/Breadcrumb';
import { Button } from 'components/catalyst/button';
import { Heading } from 'components/catalyst/heading';
import ClientInformationForm from 'components/workOrder/ClientInformationForm';
import Card from 'components/common/Card';
import ServiceDetailForm from 'components/workOrder/ServiceDetailForm';
import AreasOfConcernForm from 'components/workOrder/AreasOfConcernForm';
import AdditionalInfoForm from 'components/workOrder/AdditionalInfoForm';
import LoadingButton from 'components/common/LoadingButton';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';

// Utils
import wording from 'utils/wording';

const CreateWorkOrder = () => {
  const createWorkOrderHandlers = useCreateWorkOrderForm();

  const {
    handleSubmit,
    onSubmit,
    handleCancelCreate,
    isValid,
    isLoading,
  } = createWorkOrderHandlers;
  
  const {
    CREATE_WO_CREATE_WORK_ORDER,
    CREATE_WO_CREATING_WORK_ORDER,
    CREATE_WO_WORK_ORDERS,
    CREATE_WO_CANCEL,
  } = wording;

  const pages = [
    { name: CREATE_WO_WORK_ORDERS, href: '/work-orders', current: false },
    { name: CREATE_WO_CREATE_WORK_ORDER, href: '/work-orders/new', current: false },
  ];

  return (
    <div className='flex flex-col gap-6'>
      <Breadcrumb pages={pages} />

      <div className='flex justify-between'>
        <Heading>{CREATE_WO_CREATE_WORK_ORDER}</Heading>

        <Button
          outline
          onClick={handleCancelCreate}
        >
          <ChevronLeftIcon />
          {CREATE_WO_CANCEL}
        </Button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Card>
            <ClientInformationForm {...createWorkOrderHandlers} />
          </Card>

          <Card>
            <ServiceDetailForm {...createWorkOrderHandlers} />
          </Card>

          <Card>
            <AreasOfConcernForm {...createWorkOrderHandlers} />
          </Card>

          <Card>
            <AdditionalInfoForm {...createWorkOrderHandlers} />
          </Card>
        </div>

        <div className="mt-6 flex justify-end">
          <LoadingButton
            color='green'
            type='submit'
            disabled={!isValid || isLoading}
            isLoading={isLoading}
            label={CREATE_WO_CREATE_WORK_ORDER}
            loadingLabel={CREATE_WO_CREATING_WORK_ORDER}
          />
        </div>
      </form>

    </div>
  );
};

export default CreateWorkOrder;
