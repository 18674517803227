import { axiosInstance } from 'config/axios';
import { endpoints } from '../../config/api';

// Get company users
const getCompanyUsers = async () => {
  const { data } = await axiosInstance.get(endpoints.users.company);

  return data;
};

const createUser = async (userData) => {
  const { data } = await axiosInstance.post(endpoints.users.base, userData);

  return data;
};

const updateUser = async (userId, userData) => {
  const { data } = await axiosInstance.put(endpoints.users.asAdmin(userId), userData);
  
  return data;
};

const deleteUser = async (userId) => {
  const { data } = await axiosInstance.delete(`${endpoints.users.base}/${userId}`);

  return data;
};

const searchUsers = async (query) => {
  const { data } = await axiosInstance.get(endpoints.users.search(query));

  return data;
}

const userService = {
  getCompanyUsers,
  createUser,
  updateUser,
  deleteUser,
  searchUsers,
};

export default userService;
