import { Strong, Text } from 'components/catalyst/text';

const WODetailUserMention = ({
  mentions,
  onSelect,
}) => {
  return (
    <div className='flex flex-col p-2 gap-1 max-h-48 overflow-y-auto rounded shadow-lg border dark:border-zinc-600'>
      {mentions.map((user) => (
        <div
          key={user.email}
          onClick={() => onSelect(user)}
          className='flex items-center gap-2 py-1 px-2 hover:bg-zinc-100 hover:dark:bg-zinc-800 cursor-pointer rounded'
        >
          <Text>
            <Strong>{`${user.firstName} ${user.lastName}`}</Strong>
          </Text>

          <Text>{user.email}</Text>
        </div>
      ))}
    </div>
  );
};

export default WODetailUserMention;
