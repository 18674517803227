import jsPDF from "jspdf";
import { formatCurrency, formatDate } from "./commons";

const generatePDF = (workOrder) => {
  const pdf = new jsPDF({
    format: 'a4',
    unit: 'pt'
  });

  const pageWidth = pdf.internal.pageSize.width;
  const pageHeight = pdf.internal.pageSize.height;
  const margin = 40;
  const contentWidth = pageWidth - (margin * 2);
  
  // Header Section
  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(16);
  pdf.text(workOrder.company?.name || 'Company Name', pageWidth / 2, margin + 15, { align: 'center' });

  pdf.setFontSize(14);
  pdf.text('Repair Order Request', pageWidth / 2, margin + 35, { align: 'center' });
  
  // Client Information Section
  let yPos = margin + 55;
  pdf.setFont('helvetica', 'bold');
  pdf.setFontSize(11);
  pdf.text('CLIENT INFORMATION', margin + 10, yPos + 15);
  
  pdf.setFont('helvetica', 'normal');
  pdf.setFontSize(10);
  const clientInfo = [
    `Name:     ${workOrder.client?.name || 'N/A'}`,
    `Email:     ${workOrder.client?.email || 'N/A'}`,
    `Phone:    ${workOrder.client?.phone || 'N/A'}`,
    `Address:  ${workOrder.address?.formattedAddress || 'N/A'}`
  ];
  clientInfo.forEach((line, index) => {
    pdf.text(line, margin + 20, yPos + 32 + (index * 12));
  });

  // Service Details Section
  yPos += 80;
  pdf.setFont('helvetica', 'bold');
  pdf.text('SERVICE DETAILS', margin + 10, yPos + 15);
  
  pdf.setFont('helvetica', 'normal');
  const leftDetails = [
    `Color: ${workOrder.color || 'N/A'}`,
    `Screen Type: ${workOrder.screenType || 'N/A'}`,
    `Under Warranty: ${workOrder.underWarranty ? 'Yes' : 'No'}`
  ];
  
  const rightDetails = [
    `Screw Type: ${workOrder.screwType || 'N/A'}`,
    `Inspection Priority: ${workOrder.inspectionPriority || 'N/A'}`,
    `Status: ${workOrder.status?.charAt(0).toUpperCase() + workOrder.status?.slice(1) || 'N/A'}`
  ];

  leftDetails.forEach((line, index) => {
    pdf.text(line, margin + 20, yPos + 32 + (index * 12));
  });

  rightDetails.forEach((line, index) => {
    pdf.text(line, margin + contentWidth/2, yPos + 32 + (index * 12));
  });

  // Schedule Section
  yPos += 80;
  pdf.setFont('helvetica', 'bold');
  pdf.text('SCHEDULE', margin + 10, yPos + 15);
  
  pdf.setFont('helvetica', 'normal');
  pdf.text([
    `Start Date: ${formatDate(workOrder.startDate)}`,
    `End Date: ${formatDate(workOrder.endDate)}`
  ], margin + 20, yPos + 32);

  // Areas of Concern Section
  yPos += 60;
  const concerns = Object.entries(workOrder.areaOfConcern || {})
    .filter(([key, value]) => value && key !== 'other')
    .map(([key]) => `• ${key.charAt(0).toUpperCase() + key.slice(1)}`);

  pdf.setFont('helvetica', 'bold');
  pdf.text('AREAS OF CONCERN', margin + 10, yPos + 15);
  
  pdf.setFont('helvetica', 'normal');
  let concernYPos = yPos + 32;
  
  // Display regular concerns
  if (concerns.length > 0) {
    concerns.forEach((concern, index) => {
      pdf.text(concern, margin + 20, concernYPos + (index * 12));
      concernYPos = concernYPos + (index * 12);
    });
  }

  // Add other concerns if they exist
  if (workOrder.areaOfConcern?.other) {
    concernYPos += 24; // Add some spacing after the bullet points
    pdf.setFont('helvetica', 'bold');
    pdf.text('Other Concerns:', margin + 20, concernYPos);
    
    pdf.setFont('helvetica', 'normal');
    const otherConcerns = pdf.splitTextToSize(workOrder.areaOfConcern.other, contentWidth - 40);
    pdf.text(otherConcerns, margin + 20, concernYPos + 15);
    
    // Adjust the yPos based on the number of lines in otherConcerns
    yPos = concernYPos + (otherConcerns.length * 12);
  }

  // Materials List Section
  yPos += 40;
  pdf.setFont('helvetica', 'bold');
  pdf.text('MATERIALS LIST', margin + 10, yPos + 15);
  
  pdf.setFont('helvetica', 'normal');
  const materialsList = pdf.splitTextToSize(workOrder.materialsList || 'N/A', contentWidth - 40);
  pdf.text(materialsList, margin + 20, yPos + 32);

  // Work Details Section
  yPos += 75;
  pdf.setFont('helvetica', 'bold');
  pdf.text('WORK DETAILS', margin + 10, yPos + 15);
  
  pdf.setFont('helvetica', 'normal');
  const workDetails = pdf.splitTextToSize(workOrder.workPerformed || 'N/A', contentWidth - 40);
  pdf.text(workDetails, margin + 20, yPos + 32);

  // Financial Details Section
  yPos += 75;
  pdf.setFont('helvetica', 'bold');
  pdf.text('FINANCIAL DETAILS', margin + 10, yPos + 15);
  
  pdf.setFont('helvetica', 'normal');
  pdf.text([
    `Amount Due: ${formatCurrency(workOrder.amountDue)}`,
    `Cash on Delivery: ${workOrder.cashOnDelivery ? 'Yes' : 'No'}`
  ], margin + 20, yPos + 32);

  // Signature Section
  yPos = pageHeight - 140;
  pdf.setFont('helvetica', 'bold');
  pdf.text('CLIENT SIGNATURE', margin + 10, yPos + 15);
  
  pdf.setFont('helvetica', 'normal');
  pdf.line(margin + 20, yPos + 40, margin + 200, yPos + 40);
  pdf.text(`Date: ${formatDate(new Date())}`, margin + 220, yPos + 35);

  // Footer
  pdf.setFontSize(8);
  pdf.text([
    workOrder.company?.name || 'Company Name',
    `Generated by: ${workOrder.createdBy ? `${workOrder.createdBy.firstName} ${workOrder.createdBy.lastName}` : 'N/A'}`,
    `Generated on: ${new Date().toLocaleDateString()}`
  ], pageWidth / 2, pageHeight - 30, { 
    align: 'center'
  });

  // Page Border
  pdf.setDrawColor(200, 200, 200);
  pdf.rect(margin - 10, margin - 10, contentWidth + 20, pageHeight - (margin * 2) + 20);
  console.log("WO PDF",workOrder);

  // Format client name for filename (replace spaces with hyphens and remove special characters)
  const formatFileName = (name) => {
    return name?.replace(/[^a-zA-Z0-9\s-]/g, '')  // Remove special characters
              .replace(/\s+/g, '-')                // Replace spaces with hyphens
              .toLowerCase() || 'unnamed-client';   // Convert to lowercase and provide fallback
  };

  // Format date to YYYY-MM-DD
  const formatFileDate = (date) => {
    return date ? new Date(date).toISOString().split('T')[0] : 'no-date';
  };

  // Save the PDF with client name and date
  pdf.save(`WorkOrder-${formatFileName(workOrder.client?.name)}-${formatFileDate(workOrder.dateRequested)}.pdf`);
};

export {
  generatePDF,
};
