// Module dependencies
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getWorkOrders } from 'features/workOrders/workOrderSlice';

// UI
import Breadcrumb from 'components/Breadcrumb';
import { Button } from 'components/catalyst/button';
import { Heading } from 'components/catalyst/heading';
import Loader from 'components/Loader';
import WorkOrderLocations from 'components/workOrdersPage/WorkOrdersLocations';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import StatusSelect from 'components/StatusSelect';

// Utils
import wording from 'utils/wording';

const {
  SIDEBAR_WORK_ORDERS,
  SIDEBAR_PENDING_LOCATIONS,
  WORK_ORDERS_BACK_TO_WO,
  WORK_ORDERS_PENDING_LOCATIONS,
} = wording;

const pages = [
  { name: SIDEBAR_WORK_ORDERS, href: '/work-orders', current: false },
  { name: SIDEBAR_PENDING_LOCATIONS, href: '/work-orders/pending-locations', current: false },
];

const PendingLocationsView = () => {
  const {
    workOrders,
    isLoading,
  } = useSelector((state) => state.workOrders);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedStatus, setSelectedStatus] = useState('pending');
  const [filteredWorkOrders, setFilteredWorkOrders] = useState([]);
  
  useEffect(() => {
    dispatch(getWorkOrders());
  }, [dispatch]);

  useEffect(() => {
    const filteredWO = workOrders.filter(wo => 
      wo.status === selectedStatus && 
      wo.address?.lat && 
      wo.address?.lng
    );

    setFilteredWorkOrders(filteredWO);
  }, [workOrders, selectedStatus])

  const handleStatusChange = ({ target }) => {
    setSelectedStatus(target.value);
  }

  return (
    <div className='flex flex-col gap-6'>
      <Breadcrumb pages={pages} />

      <div className='flex justify-between items-center'>
        <Heading className='capitalize'>
          {WORK_ORDERS_PENDING_LOCATIONS(selectedStatus, filteredWorkOrders.length)}
        </Heading>

        <div className='flex justify-end items-center gap-2'>
          <StatusSelect
            name='location-status'
            value={selectedStatus}
            handleChange={handleStatusChange}
          />

          <Button
            outline
            onClick={() => navigate('/work-orders')}
            className='whitespace-nowrap'
          >
            <ChevronLeftIcon />
            {WORK_ORDERS_BACK_TO_WO}
          </Button>
        </div>
      </div>

      <>
        {workOrders.length === 0 && isLoading ? (
          <div className='flex items-center justify-center w-full h-96'>
            <Loader />
          </div>
        ) : (
          <WorkOrderLocations workOrders={filteredWorkOrders} />
        )}
      </>
    </div>
  )
}

export default PendingLocationsView;
