// UI
import { Checkbox } from 'components/catalyst/checkbox';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Heading } from 'components/catalyst/heading';
import { Input } from 'components/catalyst/input';
import { Select } from 'components/catalyst/select';
import { Textarea } from 'components/catalyst/textarea';
import Card from 'components/common/Card';
import StatusBadge from 'components/StatusBadge';
import { colorOptions, inspectionOptions, screenTypeOptions, screwTypeOptions } from 'components/workOrder/utils';
import { formatDate } from 'utils/commons';

// Utils
import wording from 'utils/wording';

const ServiceCard = ({
  workOrder,
  users,
  isEditing,
  editedData,
  handleChange,
  handleCheckboxChange,
}) => {
  const {
    WO_DETAIL_SERVICE_SPEC,
    WO_DETAIL_COLOR,
    WO_DETAIL_SCREEN_TYPE,
    WO_DETAIL_SCREW_TYPE,
    WO_DETAIL_UNDER_WARRANTY,
    WO_DETAIL_INSPECTION_PRIORITY,
    WO_DETAIL_MATERIALS_LIST,
    WO_DETAIL_START_DATE,
    WO_DETAIL_END_DATE,
    CREATE_WO_MATERIALS_LIST_PLACEHOLDER,
    GLOBAL_YES,
    GLOBAL_NO,
    GLOBAL_DASH,
  } = wording;

  return (
    <Card>
      <Heading level={2} className='pb-2'>
        {WO_DETAIL_SERVICE_SPEC}
      </Heading>
      
      <DescriptionList>
        <DescriptionTerm>
          {WO_DETAIL_COLOR}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Select
              name="color"
              value={editedData.color}
              onChange={handleChange}
            >
              {colorOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ) : (
            <>
              {workOrder?.color || GLOBAL_DASH}
            </>
          )}
        </DescriptionDetails>
        <DescriptionTerm>
          {WO_DETAIL_SCREEN_TYPE}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Select
              name="screenType"
              value={editedData.screenType}
              onChange={handleChange}
            >
              {screenTypeOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ) : (
            <>
              {workOrder?.screenType || GLOBAL_DASH}
            </>
          )}
        </DescriptionDetails>
        <DescriptionTerm>
          {WO_DETAIL_SCREW_TYPE}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Select
              name="screwType"
              value={editedData.screwType}
              onChange={handleChange}
            >
              {screwTypeOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ) : (
            <>
              {workOrder?.screwType || GLOBAL_DASH}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_UNDER_WARRANTY}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Checkbox
              name="underWarranty"
              checked={editedData.underWarranty}
              onChange={(value) => handleCheckboxChange('underWarranty', value)}
            />
          ) : (
            <>
              {workOrder?.underWarranty ? GLOBAL_YES : GLOBAL_NO}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_INSPECTION_PRIORITY}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Select
              name="inspectionPriority"
              value={editedData.inspectionPriority}
              onChange={handleChange}
            >
              {inspectionOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          ) : (
            <StatusBadge status={workOrder?.inspectionPriority}/>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_START_DATE}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="date"
              name="startDate"
              value={editedData.startDate ? editedData.startDate.slice(0, 10) : ''}
              onChange={handleChange}
              required
            />
          ) : (
            <>
              {formatDate(workOrder?.startDate)}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_END_DATE}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="date"
              name="endDate"
              value={editedData.endDate ? editedData.endDate.slice(0, 10) : ''}
              onChange={handleChange}
              min={editedData.startDate ? editedData.startDate.slice(0, 10) : ''}
              required
            />
          ) : (
            <>
              {formatDate(workOrder?.endDate)}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_MATERIALS_LIST}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Textarea
              name="materialsList"
              value={editedData.materialsList}
              onChange={handleChange}
              rows={4}
              placeholder={CREATE_WO_MATERIALS_LIST_PLACEHOLDER}
            />
          ) : (
            <>
              {workOrder?.materialsList}
            </>
          )}
        </DescriptionDetails>
      </DescriptionList>
    </Card>
  );
};

export default ServiceCard;
