// UI
import StatusCard from './StatusCard';
import AssignmentCard from './AssignmetCard';
import WorkDetailsCard from './WorkDetailsCard';
import ServiceCard from './ServiceCard';
import AreasOfConcernCard from './AreasOfConcernCard';
import Loader from 'components/Loader';

const WODetailsOverviewTab = ({
  workOrderDetailHandlers,
  isFetchingWorkorder,
}) => {
  return (
    <>
      {isFetchingWorkorder ? (
        <div className='flex items-center justify-center w-full h-96'>
          <Loader />
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="flex flex-col gap-4">
            <StatusCard {...workOrderDetailHandlers} />

            <AssignmentCard {...workOrderDetailHandlers} />

            <WorkDetailsCard {...workOrderDetailHandlers} />  
          </div>

          <div className="space-y-4">
            <ServiceCard {...workOrderDetailHandlers} />

            <AreasOfConcernCard {...workOrderDetailHandlers} />
          </div>
        </div>
      )}
    </>
  );
};

export default WODetailsOverviewTab;
