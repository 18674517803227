// Module dependencies
import { useEffect } from 'react';
import toast from 'react-hot-toast';

// UI
import LoginForm from 'components/login/LoginForm';
import LoginHeader from 'components/login/LoginHeader';
import Copyright from 'components/login/Copyright';
import ThemeSelector from 'components/ThemeSelector';

// Utils
import wording from 'utils/wording';

const Login = () => {
  const { ERROR_SESSION_EXPIRED } = wording;

  useEffect(() => {
    const sessionExpired = localStorage.getItem('nimax_auth_expired');
    
    if (sessionExpired) {
      toast.error(ERROR_SESSION_EXPIRED);

      setTimeout(() => {
        localStorage.removeItem('nimax_auth_expired');
      }, 2000);
    }
  }, []);

  return (
    <div className="min-h-screen bg-white dark:bg-zinc-950 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <LoginHeader />

      <div className="mt-8 mx-4 sm:mx-auto sm:w-full sm:max-w-md">
        <LoginForm />
        
        <Copyright />
      </div>

      <div className="absolute top-0 left-0 m-4">
        <ThemeSelector variant='button' />
      </div>
    </div>
  );
};

export default Login;
