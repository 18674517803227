// Module dependencies
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from 'features/auth/authSlice';

// UI
import { Heading } from 'components/catalyst/heading';
import { Box } from 'components/common/StyledBoxs';
import { Field, FieldGroup, Fieldset, Label } from 'components/catalyst/fieldset';
import { Input } from 'components/catalyst/input';
import { Text } from 'components/catalyst/text';
import LoadingButton from 'components/common/LoadingButton';

// Utils
import wording from 'utils/wording';

const {
  LOGIN_EMAIL_LABEL,
  PASSWORD_RESET,
  PASSWORD_RESET_LINK,
  PASSWORD_BACK_TO_LOGIN,
  PASSWORD_RESETING,
  PASSWORD_RESET_SUCCESS,
  ERROR_MESSAGE,
} = wording;

const ForgotPassword = () => {
  const { isLoading } = useSelector((state) => state.auth);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      await dispatch(
        forgotPassword(data.email)
      ).unwrap();

      toast.success(PASSWORD_RESET_SUCCESS);
    } catch (error) {
      toast.error(error.response?.data?.message || ERROR_MESSAGE);
    }
  };

  return (
    <div className="min-h-screen bg-white dark:bg-zinc-950 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Heading level={2} className='text-center !text-2xl !font-extrabold'>
        {PASSWORD_RESET}
      </Heading>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
        <Box className="py-8 px-4 sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <Fieldset>
              <FieldGroup>
                <Field>
                  <Label>{LOGIN_EMAIL_LABEL}</Label>

                  <Input
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    })}
                  />
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
                  )}
                </Field>

                <LoadingButton
                  disabled={isLoading}
                  type='submit'
                  isLoading={isLoading}
                  label={PASSWORD_RESET_LINK}
                  loadingLabel={PASSWORD_RESETING}
                  className='w-full'
                />
              </FieldGroup>
            </Fieldset>
          </form>

          <div className="mt-6 flex justify-center w-full">
            <Link to='/login'>
              <Text className='cursor-pointer hover:text-zinc-900 dark:hover:text-zinc-100'>
                {PASSWORD_BACK_TO_LOGIN}
              </Text>
            </Link> 
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ForgotPassword;
