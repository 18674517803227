
// UI
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar';
import { MoonIcon, SunIcon } from '@heroicons/react/20/solid';
import UserMenu from './UserMenu';

const SidebarNavbar = ({
  isDarkMode,
  toggleDarkMode,
}) => {
  return (
    <Navbar>
      <NavbarSpacer />
      <NavbarSection>
        <NavbarItem onClick={toggleDarkMode}>
          {isDarkMode ? <SunIcon /> : <MoonIcon />}
        </NavbarItem>

        <UserMenu />

      </NavbarSection>
    </Navbar>
  );
};

export default SidebarNavbar;
