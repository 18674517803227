'use-client'
// UI
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { NavLink } from 'react-router-dom';

// Utils
import wording from 'utils/wording';

const Breadcrumb = ({ pages }) => {
  const { GLOBAL_HOME } = wording;

  return (
    <nav aria-label="Breadcrumb" className="flex">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <NavLink to="/dashboard" className="text-zinc-500 dark:text-zinc-400 hover:text-zinc-950 dark:hover:text-white">
              <HomeIcon aria-hidden="true" className="size-5 shrink-0" />
              <span className="sr-only">{GLOBAL_HOME}</span>
            </NavLink>
          </div>
        </li>

        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon aria-hidden="true" className="size-5 shrink-0 text-zinc-500 dark:text-zinc-400" />
              <NavLink
                to={page.href}
                aria-current={page.current ? 'page' : undefined}
                className="ml-4 text-sm font-medium text-zinc-500 dark:text-zinc-400 hover:text-zinc-950 dark:hover:text-white"
              >
                {page.name}
              </NavLink>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
