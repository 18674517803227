import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const axiosPublicInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('nimax_auth_token');

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
  
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('nimax_auth_token');
      localStorage.setItem('nimax_auth_expired', true);

      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export {
  axiosInstance,
  axiosPublicInstance,
}
