// UI
import MessageCard from './MessageCard';

const MessageFeed = ({ messages }) => {
  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {messages.map((message, index) => (
          <MessageCard
            message={message}
            index={index}
            length={messages.length}
          />
        ))}
      </ul>
    </div>
  );
};

export default MessageFeed;
