// Module dependencies
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects } from '../features/projects/projectSlice';
import * as XLSX from 'xlsx';

/**
 * Custom hook to manage projects' data, filtering, selection, and export functionality.
 */
const useProjects = () => {
  const { projects, isLoading } = useSelector((state) => state.projects);

  const dispatch = useDispatch();

  // States for filtering and selection
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState(new Set());
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);

  // Fetch projects data on mount
  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  /**
   * Filters projects based on the search term and selected status.
   */
  useEffect(() => {
    const filteredProjectsBySearchTermAndStatus = projects.filter(project => {
      const matchesSearch = project.name.toLowerCase().includes(searchTerm.toLowerCase())
        || project.description?.toLowerCase().includes(searchTerm.toLowerCase())
        || project.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()));
  
      const matchesStatus = selectedStatus === 'all' || project.status === selectedStatus;
  
      return matchesSearch && matchesStatus;
    });
    
    setFilteredProjects(filteredProjectsBySearchTermAndStatus);
  }, [projects, searchTerm, selectedStatus]);

  /**
   * Toggles the selection state for a project by its ID.
   * @param {string} projectId - The ID of the project to toggle selection.
   */
  const handleToggleSelection = (projectId) => {
    setSelectedProjects(prev => {
      const newSelection = new Set(prev);

      newSelection.has(projectId)
        ? newSelection.delete(projectId)
        : newSelection.add(projectId);

      return newSelection;
    });
  };

  /**
   * Selects all filtered projects or deselects them if all are already selected.
   */
  const handleSelectAllProjects = () => {
    if (selectedProjects.size === filteredProjects.length) {
      setSelectedProjects(new Set());
    } else {
      setSelectedProjects(new Set(filteredProjects.map(p => p._id)));
    }
  };

  /**
   * Clears all project selections.
   */
  const handleClearSelection = () => setSelectedProjects(new Set());

  const formatUserName = (user) => {
    if (!user) return '';

    return user.firstName && user.lastName 
      ? `${user.firstName} ${user.lastName}`
      : user.email || '';
  };

  /**
   * Exports the selected or all projects' budgets as an Excel file.
   */
  const exportAllProjectsBudgets = () => {
    if (isSelectionMode && selectedProjects.size === 0) {
      alert('Please select at least one project to export');
      return;
    }

    const projectsToExport = isSelectionMode 
      ? projects.filter(p => selectedProjects.has(p._id))
      : projects;

    const wb = XLSX.utils.book_new();

    // Initialize the data array with headers
    let summaryData = [
      ['Pay Period 12/1-12/8'],
      [''],
      ['', ...projectsToExport.map(p => p.name), 'Total']
    ];

    // Collect all unique users and their roles from projects
    const userRoles = new Map(); // Map of "userName (role)" to row data

    projectsToExport.forEach(project => {
      if (!project.budgetId) return;

      // Get all unique role-user combinations from the project
      const projectRoleUsers = new Map(); // Map of role to Set of users

      // First pass: collect all users per role
      project.stages?.forEach(stage => {
        stage.tasks?.forEach(task => {
          task.subtasks?.forEach(subtask => {
            const subtaskRoles = subtask.roles?.map(role => role.toUpperCase().replace('_', '')) || [];
            subtask.assignees?.forEach(assignee => {
              subtaskRoles.forEach(role => {
                if (!projectRoleUsers.has(role)) {
                  projectRoleUsers.set(role, new Set());
                }
                projectRoleUsers.get(role).add(formatUserName(assignee));
              });
            });
          });
        });
      });

      // Second pass: calculate amounts per user-role
      projectRoleUsers.forEach((users, role) => {
        const numUsers = users.size;
        if (numUsers === 0) return;

        let roleAmount = 0;
        switch (role) {
          case 'DRAFTSMAN':
            roleAmount = project.budgetId.draftsmanPay || 0;
            break;
          case 'SALESMAN':
            roleAmount = project.budgetId.salesmenPay || 0;
            break;
          case 'PROJECTMANAGER':
            roleAmount = project.budgetId.projectManagerPay || 0;
            break;
          case 'INSTALLER':
            roleAmount = project.budgetId.installerPay || 0;
            break;
        }

        const amountPerUser = roleAmount / numUsers;

        // Distribute amount to each user
        users.forEach(userName => {
          const userKey = `${userName} (${role.charAt(0) + role.slice(1).toLowerCase()})`;
          if (!userRoles.has(userKey)) {
            userRoles.set(userKey, {
              amounts: new Array(projectsToExport.length).fill(0),
              total: 0
            });
          }
          const userData = userRoles.get(userKey);
          const projectIndex = projectsToExport.findIndex(p => p._id === project._id);
          userData.amounts[projectIndex] = amountPerUser;
          userData.total = userData.amounts.reduce((sum, amount) => sum + amount, 0);
        });
      });
    });

    // Add Company Profit row separately
    const companyProfits = projectsToExport.map(p => p.budgetId?.companyProfit || 0);
    const totalProfit = companyProfits.reduce((sum, profit) => sum + profit, 0);

    // Add user rows to summary data
    Array.from(userRoles.entries())
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
      .forEach(([userKey, data]) => {
        summaryData.push([
          userKey,
          ...data.amounts,
          data.total
        ]);
      });

    // Add Company Profit row
    summaryData.push([
      'Company Profit',
      ...companyProfits,
      totalProfit
    ]);

    const ws = XLSX.utils.aoa_to_sheet(summaryData);

    // Set column widths
    ws['!cols'] = [
      { wch: 25 }, // Name/Role column
      ...projectsToExport.map(() => ({ wch: 15 })), // Project columns
      { wch: 15 }, // Total column
    ];

    // Style the cells
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let R = 0; R <= range.e.r; R++) {
      for (let C = 0; C <= range.e.c; C++) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!ws[cell_ref]) continue;

        // Default cell style
        ws[cell_ref].s = {
          font: { sz: 11 },
          alignment: {
            horizontal: C === 0 ? 'left' : 'right',
            vertical: 'center'
          },
          border: {
            top: { style: 'thin', color: { rgb: "000000" } },
            bottom: { style: 'thin', color: { rgb: "000000" } },
            left: { style: 'thin', color: { rgb: "000000" } },
            right: { style: 'thin', color: { rgb: "000000" } }
          }
        };

        // Format numbers as currency
        if (typeof ws[cell_ref].v === 'number') {
          ws[cell_ref].z = '$#,##0.00';
          ws[cell_ref].s.font = { ...ws[cell_ref].s.font, bold: true };
        }

        // Style headers
        if (R <= 2) {
          ws[cell_ref].s.font = { bold: true, sz: 11 };
        }

        // Alternate row background colors (starting after headers)
        if (R > 2 && R % 2 === 0) {
          ws[cell_ref].s.fill = { fgColor: { rgb: "DCE6F1" } }; // Light blue
        }

        // Style total column
        if (C === range.e.c) {
          ws[cell_ref].s.font = { bold: true, sz: 11 };
          ws[cell_ref].s.fill = { fgColor: { rgb: "DCE6F1" } }; // Light blue
        }
      }
    }

    // Merge title cells
    ws['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: range.e.c } }
    ];

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Pay Period Budget');

    // Generate filename
    const fileName = `Pay_Period_Budget_${new Date().toISOString().split('T')[0]}.xlsx`;

    // Save file
    XLSX.writeFile(wb, fileName);
  };

  return {
    isLoading,
    projects,
    filteredProjects,
    searchTerm,
    setSearchTerm,
    selectedStatus,
    setSelectedStatus,
    isSelectionMode,
    setIsSelectionMode,
    selectedProjects,
    isMapOpen,
    setIsMapOpen,
    handleToggleSelection,
    handleSelectAllProjects,
    handleClearSelection,
    exportAllProjectsBudgets,
  };
};

export default useProjects;
