// UI
import { Button } from 'components/catalyst/button';
import { Strong, Text } from 'components/catalyst/text';
import { Badge } from 'components/catalyst/badge';
import { Box } from 'components/common/StyledBoxs';
import { 
  ArrowDownTrayIcon, 
  TrashIcon,
} from '@heroicons/react/20/solid';
import { EyeIcon } from '@heroicons/react/24/outline';
import DeleteDocumentDialog from './DeleteDocumentDialog';

// Utils
import wording from 'utils/wording';
import { formatDate } from 'utils/commons';

const DocumentCard = ({
  handleDownload,
  handleDeleteButtonClick,
  document,
  showDeleteDocumentModal,
  handleCloseDeleteDocumentModal,
  isDeletingDocument,
  handleDelete,
  pageKey,
  documentToDelete,
}) => {
  const {
    WO_DETAIL_UPLOAD,
    GLOBAL_DASH,
  } = wording;
  const getThumbnailIcon = (document) => {
    const iconMap = new Map([
      ['.doc', '/files/doc.png'],
      ['.docx', '/files/doc.png'],
      ['.jpg', '/files/jpg.png'],
      ['.jpeg', '/files/jpg.png'],
      ['.pdf', '/files/pdf.png'],
      ['.png', '/files/png.png'],
      ['.ppt', '/files/ppt.png'],
      ['.pptx', '/files/ppt.png'],
      ['.svg', '/files/svg.png'],
      ['.txt', '/files/txt.png'],
      ['.xls', '/files/xls.png'],
      ['.xlsx', '/files/xls.png'],
      ['.xml', '/files/xml.png'],
      ['.zip', '/files/zip.png'],
    ]);

    if (document.contentType?.includes('image/')) {
      return (
        <img
          src={document.url}
          alt={document.name}
          className="w-full h-36 object-cover rounded-lg border border-gray-200 dark:border-gray-800"
          onError={(e) => {
            console.log('Image failed to load:', document.url);
            e.target.onerror = null;
            e.target.src =
              'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiPjxwYXRoIHN0cm9rZUxpbmVjYXA9InJvdW5kIiBzdHJva2VMaW5lam9pbj0icm91bmQiIHN0cm9rZVdpZHRoPSIyIiBkPSJNNyAyMWgxMGEyIDIgMCAwMDItMlY5LjQxNGExIDEgMCAwMC0uMjkzLS43MDdsLTUuNDE0LTUuNDE0QTEgMSAwIDAwMTIuNTg2IDNINyIvPjwvc3ZnPg==';
          }}
        />
      );
    }

    const extension = document.name?.substring(document.name.lastIndexOf('.')).toLowerCase();
    
    const iconSrc = iconMap.get(extension) || '/icons/default.png';
  
    return (
      <img
        src={iconSrc}
        alt={`Icon for ${document.name}`}
        className="w-24 h-24 object-contain rounded"
      />
    );
  };

  return (
    <Box className="p-4 flex flex-col justify-between items-start gap-2">
      <div className='w-full'>
        <Text className='truncate'>
          <Strong>{document.name}</Strong>
        </Text>

        <Text>{document.description || GLOBAL_DASH}</Text>
      </div>

      <div className="w-full flex justify-center items-center my-2 h-36">
        {getThumbnailIcon(document)}
      </div>

      <div className="flex items-center space-x-4 w-full">
        <div className="flex justify-between items-center gap-2 w-full">
          <Text>
            {`${WO_DETAIL_UPLOAD} ${formatDate(pageKey === 'workorder-detail' ? document.uploadDate : document.uploadedAt)}`}
          </Text>

          <Text>
            {(document.size / 1024 / 1024).toFixed(2)} MB
          </Text>
        </div>
      </div>

      <div className='flex w-full justify-between gap-2'>
        <Badge color='blue' className='truncate'>
          {document.uploadedBy?.firstName} {document.uploadedBy?.lastName}
        </Badge>    

        <div className="flex items-center space-x-2">
          <Button
            className='w-7 h-7'
            onClick={() => window.open(document.url, "_blank", "noopener,noreferrer")}
            outline
          >
            <EyeIcon />
          </Button>
          
          <Button
            className='w-7 h-7'
            onClick={() => handleDownload(document._id)}
            outline
          >
            <ArrowDownTrayIcon />
          </Button>

          <Button
            className='w-7 h-7'
            onClick={() => handleDeleteButtonClick(document)}
            outline
          >
            <TrashIcon className="fill-red-500" />
          </Button>
        </div>
      </div>

      <DeleteDocumentDialog
        showDeleteDocumentModal={showDeleteDocumentModal}
        handleCloseDeleteDocumentModal={handleCloseDeleteDocumentModal}
        isDeletingDocument={isDeletingDocument}
        handleDelete={handleDelete}
        documentToDelete={documentToDelete}
      />
    </Box>
  );
};

export default DocumentCard;
