import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../common/Button';
import { createProject } from '../../features/projects/projectSlice';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getTemplates, updateTemplate, createTemplate } from '../../features/templates/templateSlice';
import { toast } from 'react-toastify';
import ErrorModal from '../common/ErrorModal';
import Select from 'react-select';
import projectService from '../../features/projects/projectService';
import GoogleLocationPicker from '../location/GoogleLocationPicker';
import Breadcrumbs from '../common/Breadcrumbs';
import { getCompanyUsers } from '../../features/users/userSlice';

const AVAILABLE_ROLES = [
  { value: 'ADMIN', label: 'Administrator' },
  { value: 'DRAFTSMAN', label: 'Draftsman' },
  { value: 'OFFICE', label: 'Office Staff' },
  { value: 'PROJECT_MANAGER', label: 'Project Manager' },
  { value: 'SALES', label: 'Sales' },
  { value: 'TECHNICIAN', label: 'Technician' },
  { value: 'INSTALLER', label: 'Installer' }
];

const DISPLAY_ROLES = AVAILABLE_ROLES.filter(role => role.value !== 'ADMIN');

const defaultStages = [
  {
    name: 'NEW_LEAD',
    order: 0,
    completed: false,
    tasks: [
      { 
        name: 'CUSTOMER CONTACTED',
        completed: false,
        subtasks: [
          { name: 'Initial contact made', completed: false, duration: 1 },
          { name: 'Customer requirements gathered', completed: false, duration: 1 }
        ]
      },
      { 
        name: 'ESTIMATE CREATED',
        completed: false,
        subtasks: [
          { name: 'Project scope defined', completed: false, duration: 1 },
          { name: 'Cost estimation completed', completed: false, duration: 1 }
        ]
      }
    ]
  },
  {
    name: 'PRE_CONSTRUCTION',
    order: 1,
    completed: false,
    tasks: [
      { 
        name: 'DRAFT PROJECT MODELS',
        completed: false,
        subtasks: [
          { name: 'Initial designs created', completed: false, duration: 1 },
          { name: 'Design review', completed: false, duration: 1 }
        ]
      },
      { 
        name: 'ENGINEERING',
        completed: false,
        subtasks: [
          { name: 'Technical specifications', completed: false, duration: 1 },
          { name: 'Engineering review', completed: false, duration: 1 }
        ]
      }
    ]
  },
  {
    name: 'BUILDING',
    order: 2,
    completed: false,
    tasks: [
      { 
        name: 'SCHEDULE INSTALL',
        completed: false,
        subtasks: [
          { name: 'Timeline planned', completed: false, duration: 1 },
          { name: 'Resources allocated', completed: false, duration: 1 }
        ]
      },
      { 
        name: 'MATERIAL LIST',
        completed: false,
        subtasks: [
          { name: 'Materials specified', completed: false, duration: 1 },
          { name: 'Orders placed', completed: false, duration: 1 }
        ]
      }
    ]
  }
];

// Add this before the ProjectWizard component
const TemplateNamingModal = ({ 
  isOpen, 
  pendingName, 
  error, 
  onClose, 
  onChange, 
  onSubmit,
  isSubmitting
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Save Modified Template
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    You've made changes to the template. To proceed, please enter a new name for this modified version.
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <input
                  ref={inputRef}
                  type="text"
                  className={getInputClassName(error)}
                  placeholder="Enter new template name"
                  value={pendingName}
                  onChange={onChange}
                  disabled={isSubmitting}
                />
                {error && (
                  <p className="mt-1.5 text-xs text-red-600">{error}</p>
                )}
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                className={`w-full sm:col-start-2 inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
                  isSubmitting || !pendingName.trim()
                    ? 'bg-green-300 text-white cursor-not-allowed'
                    : 'bg-green-600 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'
                }`}
                onClick={onSubmit}
                disabled={!pendingName.trim() || isSubmitting}
              >
                {isSubmitting ? (
                  <div className="flex items-center justify-center gap-2">
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Creating Project...
                  </div>
                ) : (
                  'Save and Create Project'
                )}
              </button>
              <button
                type="button"
                className="mt-3 w-full sm:col-start-1 sm:mt-0 inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this component definition after the TemplateNamingModal component and before the ProjectWizard component
const BudgetSection = ({ budget, onChange, errors }) => {
  const handleBreakdownChange = (index, value) => {
    const newBreakdown = [...budget.breakdown];
    newBreakdown[index] = { ...newBreakdown[index], amount: value };
    
    // Calculate total amount
    const totalAmount = newBreakdown.reduce((sum, item) => sum + Number(item.amount || 0), 0);
    
    onChange({
      ...budget,
      breakdown: newBreakdown,
      amount: totalAmount
    });
  };

  // Group budget items by category
  const directCosts = budget.breakdown.filter(item => 
    ['materialCosts', 'officeOverhead', 'companyProfit', 'permitCosts'].includes(item.category)
  );
  
  const laborCosts = budget.breakdown.filter(item => 
    ['draftsmanPay', 'installerPay', 'salesmenPay', 'salesManagerPay', 'projectManagerPay'].includes(item.category)
  );

  return (
    <div className="space-y-6">
      {/* Direct Costs Section */}
      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-3">Direct Costs</h4>
        <div className="space-y-3">
          {directCosts.map((item, index) => (
            <div key={item.category} className="flex items-center justify-between py-2 px-3 bg-white rounded-md border border-gray-200">
              <span className="text-sm font-medium text-gray-700">{item.label}</span>
              <div className="w-40">
                <div className="relative rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                    <span className="text-gray-400 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    value={item.amount || ''}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9.]/g, '');
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        handleBreakdownChange(
                          budget.breakdown.findIndex(b => b.category === item.category),
                          Number(value)
                        );
                      }
                    }}
                    className={`${baseInputClass} pl-6 pr-8`}
                    placeholder="0.00"
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <span className="text-gray-400 text-xs">USD</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Labor Costs Section */}
      <div>
        <h4 className="text-sm font-medium text-gray-700 mb-3">Labor Costs</h4>
        <div className="space-y-3">
          {[
            { category: 'draftsmanPay', label: 'Draftsman Pay' },
            { category: 'installerPay', label: 'Installer Pay' },
            { category: 'salesmenPay', label: 'Sales Commission' },
            { category: 'salesManagerPay', label: 'Sales Manager Pay' },
            { category: 'projectManagerPay', label: 'Project Manager Pay' }
          ].map((item) => (
            <div key={item.category} className="flex items-center justify-between py-2 px-3 bg-white rounded-md border border-gray-200">
              <span className="text-sm font-medium text-gray-700">{item.label}</span>
              <div className="w-40">
                <div className="relative rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                    <span className="text-gray-400 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    value={budget.breakdown.find(b => b.category === item.category)?.amount || ''}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9.]/g, '');
                      if (value === '' || /^\d*\.?\d*$/.test(value)) {
                        handleBreakdownChange(
                          budget.breakdown.findIndex(b => b.category === item.category),
                          Number(value)
                        );
                      }
                    }}
                    className={`${baseInputClass} pl-6 pr-8`}
                    placeholder="0.00"
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <span className="text-gray-400 text-xs">USD</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Total Budget Display */}
      <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
        <div className="flex items-center justify-between">
          <div>
            <h4 className="text-sm font-medium text-blue-900">Total Budget</h4>
          </div>
          <div className="text-lg font-semibold text-blue-900">
            ${budget.amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </div>
        </div>
      </div>
    </div>
  );
};

const baseInputClass = "block w-full rounded-lg border border-gray-200 px-4 py-2.5 text-gray-900 placeholder-gray-400 text-sm shadow-sm transition-colors focus:ring-1 focus:ring-blue-500 focus:border-blue-500 focus:outline-none";

const getInputClassName = (error) => {
  return `${baseInputClass} ${error ? 'border-red-300 focus:ring-red-500 focus:border-red-500' : ''}`.trim();
};

const selectStyles = {
  control: (base) => ({
    ...base,
    borderColor: '#E5E7EB',
    '&:hover': {
      borderColor: '#059669'
    }
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#D1FAE5',
    borderRadius: '4px',
    padding: '2px',
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: '#065F46',
    fontWeight: '500',
    fontSize: '0.875rem',
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: '#065F46',
    ':hover': {
      backgroundColor: '#A7F3D0',
      color: '#064E3B',
    },
  }),
  placeholder: (base) => ({
    ...base,
    color: '#6B7280',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#D1FAE5' : 'white',
    color: state.isFocused ? '#065F46' : '#374151',
    ':active': {
      backgroundColor: '#A7F3D0',
    },
  }),
};

// Add this helper function near the top of the file
const isTemplateRolesModified = (originalTemplate, currentTemplate) => {
  if (!originalTemplate) return false;
  
  const original = JSON.parse(originalTemplate);
  
  for (let stageIndex = 0; stageIndex < original.length; stageIndex++) {
    const originalStage = original[stageIndex];
    const currentStage = currentTemplate.stages[stageIndex];
    
    for (let taskIndex = 0; taskIndex < originalStage.tasks.length; taskIndex++) {
      const originalTask = originalStage.tasks[taskIndex];
      const currentTask = currentStage.tasks[taskIndex];
      
      for (let subtaskIndex = 0; subtaskIndex < originalTask.subtasks.length; subtaskIndex++) {
        const originalSubtask = originalTask.subtasks[subtaskIndex];
        const currentSubtask = currentTask.subtasks[subtaskIndex];
        
        if (!originalSubtask.roles && !currentSubtask.roles) continue;
        if (!originalSubtask.roles || !currentSubtask.roles) return true;
        
        if (JSON.stringify(originalSubtask.roles.sort()) !== 
            JSON.stringify(currentSubtask.roles.sort())) {
          return true;
        }
      }
    }
  }
  return false;
};

const ProjectWizard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentStageIndex, setCurrentStageIndex] = useState(0);
  const [template, setTemplate] = useState({
    name: 'Custom Template',
    stages: defaultStages
  });
  const [projectDetails, setProjectDetails] = useState({
    name: '',
    description: '',
    location: {
      address: '',
      street: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      coordinates: {
        lat: null,
        lng: null
      }
    },
    tags: [],
    status: 'pending',
    budget: {
      amount: 0,
      currency: 'USD',
      breakdown: [
        { category: 'materialCosts', label: 'Material Costs', amount: 0 },
        { category: 'officeOverhead', label: 'Office Overhead', amount: 0 },
        { category: 'companyProfit', label: 'Company Profit', amount: 0 },
        { category: 'permitCosts', label: 'Permit Costs', amount: 0 },
        { category: 'draftsmanPay', label: 'Draftsman Pay', amount: 0 },
        { category: 'installerPay', label: 'Installer Pay', amount: 0 },
        { category: 'salesmenPay', label: 'Sales Commission', amount: 0 },
        { category: 'salesManagerPay', label: 'Sales Manager Pay', amount: 0 },
        { category: 'projectManagerPay', label: 'Project Manager Pay', amount: 0 }
      ]
    },
    contactsInfo: []
  });
  const [errors, setErrors] = useState({
    name: '',
    budget: ''
  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isEditingTemplate, setIsEditingTemplate] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const { templates = [], isLoading: templatesLoading } = useSelector((state) => 
    state.templates || {}
  );
  const { user } = useSelector((state) => state.auth);
  const [errorModal, setErrorModal] = useState({
    isOpen: false,
    title: '',
    message: ''
  });
  const [isTemplateModified, setIsTemplateModified] = useState(false);
  const [originalTemplate, setOriginalTemplate] = useState(null);
  const [visitedStages, setVisitedStages] = useState(new Set([0])); // Start with first stage visited
  const { users: companyUsers, isLoading: usersLoading } = useSelector((state) => state.users);
  const [isValidated, setIsValidated] = useState(false);
  const [hasManuallyNamedTemplate, setHasManuallyNamedTemplate] = useState(false);
  const [templateNameModal, setTemplateNameModal] = useState({
    isOpen: false,
    pendingName: '',
    error: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Move tag handling functions here, before any JSX or useEffect
  const handleTagInput = (e) => {
    // Handle both Enter, comma and Space
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      const value = e.target.value.trim().toLowerCase();
      if (value && !projectDetails.tags.includes(value)) {
        handleProjectDetailsChange('tags', [...projectDetails.tags, value]);
      }
      e.target.value = '';
    }
  };

  // Add a new function to handle blur event for tags
  const handleTagInputBlur = (e) => {
    const value = e.target.value.trim().toLowerCase();
    if (value && !projectDetails.tags.includes(value)) {
      handleProjectDetailsChange('tags', [...projectDetails.tags, value]);
      e.target.value = '';
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    handleProjectDetailsChange('tags', 
      projectDetails.tags.filter(tag => tag !== tagToRemove)
    );
  };

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        await dispatch(getTemplates());
      } catch (error) {
        toast.error('Failed to load templates');
      }
    };
    
    loadTemplates();
  }, [dispatch]);

  const handleTemplateSelect = (e) => {
    const templateId = e.target.value;
    
    if (!templateId) {
      setSelectedTemplate(null);
      setTemplate({
        name: 'Custom Template',
        stages: defaultStages
      });
      setIsTemplateModified(false);
      setTemplateName('');
      setOriginalTemplate(null);
      return;
    }

    const selectedTemp = templates.find(t => t._id === templateId);
    
    if (selectedTemp) {
      const templateCopy = JSON.parse(JSON.stringify(selectedTemp));
      
      setSelectedTemplate(selectedTemp);
      setTemplate(templateCopy);
      setOriginalTemplate(JSON.stringify(selectedTemp.stages));
      setIsTemplateModified(false);
      setTemplateName(selectedTemp.name);
    }
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
    setHasManuallyNamedTemplate(true);
  };

  const checkTemplateModification = (newTemplate) => {
    if (!originalTemplate) return false;
    
    const normalizeTemplate = (template) => {
      return {
        stages: template.stages.map(stage => ({
          name: stage.name,
          order: stage.order,
          tasks: stage.tasks.map(task => ({
            name: task.name,
            subtasks: task.subtasks.map(subtask => ({
              name: subtask.name
            }))
          }))
        }))
      };
    };

    const currentNormalized = normalizeTemplate(newTemplate);
    const originalNormalized = normalizeTemplate({ stages: JSON.parse(originalTemplate) });
    
    return JSON.stringify(currentNormalized) !== JSON.stringify(originalNormalized);
  };

  const handleTemplateSave = async () => {
    if (!user) {
      showError(
        'Authentication Required', 
        'Please log in again to save the template.'
      );
      return;
    }

    if (!templateName.trim()) {
      toast.error('Please enter a template name');
      return;
    }

    const templateData = {
      name: templateName,
      stages: template.stages,
      description: `Template created from ${selectedTemplate ? 'existing template' : 'scratch'}`
    };

    try {
      if (selectedTemplate?._id) {
        await dispatch(updateTemplate({
          ...templateData,
          _id: selectedTemplate._id,
        })).unwrap();
        toast.success('Template updated successfully');
      } else {
        await dispatch(createTemplate({
          ...templateData,
        })).unwrap();
        toast.success('Template saved successfully');
      }
    } catch (error) {
      showError(
        'Template Save Error',
        error.message || 'Failed to save template. Please try again.'
      );
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        if (!value.trim()) {
          return 'Project name is required';
        }
        if (value.length < 3) {
          return 'Project name must be at least 3 characters';
        }
        return '';
      case 'location':
        if (!value.address) {
          return 'Location is required';
        }
        return '';
      case 'budget':
        if (!value || value <= 0) {
          return 'Budget must be greater than 0';
        }
        if (value > 1000000000) {
          return 'Budget cannot exceed 1 billion';
        }
        return '';
      default:
        return '';
    }
  };

  const handleProjectDetailsChange = useCallback((field, value) => {
    setProjectDetails(prev => ({
      ...prev,
      [field]: value
    }));
    
    if (isValidated) {
      const error = validateField(field, value);
      setErrors(prev => ({
        ...prev,
        [field]: error
      }));
    }
  }, [isValidated]);

  const handleAddTask = (stageIndex) => {
    const newTemplate = {...template};
    newTemplate.stages[stageIndex].tasks.push({ 
      name: '',
      completed: false,
      roles: [],
      subtasks: []
    });
    setTemplate(newTemplate);
    setIsTemplateModified(checkTemplateModification(newTemplate));
  };

  const handleRemoveTask = (stageIndex, taskIndex) => {
    const newTemplate = {...template};
    newTemplate.stages[stageIndex].tasks.splice(taskIndex, 1);
    setTemplate(newTemplate);
    setIsTemplateModified(checkTemplateModification(newTemplate));
  };

  const handleTaskChange = (stageIndex, taskIndex, value) => {
    const newTemplate = {...template};
    newTemplate.stages[stageIndex].tasks[taskIndex].name = value;
    setTemplate(newTemplate);
    setIsTemplateModified(checkTemplateModification(newTemplate));
  };

  const handleSubmit = async () => {
    if (!user) {
      showError('Authentication Required', 'Please log in again to create the project.');
      return;
    }

    // Check if template roles were modified and need a new name
    if (selectedTemplate && isTemplateRolesModified(originalTemplate, template) && !templateNameModal.isOpen) {
      setTemplateNameModal({
        isOpen: true,
        pendingName: `${selectedTemplate.name} - Modified`,
        error: ''
      });
      return;
    }

    setIsSubmitting(true);

    try {
      let templateId = selectedTemplate?._id;

      // Always create a new template if it's modified or new
      if (!selectedTemplate || isTemplateRolesModified(originalTemplate, template)) {
        const templateNameToUse = templateNameModal.pendingName || templateName || 'Custom Template';
        
        if (!templateNameToUse.trim()) {
          throw new Error('Template name is required');
        }

        // Prepare template data with proper structure
        const templateData = {
          name: templateNameToUse,
          stages: template.stages.map(stage => ({
            name: stage.name,
            order: stage.order,
            tasks: stage.tasks.map(task => ({
              name: task.name,
              roles: task.roles || [],
              subtasks: task.subtasks.map(subtask => ({
                name: subtask.name,
                duration: subtask.duration || 1,
                estimatedBudget: subtask.estimatedBudget || 12,
                roles: subtask.roles || []
              }))
            }))
          })),
          description: `Template created from ${selectedTemplate ? 'existing template' : 'scratch'}`,
          company: user.companyId
        };

        // Create new template
        const savedTemplate = await dispatch(createTemplate({
          ...templateData,
        })).unwrap();
        
        templateId = savedTemplate._id;
      }

      // Rest of your project creation code...
      const budgetData = {
        materialCosts: projectDetails.budget.breakdown.find(item => item.category === 'materialCosts')?.amount || 0,
        officeOverhead: projectDetails.budget.breakdown.find(item => item.category === 'officeOverhead')?.amount || 0,
        companyProfit: projectDetails.budget.breakdown.find(item => item.category === 'companyProfit')?.amount || 0,
        permitCosts: projectDetails.budget.breakdown.find(item => item.category === 'permitCosts')?.amount || 0,
        draftsmanPay: projectDetails.budget.breakdown.find(item => item.category === 'draftsmanPay')?.amount || 0,
        installerPay: projectDetails.budget.breakdown.find(item => item.category === 'installerPay')?.amount || 0,
        salesmenPay: projectDetails.budget.breakdown.find(item => item.category === 'salesmenPay')?.amount || 0,
        salesManagerPay: projectDetails.budget.breakdown.find(item => item.category === 'salesManagerPay')?.amount || 0,
        projectManagerPay: projectDetails.budget.breakdown.find(item => item.category === 'projectManagerPay')?.amount || 0,
        totalAmount: projectDetails.budget.amount,
        company: user.user.company,
        createdBy: user.user._id
      };

      const createdBudget = await projectService.createBudget(budgetData);
      
      if (!createdBudget || !createdBudget._id) {
        throw new Error('Failed to create budget');
      }

      // Create project with the template ID
      const projectData = {
        name: projectDetails.name,
        description: projectDetails.description || '',
        location: projectDetails.location,
        tags: projectDetails.tags,
        status: projectDetails.status,
        budget: {
          budgetId: createdBudget._id,
          amount: projectDetails.budget.amount
        },
        template: {
          templateId: templateId,
          stages: template.stages.map(stage => ({
            name: stage.name,
            tasks: stage.tasks.map(task => ({
              name: task.name,
              roles: task.roles || [],
              completed: false,
              subtasks: task.subtasks.map(subtask => ({
                name: subtask.name,
                completed: false,
                duration: subtask.duration || 1,
                roles: subtask.roles || []
              }))
            }))
          })),
          description: `Template created from ${selectedTemplate ? 'existing template' : 'scratch'}`,
          company: user.user.company,
          createdBy: user.user._id
        },
        company: user.user.company,
        createdBy: user.user._id,
        contactsInfo: projectDetails.contactsInfo
      };

      const createdProject = await dispatch(createProject({ 
        ...projectData, 
      })).unwrap();

      if (!createdProject) {
        throw new Error('Failed to create project');
      }

      toast.success('Project created successfully');
      navigate(`/projects/${createdProject._id}`);

    } catch (error) {
      console.log(error);
      console.error('Error creating project:', error);
      showError(
        'Project Creation Error',
        error.message || 'Failed to create project. Please try again.'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddSubtask = (stageIndex, taskIndex) => {
    const newTemplate = JSON.parse(JSON.stringify(template));
    if (!newTemplate.stages[stageIndex].tasks[taskIndex].subtasks) {
      newTemplate.stages[stageIndex].tasks[taskIndex].subtasks = [];
    }
    
    // Get parent task's roles to initialize subtask with
    const parentTaskRoles = newTemplate.stages[stageIndex].tasks[taskIndex].roles || [];
    
    newTemplate.stages[stageIndex].tasks[taskIndex].subtasks.push({ 
      name: '',
      completed: false,
      duration: 1,
      roles: [...parentTaskRoles] // Initialize with parent task's roles
    });
    setTemplate(newTemplate);
    setIsTemplateModified(checkTemplateModification(newTemplate));
  };

  const handleRemoveSubtask = (stageIndex, taskIndex, subtaskIndex) => {
    const newTemplate = {...template};
    newTemplate.stages[stageIndex].tasks[taskIndex].subtasks.splice(subtaskIndex, 1);
    setTemplate(newTemplate);
    setIsTemplateModified(checkTemplateModification(newTemplate));
  };

  const handleSubtaskChange = (stageIndex, taskIndex, subtaskIndex, field, value) => {
    const newTemplate = JSON.parse(JSON.stringify(template)); // Deep clone
    const subtask = newTemplate.stages[stageIndex].tasks[taskIndex].subtasks[subtaskIndex];
    
    if (field === 'roles') {
      subtask.roles = value;
    } else if (field === 'estimatedBudget') {
      // Add console.log to debug
      console.log('Setting estimated budget:', value);
      // Ensure we're storing as a number and not a string
      subtask.estimatedBudget = Number(value);
    } else {
      subtask[field] = value;
    }
    
    // Add console.log to verify the template state
    console.log('Updated template:', JSON.stringify(newTemplate.stages[stageIndex].tasks[taskIndex].subtasks[subtaskIndex], null, 2));
    
    setTemplate(newTemplate);
    setIsTemplateModified(checkTemplateModification(newTemplate));
  };

  const handleAddStage = () => {
    const newTemplate = {...template};
    newTemplate.stages.push({
      name: `NEW_STAGE_${newTemplate.stages.length + 1}`,
      order: newTemplate.stages.length,
      completed: false,
      tasks: []
    });
    setTemplate(newTemplate);
    setIsTemplateModified(checkTemplateModification(newTemplate));
  };

  const handleDeleteStage = (stageIndex) => {
    if (template.stages.length <= 1) {
      toast.error("You must have at least one stage");
      return;
    }
    
    const newTemplate = {...template};
    newTemplate.stages.splice(stageIndex, 1);
    
    // Update order of remaining stages
    newTemplate.stages.forEach((stage, index) => {
      stage.order = index;
    });
    
    setTemplate(newTemplate);
    setIsTemplateModified(checkTemplateModification(newTemplate));
    
    // Adjust currentStageIndex if needed
    if (currentStageIndex >= newTemplate.stages.length) {
      setCurrentStageIndex(newTemplate.stages.length - 1);
    }
  };

  const handleStageNameChange = (stageIndex, newName) => {
    const newTemplate = {...template};
    newTemplate.stages[stageIndex].name = newName.toUpperCase().replace(/\s+/g, '_');
    setTemplate(newTemplate);
    setIsTemplateModified(checkTemplateModification(newTemplate));
  };

  const showError = (title, message) => {
    setErrorModal({
      isOpen: true,
      title,
      message
    });
  };

  const handleStageNavigation = (newIndex) => {
    setCurrentStageIndex(newIndex);
    setVisitedStages(prev => new Set([...prev, newIndex]));
  };

  const hasVisitedAllStages = () => {
    return visitedStages.size === template.stages.length;
  };

  const renderSubtask = (stageIndex, taskIndex, subtask, subtaskIndex) => (
    <div key={subtaskIndex} className="flex items-center gap-4 p-2 bg-gray-50 rounded-md">
      <span className="w-1.5 h-1.5 bg-gray-400 rounded-full"></span>
      <div className="flex-1">
        <input
          type="text"
          value={subtask.name}
          onChange={(e) => handleSubtaskChange(stageIndex, taskIndex, subtaskIndex, 'name', e.target.value)}
          className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="Subtask name"
        />
      </div>

      {/* Duration Input */}
      <div className="flex items-center min-w-[140px] gap-2">
        <label className="text-sm text-gray-600 whitespace-nowrap">Duration:</label>
        <div className="relative rounded-md shadow-sm">
          <input
            type="number"
            min="0.5"
            step="0.5"
            value={subtask.duration}
            onChange={(e) => {
              const newValue = parseFloat(e.target.value);
              if (!isNaN(newValue) && newValue >= 0.5) {
                handleSubtaskChange(
                  stageIndex,
                  taskIndex,
                  subtaskIndex,
                  'duration',
                  newValue
                );
              }
            }}
            className="w-20 text-sm rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 pr-12"
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">
            <span className="text-gray-500 text-sm">days</span>
          </div>
        </div>
      </div>

      {/* Roles Select */}
      <div className="w-64">
        <Select
          isMulti
          options={AVAILABLE_ROLES}
          value={AVAILABLE_ROLES.filter(role => 
            subtask.roles?.includes(role.value)
          )}
          onChange={(selected) => handleSubtaskChange(
            stageIndex, 
            taskIndex, 
            subtaskIndex, 
            'roles', 
            selected ? selected.map(s => s.value) : []
          )}
          placeholder="Select roles"
          styles={selectStyles}
        />
      </div>

      {/* Delete button */}
      <button
        onClick={() => handleRemoveSubtask(stageIndex, taskIndex, subtaskIndex)}
        className="text-red-600 hover:text-red-800 p-1"
        aria-label="Remove subtask"
      >
        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  );

  const stageConfigurationContent = (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <h3 className="text-xl font-semibold text-gray-800">
            Stage {currentStageIndex + 1}
          </h3>
          <input
            type="text"
            value={template.stages[currentStageIndex].name}
            onChange={(e) => handleStageNameChange(currentStageIndex, e.target.value)}
            className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="danger"
            onClick={() => handleDeleteStage(currentStageIndex)}
            disabled={template.stages.length <= 1}
          >
            Delete Stage
          </Button>
          <Button
            variant="secondary"
            onClick={handleAddStage}
          >
            Add New Stage
          </Button>
        </div>
      </div>

      <div className="space-y-4">
        {template.stages[currentStageIndex].tasks.map((task, taskIndex) => (
          <div key={taskIndex}>
            <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
              <span className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center font-medium">
                {taskIndex + 1}
              </span>
              <input
                type="text"
                value={task.name}
                onChange={(e) => handleTaskChange(currentStageIndex, taskIndex, e.target.value)}
                className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Task name"
              />
              
              {/* Add role selector for task level */}
              <div className="w-64">
                <Select
                  isMulti
                  options={AVAILABLE_ROLES}
                  value={AVAILABLE_ROLES.filter(role => 
                    task.roles?.includes(role.value)
                  )}
                  onChange={(selected) => handleTaskRolesChange(
                    currentStageIndex,
                    taskIndex,
                    selected ? selected.map(s => s.value) : []
                  )}
                  placeholder="Set roles for all subtasks"
                  styles={selectStyles}
                />
              </div>

              <button
                onClick={() => handleRemoveTask(currentStageIndex, taskIndex)}
                className="flex-shrink-0 p-2 text-red-600 hover:text-red-800 rounded-full hover:bg-red-50 transition-colors"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </div>
            
            {/* Subtasks section */}
            <div className="ml-12 space-y-2">
              {task.subtasks?.map((subtask, subtaskIndex) => 
                renderSubtask(currentStageIndex, taskIndex, subtask, subtaskIndex)
              )}
              <button
                onClick={() => handleAddSubtask(currentStageIndex, taskIndex)}
                className="mt-2 text-sm text-blue-600 hover:text-blue-700"
              >
                + Add Subtask
              </button>
            </div>
          </div>
        ))}
        
        <Button
          variant="secondary"
          onClick={() => handleAddTask(currentStageIndex)}
          className="w-full mt-4"
        >
          Add New Task
        </Button>
      </div>
    </div>
  );

  const templateSelectionContent = (
    <div className="mt-6 space-y-4">
      <div className="border-b border-gray-200 pb-3">
        <h3 className="text-xl font-semibold text-gray-800">Project Template</h3>
        <p className="text-sm text-gray-500">Select an existing template or create a new one</p>
      </div>
      
      <div className="grid gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Select Template
          </label>
          {templatesLoading ? (
            <div>Loading templates...</div>
          ) : (
            <Select
              value={selectedTemplate ? {
                value: selectedTemplate._id,
                label: selectedTemplate.name
              } : null}
              onChange={(selected) => {
                if (!selected) {
                  setSelectedTemplate(null);
                  setTemplate({
                    name: 'Custom Template',
                    stages: defaultStages
                  });
                  setIsTemplateModified(false);
                  setTemplateName('');
                  setOriginalTemplate(null);
                  return;
                }

                const selectedTemp = templates.find(t => t._id === selected.value);
                if (selectedTemp) {
                  const templateCopy = JSON.parse(JSON.stringify(selectedTemp));
                  setSelectedTemplate(selectedTemp);
                  setTemplate(templateCopy);
                  setOriginalTemplate(JSON.stringify(selectedTemp.stages));
                  setIsTemplateModified(false);
                  setTemplateName(selectedTemp.name);
                }
              }}
              options={templates.map(template => ({
                value: template._id,
                label: template.name
              }))}
              isClearable
              placeholder="Select a template or create new"
              className="text-sm"
              classNamePrefix="select"
              isSearchable
              noOptionsMessage={() => "No templates available"}
            />
          )}
          {templates.length === 0 && !templatesLoading && (
            <p className="text-sm text-gray-500 mt-2">No templates available</p>
          )}
        </div>

        {!selectedTemplate && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              New Template Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className={getInputClassName(errors.template)}
              placeholder="Enter template name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              required
            />
            {errors.template && (
              <p className="mt-1.5 text-xs text-red-600">{errors.template}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );

  // Modify validateProjectDetails to return validation result
  const validateProjectDetails = () => {
    const validationErrors = {};
    
    if (!projectDetails.name.trim()) {
      validationErrors.name = 'Project name is required';
    }

    if (!projectDetails.location.address) {
      validationErrors.location = 'Location is required';
    }

    if (!projectDetails.description.trim()) {
      validationErrors.description = 'Project description is required';
    }

    if (!projectDetails.budget.amount || projectDetails.budget.amount <= 0) {
      validationErrors.budget = 'Budget must be greater than 0';
    }

    return {
      isValid: Object.keys(validationErrors).length === 0,
      errors: validationErrors
    };
  };

  // Handle step progression as a callback
  const handleStepProgression = useCallback(() => {
    if (currentStep === 0) {
      const { isValid, errors: validationErrors } = validateProjectDetails();
      
      setErrors(validationErrors);
      setIsValidated(true);
      
      if (!selectedTemplate && !templateName.trim()) {
        setErrors(prev => ({
          ...prev,
          template: 'Please select a template or enter a name for a new one'
        }));
        return false;
      }
      
      return isValid;
    }
    return true;
  }, [currentStep, projectDetails, selectedTemplate, templateName]);

  // Modify the steps array
  const steps = useMemo(() => [
    {
      title: 'Project Details',
      content: (
        <div className="bg-white rounded-lg shadow-sm p-6 max-w-3xl mx-auto">
          {/* Project Information Section */}
          <div className="space-y-4">
            <div className="border-b border-gray-200 pb-2">
              <h3 className="text-lg font-medium text-gray-800">Project Information</h3>
            </div>
            
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Project Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={projectDetails.name}
                  onChange={(e) => handleProjectDetailsChange('name', e.target.value)}
                  className={getInputClassName(errors.name)}
                  placeholder="Enter project name"
                />
                {errors.name && (
                  <p className="mt-1.5 text-xs text-red-600">{errors.name}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Location <span className="text-red-500">*</span>
                </label>
                <GoogleLocationPicker
                  value={projectDetails.location.address}
                  onChange={(locationData) => {
                    handleProjectDetailsChange('location', locationData.location);
                  }}
                  errors={errors.location}
                  className={baseInputClass}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Tags
              </label>
              <div className="flex flex-wrap gap-2 p-2 border rounded-md border-gray-300 bg-white">
                {projectDetails.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                  >
                    {tag}
                    <button
                      type="button"
                      onClick={() => handleRemoveTag(tag)}
                      className="ml-1 inline-flex items-center p-0.5 rounded-full text-blue-400 hover:bg-blue-200 hover:text-blue-600"
                    >
                      <svg className="h-3 w-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </span>
                ))}
                <input
                  type="text"
                  onKeyDown={handleTagInput}
                  onBlur={handleTagInputBlur}
                  className="flex-1 outline-none border-0 focus:ring-0 min-w-[120px] text-sm"
                  placeholder="Type and press Enter, Space or comma to add tags"
                />
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Press Enter, Space, or comma to add a tag
              </p>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Description <span className="text-red-500">*</span>
              </label>
              <textarea
                value={projectDetails.description}
                onChange={(e) => handleProjectDetailsChange('description', e.target.value)}
                className={getInputClassName(errors.description)}
                rows={3}
                placeholder="Describe the project scope, objectives, and any special requirements..."
              />
              {errors.description && (
                <p className="mt-1.5 text-xs text-red-600">{errors.description}</p>
              )}
            </div>

            {/* Status Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-600 mb-1">
                Project Status
              </label>
              <select
                value={projectDetails.status}
                onChange={(e) => handleProjectDetailsChange('status', e.target.value)}
                className={getInputClassName(errors.status)}
              >
                <option value="pending">Pending</option>
                <option value="sold">Sold</option>
                <option value="pre-construction">Pre-Construction</option>
                <option value="permitting">Permitting</option>
                <option value="construction">Construction</option>
                <option value="done">Done</option>
              </select>
            </div>

            {/* Budget Section */}
            <div className="mt-8 space-y-4">
              <div className="border-b border-gray-200 pb-2">
                <h3 className="text-lg font-medium text-gray-800">Budget Details</h3>
                <p className="text-sm text-gray-500">Break down your project budget into categories</p>
              </div>
              
              <BudgetSection
                budget={projectDetails.budget}
                onChange={(newBudget) => handleProjectDetailsChange('budget', newBudget)}
                errors={errors}
              />
            </div>

            {/* Template Selection */}
            {templateSelectionContent}
          </div>
        </div>
      ),
      canProceed: handleStepProgression
    },
    {
      title: 'Stage Configuration',
      content: (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-4">
              <h3 className="text-xl font-semibold text-gray-800">
                Stage {currentStageIndex + 1}
              </h3>
              <input
                type="text"
                value={template.stages[currentStageIndex].name}
                onChange={(e) => handleStageNameChange(currentStageIndex, e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div className="flex items-center gap-2">
              <Button
                variant="danger"
                onClick={() => handleDeleteStage(currentStageIndex)}
                disabled={template.stages.length <= 1}
              >
                Delete Stage
              </Button>
              <Button
                variant="secondary"
                onClick={handleAddStage}
              >
                Add New Stage
              </Button>
            </div>
          </div>

          <div className="space-y-4">
            {template.stages[currentStageIndex].tasks.map((task, taskIndex) => (
              <div key={taskIndex}>
                <div className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                  <span className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center font-medium">
                    {taskIndex + 1}
                  </span>
                  <input
                    type="text"
                    value={task.name}
                    onChange={(e) => handleTaskChange(currentStageIndex, taskIndex, e.target.value)}
                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    placeholder="Task name"
                  />
                  
                  {/* Add role selector for task level */}
                  <div className="w-64">
                    <Select
                      isMulti
                      options={AVAILABLE_ROLES}
                      value={AVAILABLE_ROLES.filter(role => 
                        task.roles?.includes(role.value)
                      )}
                      onChange={(selected) => handleTaskRolesChange(
                        currentStageIndex,
                        taskIndex,
                        selected ? selected.map(s => s.value) : []
                      )}
                      placeholder="Set roles for all subtasks"
                      styles={selectStyles}
                    />
                  </div>

                  <button
                    onClick={() => handleRemoveTask(currentStageIndex, taskIndex)}
                    className="flex-shrink-0 p-2 text-red-600 hover:text-red-800 rounded-full hover:bg-red-50 transition-colors"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
                
                {/* Subtasks section */}
                <div className="ml-12 space-y-2">
                  {task.subtasks?.map((subtask, subtaskIndex) => 
                    renderSubtask(currentStageIndex, taskIndex, subtask, subtaskIndex)
                  )}
                  <button
                    onClick={() => handleAddSubtask(currentStageIndex, taskIndex)}
                    className="mt-2 text-sm text-blue-600 hover:text-blue-700"
                  >
                    + Add Subtask
                  </button>
                </div>
              </div>
            ))}
            
            <Button
              variant="secondary"
              onClick={() => handleAddTask(currentStageIndex)}
              className="w-full mt-4"
            >
              Add New Task
            </Button>
          </div>
        </div>
      )
    }
  ], [handleStepProgression, projectDetails, errors, templateSelectionContent]);

  // Add error display for project details
  const renderError = (fieldName) => {
    return errors[fieldName] ? (
      <p className="mt-1 text-sm text-red-600">{errors[fieldName]}</p>
    ) : null;
  };

  // Modify the project details form to show all validation errors
  const projectDetailsContent = (
    <div className="bg-white rounded-lg shadow-sm p-6">
      {/* Project Information Section */}
      <div className="space-y-4">
        <div className="border-b border-gray-200 pb-2">
          <h3 className="text-lg font-medium text-gray-800">Project Information</h3>
        </div>
        
        <div className="grid grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Project Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={projectDetails.name}
              onChange={(e) => handleProjectDetailsChange('name', e.target.value)}
              className={getInputClassName(errors.name)}
              placeholder="Enter project name"
            />
            {errors.name && (
              <p className="mt-1.5 text-xs text-red-600">{errors.name}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Location <span className="text-red-500">*</span>
            </label>
            <GoogleLocationPicker
              value={projectDetails.location.address}
              onChange={(locationData) => {
                handleProjectDetailsChange('location', locationData.location);
              }}
              errors={errors.location}
              className={baseInputClass}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Tags
          </label>
          <div className="flex flex-wrap gap-2 p-2 border rounded-md border-gray-300 bg-white">
            {projectDetails.tags.map((tag, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
              >
                {tag}
                <button
                  type="button"
                  onClick={() => handleRemoveTag(tag)}
                  className="ml-1 inline-flex items-center p-0.5 rounded-full text-blue-400 hover:bg-blue-200 hover:text-blue-600"
                >
                  <svg className="h-3 w-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </span>
            ))}
            <input
              type="text"
              onKeyDown={handleTagInput}
              onBlur={handleTagInputBlur}
              className="flex-1 outline-none border-0 focus:ring-0 min-w-[120px] text-sm"
              placeholder="Type and press Enter, Space or comma to add tags"
            />
          </div>
          <p className="mt-1 text-xs text-gray-500">
            Press Enter, Space, or comma to add a tag
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            value={projectDetails.description}
            onChange={(e) => handleProjectDetailsChange('description', e.target.value)}
            className={getInputClassName(errors.description)}
            rows={3}
            placeholder="Describe the project scope, objectives, and any special requirements..."
          />
          {errors.description && (
            <p className="mt-1.5 text-xs text-red-600">{errors.description}</p>
          )}
        </div>

        {/* Status Selection */}
        <div>
          <label className="block text-sm font-medium text-gray-600 mb-1">
            Project Status
          </label>
          <select
            value={projectDetails.status}
            onChange={(e) => handleProjectDetailsChange('status', e.target.value)}
            className={getInputClassName(errors.status)}
          >
            <option value="pending">Pending</option>
            <option value="sold">Sold</option>
            <option value="pre-construction">Pre-Construction</option>
            <option value="permitting">Permitting</option>
            <option value="construction">Construction</option>
            <option value="done">Done</option>
          </select>
        </div>

        {/* Budget Section */}
        <div className="mt-8 space-y-4">
          <div className="border-b border-gray-200 pb-2">
            <h3 className="text-lg font-medium text-gray-800">Budget Details</h3>
            <p className="text-sm text-gray-500">Break down your project budget into categories</p>
          </div>
          
          <BudgetSection
            budget={projectDetails.budget}
            onChange={(newBudget) => handleProjectDetailsChange('budget', newBudget)}
            errors={errors}
          />
        </div>

        {/* Template Selection */}
        {templateSelectionContent}
      </div>
    </div>
  );

  // Navigation button click handler
  // const handleNavigationClick = useCallback(() => {
  //   if (currentStep === steps.length - 1) {
  //     handleSubmit();
  //   } else {
  //     const canProceed = handleStepProgression();
      
  //     if (canProceed) {
  //       setCurrentStep(prev => prev + 1);
  //     }
  //   }
  // }, [currentStep, steps.length, handleStepProgression, handleSubmit]);

  // Add this new handler for task-level role changes
  const handleTaskRolesChange = (stageIndex, taskIndex, selectedRoles) => {
    const newTemplate = JSON.parse(JSON.stringify(template)); // Deep clone to avoid reference issues
    const task = newTemplate.stages[stageIndex].tasks[taskIndex];
    
    // Set the roles at task level
    task.roles = selectedRoles;
    
    // Apply the same roles to all subtasks
    if (task.subtasks) {
      task.subtasks.forEach(subtask => {
        subtask.roles = [...selectedRoles];
      });
    }
    
    setTemplate(newTemplate);
  };

  return (
    <div className="container mx-auto px-6 py-8">
      <div className="max-w-[1440px] mx-auto">
        <Breadcrumbs 
          items={[
            { label: 'Projects', href: '/projects' },
            { label: 'New Project' }
          ]} 
        />
        
        <div className="mb-8">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h2 className="text-2xl font-bold text-gray-900">Create New Project</h2>
              <p className="mt-1 text-sm text-gray-500">{steps[currentStep].title}</p>
            </div>
          </div>

          {currentStep === 1 && (
            <div className="mb-8 bg-white rounded-lg shadow-sm p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-gray-900">
                  {template.stages[currentStageIndex].name}
                </h3>
                <span className="text-sm text-gray-500">
                  Stage {currentStageIndex + 1} of {template.stages.length}
                </span>
              </div>
              <div className="relative">
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                  <div
                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600 transition-all duration-500"
                    style={{ width: `${((currentStageIndex + 1) / template.stages.length) * 100}%` }}
                  />
                </div>
                <div className="flex justify-between">
                  {template.stages.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => handleStageNavigation(index)}
                      className={`w-8 h-8 rounded-full flex items-center justify-center transition-colors ${
                        index === currentStageIndex
                          ? 'bg-blue-600 text-white transform scale-110'
                          : index < currentStageIndex
                          ? 'bg-green-500 text-white'
                          : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
                      }`}
                    >
                      {index + 1}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}

          {currentStep === 1 ? (
            stageConfigurationContent
          ) : (
            steps[currentStep].content
          )}
        </div>

        {/* Navigation Controls */}
        <div className="mt-8 flex justify-between items-center pt-5 border-t border-gray-200 bg-white px-6 py-4 rounded-lg shadow-sm">
          {currentStep > 0 ? (
            <Button 
              variant="secondary" 
              onClick={() => {
                if (currentStageIndex > 0) {
                  handleStageNavigation(currentStageIndex - 1);
                } else {
                  setCurrentStep(prev => prev - 1);
                }
              }}
            >
              {currentStageIndex > 0 ? 'Previous Stage' : 'Back'}
            </Button>
          ) : (
            <Link to="/projects">
              <Button variant="secondary">
                Cancel
              </Button>
            </Link>
          )}
          <div className="flex-1" />
          <Button
            variant="primary"
            onClick={() => {
              if (currentStep === steps.length - 1) {
                if (currentStageIndex < template.stages.length - 1) {
                  handleStageNavigation(currentStageIndex + 1);
                } else if (hasVisitedAllStages()) {
                  handleSubmit();
                }
              } else {
                const canProceed = handleStepProgression();
                if (canProceed) {
                  setCurrentStep(prev => prev + 1);
                }
              }
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="flex items-center gap-2">
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Creating Project...
              </div>
            ) : currentStep === steps.length - 1 ? (
              currentStageIndex < template.stages.length - 1 ? 
                'Next Stage' : 
                (hasVisitedAllStages() ? 'Create Project' : `Review All Stages (${visitedStages.size}/${template.stages.length})`)
            ) : (
              'Next Step'
            )}
          </Button>
        </div>

        {/* Warning message - only show when on last stage and not all stages visited */}
        {currentStep === steps.length - 1 && 
         currentStageIndex === template.stages.length - 1 && 
         !hasVisitedAllStages() && (
          <div className="mt-4 p-4 bg-yellow-50 border border-yellow-200 rounded-md">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Please review all stages before creating the project
                </h3>
                <p className="mt-1 text-sm text-yellow-700">
                  You have reviewed {visitedStages.size} out of {template.stages.length} stages.
                </p>
              </div>
            </div>
          </div>
        )}

        <ErrorModal
          isOpen={errorModal.isOpen}
          onClose={() => setErrorModal(prev => ({ ...prev, isOpen: false }))}
          title={errorModal.title}
          message={errorModal.message}
        />
        
        <TemplateNamingModal 
          isOpen={templateNameModal.isOpen}
          pendingName={templateNameModal.pendingName}
          error={templateNameModal.error}
          onClose={() => !isSubmitting && setTemplateNameModal(prev => ({ ...prev, isOpen: false }))}
          onChange={(e) => setTemplateNameModal(prev => ({
            ...prev,
            pendingName: e.target.value,
            error: ''
          }))}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
    </div>
  );
};

export default ProjectWizard; 