// Module dependencies
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

// UI
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from 'components/catalyst/dropdown';
import { Heading } from 'components/catalyst/heading';
import Card from 'components/common/Card'
import { Button } from 'components/catalyst/button';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Input } from 'components/catalyst/input';
import { Text } from 'components/catalyst/text';
import Loader from 'components/Loader';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/16/solid'

// Utils
import wording from 'utils/wording';
import { formatDate } from 'utils/commons';

const WODetailScheduleTab = ({
  workOrder,
  calendarRef,
  scheduleView,
  currentDate,
  handlePrevious,
  handleNext,
  handleViewChange,
  isLoading,
  isEditing,
  editedData,
  handleChange,
}) => {
  const {
    WO_DETAIL_SCHEDULE,
    WO_DETAIL_NO_SCHEDULE,
    GLOBAL_MONTH,
    GLOBAL_WEEK,
    WO_DETAIL_START_DATE,
    WO_DETAIL_END_DATE,
  } = wording;

  const calendarEvent = {
    id: workOrder._id,
    title: workOrder.client?.name || 'Unnamed Client',
    start: workOrder.startDate,
    end: workOrder.endDate,
    backgroundColor: '#E3F2FD',
    borderColor: '#90CAF9',
    textColor: '#1565C0',
    classNames: ['shadow-sm', 'border']
  };

  return (
    <>
      {isLoading ? (
        <div className='flex items-center justify-center w-full h-96'>
          <Loader />
        </div>
      ) : (
        <Card>
          <div className='flex items-center justify-between mb-4'>
            <Heading level={2} className='pb-2'>
              {WO_DETAIL_SCHEDULE}
            </Heading>

            <div className='flex items-center justify-end gap-2'>
              <Dropdown>
                <DropdownButton outline className='capitalize'>
                  {scheduleView}
                  <ChevronDownIcon />
                </DropdownButton>

                <DropdownMenu>
                  <DropdownItem onClick={() => handleViewChange('month')}>
                    {GLOBAL_MONTH}
                  </DropdownItem>

                  <DropdownItem onClick={() => handleViewChange('week')}>
                    {GLOBAL_WEEK}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Button outline onClick={handlePrevious}>
                <ArrowLongLeftIcon />
              </Button>

              <Button outline onClick={handleNext}>
                <ArrowLongRightIcon />
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-20 mb-4">
            <DescriptionList className='justify-between'>
              <DescriptionTerm>
                {WO_DETAIL_START_DATE}
              </DescriptionTerm>

              <DescriptionDetails>
                {isEditing ? (
                  <Input
                    type="date"
                    name="startDate"
                    value={editedData.startDate ? editedData.startDate.slice(0, 10) : ''}
                    onChange={handleChange}
                    required
                  />
                ) : (
                  <>
                    {formatDate(workOrder?.startDate)}
                  </>
                )}
              </DescriptionDetails>
            </DescriptionList>

            <DescriptionList className='justify-between'>
              <DescriptionTerm>
                {WO_DETAIL_END_DATE}
              </DescriptionTerm>

              <DescriptionDetails>
                {isEditing ? (
                  <Input
                    type="date"
                    name="endDate"
                    value={editedData.endDate ? editedData.endDate.slice(0, 10) : ''}
                    onChange={handleChange}
                    min={editedData.startDate ? editedData.startDate.slice(0, 10) : ''}
                    required
                  />
                ) : (
                  <>
                    {formatDate(workOrder?.endDate)}
                  </>
                )}
              </DescriptionDetails>
            </DescriptionList>
          </div>

          {(workOrder?.startDate && workOrder?.endDate) || isEditing ? (
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView={scheduleView === 'month' ? 'dayGridMonth' : 'timeGridWeek'}
              initialDate={currentDate}
              events={[calendarEvent]}
              headerToolbar={{
                left: '',
                center: 'title',
                right: ''
              }}
              views={{
                dayGridMonth: {
                  dayMaxEvents: 4,
                },
                timeGridWeek: {
                  dayMaxEvents: true,
                  slotMinTime: '06:00:00',
                  slotMaxTime: '20:00:00',
                  expandRows: true,
                  allDaySlot: true,
                  slotEventOverlap: false,
                  dayHeaderFormat: { weekday: 'short', day: 'numeric' },
                  scrollTime: '06:00:00',
                }
              }}
              eventContent={(eventInfo) => (
                <div className={`
                  flex items-center gap-1 p-1 w-full
                  ${scheduleView === 'week' ? 'min-h-[40px]' : 'min-h-[24px]'}
                `}>
                  <span className={`
                    font-medium truncate
                    ${scheduleView === 'week' ? 'text-sm' : 'text-xs'}
                  `}>
                    {eventInfo.event.title}
                  </span>
                </div>
              )}
              eventDidMount={(info) => {
                const isWeekView = scheduleView === 'week';
                const height = isWeekView ? '40px' : '24px';
                
                info.el.style.height = height;
                info.el.style.padding = '0px';
                info.el.style.margin = isWeekView ? '4px 0' : '1px 0';
                info.el.style.overflow = 'hidden';
              }}
              height="calc(100vh - 20rem)"
              className="fc-tailwind"
              firstDay={1}
              weekends={true}
              fixedWeekCount={false}
              showNonCurrentDates={false}
            />
          ) : (
            <Text>{WO_DETAIL_NO_SCHEDULE}</Text>
          )}
        </Card>
      )}
    </>

  );
};

export default WODetailScheduleTab;
