
const { useRef, useCallback, useState } = require("react");

const useTableFeatures = (tableKey) => {
  const COLUMN_ORDER_KEY = `nimax_user_preference_${tableKey}_columns_state`;
  const PAGINATION_SIZE_KEY = `nimax_user_preference_${tableKey}_page_size`;

  const tableRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');

  const [initialColumState, setInitialColumState] = useState([]);

  const handleSearchTermChange = (newValue) => {
    setSearchTerm(newValue);
  };

  const handleFilter = useCallback((filterValue) => {
    if (tableRef.current) {
      const filterTextBox = document.getElementById("filter-text-box");

      const correctValue = filterValue ? filterValue : filterTextBox.value;

      tableRef.current?.api.setGridOption(
        "quickFilterText",
        correctValue
      );
      
      setSearchTerm(correctValue);
    }
  }, []);

  const handleCleanFilterText = useCallback(() => {
    if (tableRef.current) {
      tableRef.current?.api.setGridOption("quickFilterText", '');
      setSearchTerm('');
    }
  }, []);

  const onDragStarted = (e) => {
    const actualColumnOrder = e.api.getColumnState().map(c => c.colId);

    setInitialColumState(actualColumnOrder);
  };

  const onDragStopped = (e) => {
    const newColumnOrder = e.api.getColumnState();
    const mappedColumnOrder = newColumnOrder.map(c => c.colId);
  
    const sameOrder = initialColumState.every(
      (c, i) => c === mappedColumnOrder[i]
    );

    if (!sameOrder) {
      localStorage.setItem(COLUMN_ORDER_KEY, JSON.stringify(e.api.getColumnState()))
    }
  };

  const onPaginationChanged = (e) => {
    if (e.newPageSize) {
      const newPageSize = e.api.paginationGetPageSize();

      localStorage.setItem(PAGINATION_SIZE_KEY, newPageSize);
    }
  };

  const onGridReady = (e) => {
    const userPreferenceColumnOrder = localStorage.getItem(COLUMN_ORDER_KEY);
    const userPreferencePageSize = localStorage.getItem(PAGINATION_SIZE_KEY)

    const parsedOrder = JSON.parse(userPreferenceColumnOrder);

    if (userPreferenceColumnOrder) {
      e.api.applyColumnState({
        state: parsedOrder,
        applyOrder: true,
      });
    }

    if (userPreferencePageSize) {
      e.api.setGridOption('paginationPageSize', userPreferencePageSize)
    }
  };

  return {
    tableRef,
    searchTerm,
    handleSearchTermChange,
    handleCleanFilterText,
    handleFilter,
    onDragStarted,
    onDragStopped,
    onGridReady,
    onPaginationChanged,
  }
};

export default useTableFeatures;
