// Module dependencies
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { featureConfig } from 'config/features';
import packageJson from '../../../package.json';

// UI
import UserMenu from './UserMenu';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from 'components/catalyst/sidebar'
import {
  HomeIcon,
  ClipboardDocumentListIcon,
  FolderIcon,
  CalendarDaysIcon,
  UsersIcon,
  CalendarIcon,
  DocumentIcon,
  MoonIcon,
  SunIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  MapPinIcon,
} from '@heroicons/react/20/solid';
import { Badge } from 'components/catalyst/badge';
import NimaxLogo from 'components/NimaxLogo';

// Utils
import wording from 'utils/wording';
import { Strong, Text } from 'components/catalyst/text';
import { Fragment, useState } from 'react';

const SidebarMain = ({
  isDarkMode,
  toggleDarkMode,
}) => {
  const location = useLocation();

  // TODO: For now always open, Reactivate when we have more features
  // const workOrdersPaths = [
  //   "/work-orders",
  //   "/work-orders/pending-locations",
  //   "/work-orders/global-schedule",
  // ];

  // const isWorkOrdersPath = workOrdersPaths.includes(location.pathname);

  const [panelState, setPanelState] = useState({
    'work-orders': true,
  });

  // const togglePanelState = (id) => {
  //   setPanelState((prevState) => ({
  //     ...prevState,
  //     [id]: !prevState[id],
  //   }));
  // };

  const {
    SIDEBAR_DASHBOARD,
    SIDEBAR_PROJECTS,
    SIDEBAR_SCHEDULING,
    SIDEBAR_WORK_ORDERS,
    SIDEBAR_TEAM,
    SIDEBAR_DOCUMENTS,
    SIDEBAR_THEME,
    SIDEBAR_VERSION,
    SIDEBAR_PENDING_LOCATIONS,
    SIDEBAR_GLOBAL_SCHEDULE,
    GLOBAL_NEW,
    GLOBAL_SOON,
  } = wording;

  const navigationList = [
    {
      id: "dashboard",
      name: SIDEBAR_DASHBOARD,
      href: "/dashboard",
      icon: <HomeIcon />,
      enabled: featureConfig.dashboard,
    },
    {
      name: SIDEBAR_PROJECTS,
      href: "/projects",
      icon: <FolderIcon />,
      enabled: featureConfig.projects,
    },
    {
      name: SIDEBAR_SCHEDULING,
      href: "/schedule",
      icon: <CalendarIcon />,
      enabled: featureConfig.schedule,
    },
    {
      id: "work-orders",
      name: SIDEBAR_WORK_ORDERS,
      href: "/work-orders",
      icon: panelState["work-orders"] ? (
        <ChevronDownIcon />
      ) : (
        <ChevronRightIcon />
      ),
      enabled: featureConfig.workOrders,
      children: [
        {
          name: SIDEBAR_WORK_ORDERS,
          href: "/work-orders",
          icon: <ClipboardDocumentListIcon />,
        },
        {
          name: SIDEBAR_PENDING_LOCATIONS,
          href: "/work-orders/pending-locations",
          icon: <MapPinIcon />,
        },
        {
          name: SIDEBAR_GLOBAL_SCHEDULE,
          href: "/work-orders/global-schedule",
          icon: <CalendarIcon />,
        },
      ],
    },
    {
      name: SIDEBAR_TEAM,
      href: "/team",
      icon: <UsersIcon />,
      enabled: featureConfig.team,
    },
    {
      name: SIDEBAR_DOCUMENTS,
      href: "/documents",
      icon: <DocumentIcon />,
      enabled: featureConfig.documents,
    },
  ];

  return (
    <>
      <Sidebar>
        <SidebarHeader>
          <SidebarSection>
            <div className="flex items-center gap-2 w-full py-3 pl-2">
              <NimaxLogo size='small' />
            </div>
          </SidebarSection>

          <SidebarSection>
            {navigationList.slice(0, 4).map(({ id, name, href, icon, enabled, children }) => (
              <Fragment key={name}>
                {enabled && (
                  <>
                    <NavLink to={href}>
                      <SidebarItem
                        className={enabled ? 'cursor-pointer' : '!cursor-not-allowed'}
                        disabled={!enabled}
                        // TODO: For now always open, Reactivate when we have more features
                        // onClick={() => togglePanelState(id)}
                      >
                        {icon}
                        <SidebarLabel>{name}</SidebarLabel>
                        
                        <Badge color={enabled ? 'green' : 'zinc'} className='capitalize'>
                          {enabled ? GLOBAL_NEW : GLOBAL_SOON }
                        </Badge>
                      </SidebarItem>
                    </NavLink>

                    {children && panelState[id] && (
                      <>
                        {children.map(({ name, href, icon }) => (
                          <NavLink to={href} key={`${name}-${href}`}>
                            <SidebarItem
                              current={location.pathname === href}
                            >
                              {icon}
                              <SidebarLabel>{name}</SidebarLabel>
                            </SidebarItem>
                          </NavLink>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Fragment>
              
            ))}
          </SidebarSection>
        </SidebarHeader>

        <SidebarBody>
          <SidebarSection>
            {navigationList.slice(4).map(({ name, href, icon, enabled }) => (
              <Fragment key={name}>
                {enabled && (
                  <NavLink to={href}>
                    <SidebarItem
                      current={location.pathname === href}
                      className={enabled ? 'cursor-pointer' : 'cursor-not-allowed'}
                      disabled={!enabled}
                    >
                      {icon}
                      <SidebarLabel>{name}</SidebarLabel>
                      <Badge color={enabled ? 'green' : 'zinc'} className='capitalize'>
                        {enabled ? 'new' : 'soon' }
                      </Badge>
                    </SidebarItem>
                  </NavLink>
                )}
              </Fragment>
            ))}
          </SidebarSection>

          <SidebarSpacer />

          <SidebarSection>
            <SidebarItem onClick={toggleDarkMode}>
              {isDarkMode ? <SunIcon /> : <MoonIcon />}
              <SidebarLabel>{SIDEBAR_THEME}</SidebarLabel>
            </SidebarItem>
          </SidebarSection>

          <Text className='px-3 pt-2'>
            {SIDEBAR_VERSION}

            <Strong className='pl-1'>{`${packageJson.version}`}</Strong>
          </Text>
        </SidebarBody>

        <SidebarFooter className="max-lg:hidden">
          <UserMenu type='sidebar' />
        </SidebarFooter>
      </Sidebar>
    </>
  );
};

export default SidebarMain;
