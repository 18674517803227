import { useSelector } from "react-redux";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const Loader = () => {
  const { isDarkMode } = useSelector((state) => state.ui);
  
  const sourcePath = isDarkMode
    ? "/lotties/loader-2-white.lottie"
    : "/lotties/loader-2-dark.lottie";

  return (
    <div className="flex justify-center items-center">
      <DotLottieReact
        src={sourcePath}
        loop
        autoplay
        speed={2.5}
        style={{ width: "300px", height: "300px" }} 
      />
    </div>
  );
};

export default Loader;
