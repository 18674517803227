// UI
import Card from 'components/common/Card';
import { Badge } from 'components/catalyst/badge';
import { Checkbox, CheckboxField } from 'components/catalyst/checkbox';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Label } from 'components/catalyst/fieldset';
import { Heading } from 'components/catalyst/heading';
import { Textarea } from 'components/catalyst/textarea';

// Utils
import { areasList } from 'components/workOrder/utils';
import wording from 'utils/wording';

const AreasOfConcernCard = ({
  workOrder,
  isEditing,
  editedData,
  handleChange,
  handleAreaOfConcernChange,
}) => {
  const {
    WO_DETAIL_AREAS_OF_CONCERN,
    WO_DETAIL_OTHER_CONCERNS,
    WO_DETAIL_NO_AREAS_SELECTED,
  } = wording;

  return (
    <Card>
      <Heading level={2} className='pb-2'>
        {WO_DETAIL_AREAS_OF_CONCERN}
      </Heading>

      <DescriptionList>
        <DescriptionTerm>
          {WO_DETAIL_AREAS_OF_CONCERN}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <div className="grid grid-cols-2 gap-2">
              {areasList.map(({ label, value }) => (
                <CheckboxField key={label}>
                  <Checkbox
                    name={value}
                    checked={editedData.areaOfConcern[label]}
                    onChange={(value) => handleAreaOfConcernChange(label, value)}
                  />
    
                  <Label className='capitalize'>{label}</Label>
                </CheckboxField>
              ))}
            </div>
          ) : (
            <div className="flex flex-wrap gap-2">
              {Object.entries(workOrder?.areaOfConcern || {}).map(([key, value]) => {
                if (key !== 'other' && value) {
                  return (
                    <Badge
                      key={key}
                      color='yellow'
                      className='capitalize'
                    >
                      {key}
                    </Badge>
                  );
                }
                return null;
              })}

              {Object.values(workOrder?.areaOfConcern || {}).every(v => !v || v === '') && (
                <>{WO_DETAIL_NO_AREAS_SELECTED}</>
              )}
            </div>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_OTHER_CONCERNS}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Textarea
              name="areaOfConcern.other"
              value={editedData.areaOfConcern?.other}
              onChange={handleChange}
              rows={4}
            />
          ) : (
            
            <>
              {workOrder?.areaOfConcern?.other === '' ? (
                <>
                  {WO_DETAIL_NO_AREAS_SELECTED}
                </>
              ) : (
                <>
                  {workOrder?.areaOfConcern?.other}
                </>
              )}
            </>
          )}
        </DescriptionDetails>
      </DescriptionList>
  </Card>
  );
};

export default AreasOfConcernCard;
