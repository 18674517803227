// Module dependencies
import useLogin from "hooks/useLogin";
import { Link } from "react-router-dom";

// UI
import { Fieldset, FieldGroup, Field, Label } from "components/catalyst/fieldset";
import { Input, InputGroup } from "components/catalyst/input";
import { LockClosedIcon } from '@heroicons/react/24/outline';
import LoadingButton from "components/common/LoadingButton";

import { Text } from "components/catalyst/text";
// Utils
import wording from "utils/wording";

const LoginForm = () => {
  const {
    formData,
    handleInputChange,
    handleSubmit,
    isLoading,
    isError,
    message,
  } = useLogin();

  const {
    LOGIN_EMAIL_LABEL,
    LOGIN_PASSWORD_LABEL,
    LOGIN_BUTTON,
    SIGNING_IN_BUTTON,
    LOGIN_REGISTER_ACCOUNT,
    LOGIN_FORGOT_PASSWORD,
  } = wording;

  return (
    <div className="bg-white dark:bg-zinc-900 py-8 px-4 shadow-lg border border-gray-200 dark:border-gray-800 sm:rounded-lg sm:px-10">
      <form className="space-y-6" onSubmit={handleSubmit}>
        {isError && (
          <div className="bg-red-50 text-red-700 p-4 rounded-md border border-red-200">
            <p>{message}</p>
          </div>
        )}

        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>{LOGIN_EMAIL_LABEL}</Label>

              <Input
                id="email"
                name="email"
                type="email"
                required
                value={formData.email}
                onChange={handleInputChange}
              />
            </Field>

            <Field>
              <Label>{LOGIN_PASSWORD_LABEL}</Label>

              <InputGroup>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={formData.password}
                  onChange={handleInputChange}
                />

                <LockClosedIcon />
              </InputGroup>
            </Field>

            <LoadingButton
              disabled={isLoading}
              type='submit'
              isLoading={isLoading}
              label={LOGIN_BUTTON}
              loadingLabel={SIGNING_IN_BUTTON}
              className='w-full'
            />
          </FieldGroup>
        </Fieldset>
      </form>

      <div className="mt-6">
        <div className="flex items-center justify-between">
          <Text className='cursor-not-allowed'>
            {LOGIN_REGISTER_ACCOUNT}
          </Text>

          <Link to='/forgot-password'>
            <Text className='cursor-pointer hover:text-zinc-900 dark:hover:text-zinc-100'>
              {LOGIN_FORGOT_PASSWORD}
            </Text>
          </Link>        
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
