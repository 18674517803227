// UI
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Heading } from 'components/catalyst/heading';
import { Input } from 'components/catalyst/input';
// import { Select } from 'components/catalyst/select';
import { Textarea } from 'components/catalyst/textarea';
import Card from 'components/common/Card';

// Utils
import { formatCurrency } from 'utils/commons';
import wording from 'utils/wording';

const WorkDetailsCard = ({
  workOrder,
  isEditing,
  editedData,
  handleChange,
}) => {
  const {
    WO_DETAIL_WORK_DETAILS,
    WO_DETAIL_WORK_PERFORMED,
    WO_DETAIL_AMOUNT_DUE,
    // WO_DETAIL_TECHNICIAN,
    // GLOBAL_UNASSIGNED,
  } = wording;
  
  return (
    <Card>
      <Heading level={2} className='pb-2'>
        {WO_DETAIL_WORK_DETAILS}
      </Heading>

      <DescriptionList>
        <DescriptionTerm>
          {WO_DETAIL_AMOUNT_DUE}
        </DescriptionTerm>
        
        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="number"
              name="amountDue"
              value={editedData.amountDue}
              onChange={handleChange}
            />
          ) : (
            <>
              {formatCurrency(workOrder?.amountDue)}
            </>
          )}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_WORK_PERFORMED}
        </DescriptionTerm>
        
        <DescriptionDetails>
          {isEditing ? (
            <Textarea 
              name="workPerformed"
              value={editedData.workPerformed}
              onChange={handleChange}
              rows={4}
            />
          ) : (
            <>
              {workOrder?.workPerformed}
            </>
          )}
        </DescriptionDetails>
      </DescriptionList>
    </Card>
  );
};

export default WorkDetailsCard;
