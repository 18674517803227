// UI
import { Input, InputGroup } from 'components/catalyst/input';
import { Button } from 'components/catalyst/button';
import { Label } from 'components/catalyst/fieldset';
import { Switch, SwitchField } from 'components/catalyst/switch';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

// Utils
import wording from 'utils/wording';

const WorkOrdersViewSearch = ({ 
  searchTerm, 
  handleSearchTermChange, 
  handleFilter,
  handleCleanFilterText,
  includeCompletedWO,
  handleToggleIncludeCompletedWO,
}) => {
  const {
    WORK_ORDERS_SEARCH_PLACEHOLDER,
    WORK_ORDERS_INCLUDE_COMPLETED,
  } = wording;

  return (
    <div className="flex flex-col sm:flex-row gap-6">
      <div className="flex-1 relative">
        <div className='flex gap-2'>
          <div className='flex-1'>
            <InputGroup>
              <MagnifyingGlassIcon />

              <Input
                type="text"
                id="filter-text-box"
                placeholder={WORK_ORDERS_SEARCH_PLACEHOLDER}
                value={searchTerm}
                onChange={(e) => {
                  handleSearchTermChange(e.target.value);
                  handleFilter();
                }}
              />
            </InputGroup>
          </div>

          {searchTerm && (
            <Button plain className="flex-shrink-0 px-4" onClick={handleCleanFilterText}>
              <XMarkIcon />
            </Button>
          )}
        </div>
      </div>

      <SwitchField>
        <Label className='cursor-pointer'>
          {WORK_ORDERS_INCLUDE_COMPLETED}
        </Label>

        <Switch
          name="includeCompletedWO"
          checked={includeCompletedWO}
          onChange={handleToggleIncludeCompletedWO}
          className='cursor-pointer'
          color='blue'
        />
      </SwitchField>
    </div>
  );
};

export default WorkOrdersViewSearch;
