// Module dependencies
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getWorkOrders, updateWorkOrder } from 'features/workOrders/workOrderSlice';
import { getCompanyUsers } from 'features/users/userSlice';
import { useBasicModal } from './useBasicModal';
import useTableFeatures from './useTableFeatures';

const useWorkOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    workOrders,
    isLoading
  } = useSelector((state) => state.workOrders);

  // Table filter
  const {
    tableRef,
    searchTerm,
    handleSearchTermChange,
    handleCleanFilterText,
    handleFilter,
    onDragStarted,
    onDragStopped,
    onGridReady,
    onPaginationChanged,
  } = useTableFeatures('work_orders');

  // Locations Map Modal
  const {
    showModal: showLocationsMap,
    handleOpenModal: handleOpenLocationsMap,
    handleCloseModal: handleCloseLocationsMap,
  } = useBasicModal();

  // Global Schedule Modal
  const {
    showModal: showGlobalSchedule,
    handleOpenModal: handleOpenGlobalSchedule,
    handleCloseModal: handleCloseGlobalSchedule,
  } = useBasicModal();

  useEffect(() => {
    dispatch(getWorkOrders());
    dispatch(getCompanyUsers());
  }, [dispatch]);

  useEffect(() => {
    const activeWOs = workOrders.filter(
      workOrder => workOrder.status !== 'completed'
    );
  
    setActiveWorkOrders(activeWOs);
  }, [workOrders])

  const [includeCompletedWO, setIncludeCompletedWO] = useState(false);
  const [activeWorkOrders, setActiveWorkOrders] = useState([]);

  const [editingWOStatusId, setEditingWOStatusId] = useState(0);

  const handleCreateWorkOrder = () => {
    navigate('/work-orders/new');
  };

  const handleViewWorkOrder = (rowData) => {
    const id = rowData.data._id;

    navigate(`/work-orders/${id}`);
  };

  const handleViewGlobalSchedule = (rowData) => {
    navigate('/work-orders/global-schedule');
  };

  const handleViewPendingLocations = (rowData) => {
    navigate('/work-orders/pending-locations');
  };

  const handleToggleIncludeCompletedWO = () => {
    setIncludeCompletedWO(!includeCompletedWO);
  };

  const handleUpdateStatus = async (
    newStatus,
    workOrderData,
    callback,
  ) => {
    try {
      setEditingWOStatusId(workOrderData._id)
      callback();

      await dispatch(updateWorkOrder({ 
        workOrderId: workOrderData._id, 
        workOrderData: {
          ...workOrderData,
          status: newStatus,
        } 
      }));

      await dispatch(getWorkOrders());

      setEditingWOStatusId(0);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    workOrders,
    activeWorkOrders,
    isLoading,
    handleCreateWorkOrder,
    handleViewWorkOrder,
    includeCompletedWO,
    handleToggleIncludeCompletedWO,

    // Table
    tableRef,
    searchTerm,
    handleFilter,
    handleCleanFilterText,
    handleSearchTermChange,
    onDragStarted,
    onDragStopped,
    onGridReady,
    onPaginationChanged,
    editingWOStatusId,
    handleUpdateStatus,

    handleViewGlobalSchedule,
    handleViewPendingLocations,

    // Map
    showLocationsMap,
    handleOpenLocationsMap,
    handleCloseLocationsMap,

    // Schedule
    showGlobalSchedule,
    handleOpenGlobalSchedule,
    handleCloseGlobalSchedule,
  };
};

export default useWorkOrders;
