// Module depencencies
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from 'features/auth/authSlice';

// UI
import { Heading } from 'components/catalyst/heading';
import { Box } from 'components/common/StyledBoxs';
import { Field, FieldGroup, Fieldset, Label } from 'components/catalyst/fieldset';
import { Input } from 'components/catalyst/input';
import LoadingButton from 'components/common/LoadingButton';

// Utils
import wording from 'utils/wording';

const {
  PASSWORD_NEW_TITLE,
  PASSWORD_NEW_LABEL_PASSWORD,
  PASSWORD_NEW_LABEL_CONFIRM_PASSWORD,
  PASSWORD_NEW_PLACEHOLDER_PASSWORD,
  PASSWORD_NEW_PLACEHOLDER_CONFIRM_PASSWORD,
  PASSWORD_NEW_ERROR_REQUIRED,
  PASSWORD_NEW_ERROR_MIN_LENGTH,
  PASSWORD_NEW_ERROR_CONFIRM_REQUIRED,
  PASSWORD_NEW_ERROR_MISMATCH,
  PASSWORD_NEW_SUBMITTING,
  PASSWORD_NEW_BUTTON,
  PASSWORD_NEW_SUCCESS,
  ERROR_MESSAGE,
} = wording;

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const { isLoading } = useSelector((state) => state.auth);
  
  const dispatch = useDispatch();

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    try {
      await dispatch(
        resetPassword({
          token,
          password: data.password
        })
      ).unwrap();

      toast.success(PASSWORD_NEW_SUCCESS);

      navigate('/login');
    } catch (error) {
      toast.error(error.response?.data?.message || ERROR_MESSAGE);

      console.log(error);
    }
  };

  return (
    <div className="min-h-screen bg-white dark:bg-zinc-950 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Heading level={2} className='text-center !text-2xl !font-extrabold'>
        {PASSWORD_NEW_TITLE}
      </Heading>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
        <Box className="py-8 px-4 sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <Fieldset>
              <FieldGroup>
                <Field>
                  <Label>{PASSWORD_NEW_LABEL_PASSWORD}</Label>

                  <Input
                    id="password"
                    type="password"
                    placeholder={PASSWORD_NEW_PLACEHOLDER_PASSWORD}
                    required
                    {...register('password', {
                      required: PASSWORD_NEW_ERROR_REQUIRED,
                      minLength: {
                        value: 8,
                        message: PASSWORD_NEW_ERROR_MIN_LENGTH,
                      }
                    })}
                  />
                  {errors.password && (
                    <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>
                  )}
                </Field>

                <Field>
                  <Label>{PASSWORD_NEW_LABEL_CONFIRM_PASSWORD}</Label>

                  <Input
                    id="confirmPassword"
                    type="password"
                    placeholder={PASSWORD_NEW_PLACEHOLDER_CONFIRM_PASSWORD}
                    required
                    {...register('confirmPassword', {
                      required: PASSWORD_NEW_ERROR_CONFIRM_REQUIRED,
                      validate: (val) => {
                        if (watch('password') !== val) {
                          return PASSWORD_NEW_ERROR_MISMATCH;
                        }
                      }
                    })}
                  />
                  {errors.confirmPassword && (
                    <p className="mt-2 text-sm text-red-600">{errors.confirmPassword.message}</p>
                  )}
                </Field>

                <LoadingButton
                  disabled={isLoading}
                  type='submit'
                  isLoading={isLoading}
                  label={PASSWORD_NEW_BUTTON}
                  loadingLabel={PASSWORD_NEW_SUBMITTING}
                  className='w-full'
                />
              </FieldGroup>
            </Fieldset>
          </form>
        </Box>
      </div>
    </div>
  );
};

export default ResetPassword;
