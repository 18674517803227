import React from 'react'
import ClientInformationCard from './ClientInformationCard';
import ClientLocationCard from './ClientLocationCard';
import Loader from 'components/Loader';

const WODetailsClietTab = ({
  workOrderDetailHandlers,
}) => {
  return (
    <>
      {workOrderDetailHandlers.isLoading ? (
        <div className='flex items-center justify-center w-full h-96'>
          <Loader />
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <ClientInformationCard {...workOrderDetailHandlers} />
          
          <ClientLocationCard {...workOrderDetailHandlers} />
        </div>
      )}
    </>
  );
};

export default WODetailsClietTab;
