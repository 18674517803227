import Breadcrumb from 'components/Breadcrumb';
import Loader from 'components/Loader';
import GlobalSchedule from 'components/workOrdersPage/GlobalSchedule';
import { getWorkOrders } from 'features/workOrders/workOrderSlice';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import wording from 'utils/wording';

const GlobalScheduleView = () => {
  const {
    workOrders,
    isLoading,
  } = useSelector((state) => state.workOrders);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getWorkOrders());
  }, [dispatch]);
    
  const { SIDEBAR_WORK_ORDERS, WORK_ORDERS_GLOBAL_SCHEDULE } = wording;

  const pages = [
    { name: SIDEBAR_WORK_ORDERS, href: '/work-orders', current: false },
    { name: WORK_ORDERS_GLOBAL_SCHEDULE, href: '/work-orders/global-schedule', current: false },
  ];

  return (
    <div className='flex flex-col gap-6'>
      <Breadcrumb pages={pages} />

      <>
        {workOrders.length === 0 && isLoading ? (
          <div className='flex items-center justify-center w-full h-96'>
            <Loader />
          </div>
        ) : (
          <GlobalSchedule workOrders={workOrders} />
        )}
      </>
  </div>
  )
}

export default GlobalScheduleView;
