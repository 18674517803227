// Module dependencies
import {  forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AgGridReact } from "ag-grid-react";
import { themeAlpine } from 'ag-grid-community';
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";

ModuleRegistry.registerModules([AllCommunityModule]);

const BaseTable = forwardRef(
  ({ rowData, columnDefs, ...props }, ref) => {
    const { isDarkMode } = useSelector((state) => state.ui);
  
    const myTheme = themeAlpine.withParams({
      /* Spacing and row height */
      spacing: 2,
      rowHeight: 70,
    
      /* Colors */
      foregroundColor: isDarkMode ? 'rgb(161, 161, 170)' : 'rgb(113, 113, 122)', // Text color
      backgroundColor: isDarkMode ? 'rgb(24, 24, 27)' : 'rgb(255, 255, 255)', // Table background
      headerBackgroundColor: isDarkMode ? 'rgb(24, 24, 27)' : 'rgb(255, 255, 255)', // Header row
      rowHoverColor: isDarkMode ? 'rgb(39, 39, 42)' : 'rgb(249, 250, 251)', // Row hover
    
      /* Font styles */
      fontFamily: 'Inter, sans-serif',
      fontSize: 14,
  
      headerFontWeight: 400,
      headerVerticalPaddingScale: 4,
      headerTextColor: isDarkMode ? 'rgb(161, 161, 170)' : 'rgb(113, 113, 122)',
    });

    const defaultColDef = useMemo(() => {
      return {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        headerClass: 'pr-4',
        cellClass: 'flex items-center h-full !pr-4',
      };
    }, []);
  
    const autoSizeStrategy = useMemo(() => {
      return {
        type: "fitGridWidth",
        defaultMinWidth: 120,
      };
    }, []);
    
    return (
      <div>
        <AgGridReact
          {...props}
          ref={ref}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowHeight={70}
          autoSizeStrategy={autoSizeStrategy}
          pagination={true}
          paginationPageSize={5}
          paginationPageSizeSelector={[5, 10, 50]}
          rowClass='flex items-center justify-center cursor-pointer'
          theme={myTheme}
          domLayout={"autoHeight"}
          suppressCellFocus={true}
        />
      </div>
    );
  }
);

export default BaseTable;
