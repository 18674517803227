import { endpoints } from '../../config/api';
import { axiosInstance } from 'config/axios';

// Create new work order
const createWorkOrder = async (workOrderData) => {
  const { data } = await axiosInstance.post(
    endpoints.workOrders.base, workOrderData
  );

  return data;
};

// Get all work orders
const getWorkOrders = async () => {
  const { data } = await axiosInstance.get(
    endpoints.workOrders.base
  );

  return data;
};

// Get single work order
const getWorkOrder = async (workOrderId) => {
  const { data } = await axiosInstance.get(
    endpoints.workOrders.workOrder(workOrderId)
  );

  return data;
};

// Update work order
const updateWorkOrder = async (workOrderId, workOrderData) => {
  const { data } = await axiosInstance.put(
    endpoints.workOrders.workOrder(workOrderId),
    workOrderData
  );

  return data;
};

// Delete work order
const deleteWorkOrder = async (workOrderId) => {
  const { data } = await axiosInstance.delete(
    endpoints.workOrders.workOrder(workOrderId)
  );

  return data;
};

// Assign work order to project
const assignToProject = async (workOrderId, projectId) => {
  const { data } = await axiosInstance.put(
    endpoints.workOrders.assignProject(workOrderId),
    { projectId },
  );

  return data;
};

// Assign work order to user
const assignToUser = async (workOrderId, userId) => {
  const { data } = await axiosInstance.put(
    endpoints.workOrders.assignUser(workOrderId),
    { userId },
  );

  return data;
};

// Upload document
const uploadDocument = async (workOrderId, formData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const { data } = await axiosInstance.post(
    endpoints.workOrders.documents(workOrderId),
    formData,
    config
  );

  return data;
};

// Delete document
const deleteDocument = async (workOrderId, documentId) => {
  const { data } = await axiosInstance.delete(
    `${endpoints.workOrders.documents(workOrderId)}/${documentId}`,
  );

  return data;
};

// Get document URL
const getDocumentUrl = async (workOrderId, documentId) => {
  const { data } = await axiosInstance.get(
    `${endpoints.workOrders.documents(workOrderId)}/${documentId}/url`,
  );
  return data;
};

// Update work order status
const updateWorkOrderStatus = async (workOrderId, status) => {
  const { data } = await axiosInstance.put(
    endpoints.workOrders.status(workOrderId),
    { status }
  );

  return data;
};

const getWorkOrderMessages = async (workOrderId) => {
  const { data } = await axiosInstance.get(
    endpoints.workOrders.messages(workOrderId)
  );

  return data;
};

const sendWorkOrderMessage = async (workOrderId, message) => {
  const { data } = await axiosInstance.post(
    endpoints.workOrders.messages(workOrderId),
    { message },
  );

  return data;
};

const getWorkOrderActivity = async (workOrderId) => {
  const { data } = await axiosInstance.get(
    endpoints.workOrders.logs(workOrderId)
  );

  return data.data;
};

const workOrderService = {
  createWorkOrder,
  getWorkOrders,
  getWorkOrder,
  updateWorkOrder,
  deleteWorkOrder,
  assignToProject,
  assignToUser,
  uploadDocument,
  deleteDocument,
  getDocumentUrl,
  updateWorkOrderStatus,
  getWorkOrderMessages,
  sendWorkOrderMessage,
  getWorkOrderActivity,
};

export default workOrderService;
