// Module dependencies
import { useNavigate } from 'react-router-dom';
import { useMaps } from '../../contexts/MapsContext';

// UI
import Map from '../common/Map';
import { Dialog, DialogBody, DialogTitle } from 'components/catalyst/dialog'
import { Button } from 'components/catalyst/button';
import { BoxCentered } from 'components/common/StyledBoxs';
import Loader from 'components/Loader';
import { XMarkIcon } from '@heroicons/react/24/outline';

// utils
import wording from 'utils/wording';

const WorkOrderLocationsMap = ({
  workOrders,
  showLocationsMap,
  handleCloseLocationsMap,
}) => {
  const navigate = useNavigate();

  const { isLoaded } = useMaps();

  const { WORK_ORDERS_PENDING_LOCATIONS } = wording;

  // Filter only pending work orders with valid coordinates
  const pendingWorkOrders = workOrders.filter(wo => 
    wo.status === 'pending' && 
    wo.address?.lat && 
    wo.address?.lng
  );

  const markers = pendingWorkOrders.map(wo => ({
    position: {
      lat: parseFloat(wo.address.lat),
      lng: parseFloat(wo.address.lng)
    },
    title: `${wo.client?.name} - ${wo.address?.formattedAddress}`,
    projectId: wo._id
  }));

  return (
    <Dialog
      open={showLocationsMap}
      onClose={handleCloseLocationsMap}
      size='5xl'
    >
      <DialogTitle>
        <div className='flex items-center justify-between'>
          {WORK_ORDERS_PENDING_LOCATIONS(pendingWorkOrders.length)}

          <Button
            plain
            onClick={handleCloseLocationsMap}
          >
            <XMarkIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogBody>
        <div className="h-[70vh] rounded-lg overflow-hidden">
          {!isLoaded ? (
            <BoxCentered className='h-full'>
              <Loader />
            </BoxCentered>
          ) : (
            <Map
              markers={markers}
              onMarkerClick={(workOrderId) => {
                navigate(`/work-orders/${workOrderId}`);

                handleCloseLocationsMap();
              }}
              fitMarkers={true}
            />
          )}
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default WorkOrderLocationsMap; 