import clsx from 'clsx';

const Box = ({ className, children, ...props }) => {
  return (
    <div
      className={clsx(className, 'bg-white dark:bg-zinc-900 shadow-xs border border-gray-200 dark:border-gray-800 rounded-lg')}
      {...props}
    >
      {children}
    </div>
  );
};

const BoxCentered = ({ className, children, ...props }) => {
  return (
    <Box
      className={clsx(className, 'flex justify-center items-center')}
      {...props}
    >
      {children}
    </Box>
  );
};

export {
  Box,
  BoxCentered,
};
