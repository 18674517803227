import wording from 'utils/wording';
import './nimaxlogo.css';

const NimaxLogo = ({ size }) => {
  const { COMPANY_NAME } = wording;
  
  const getTitleSizeStyles = (rotate) => {
    const baseStyles = 'font-medium text-zinc-950 dark:text-white font-logo';

    const sizeStyle = size === 'small'
      ? `${rotate ? 'text-[28px] leading-6 mt-[-1px]' : 'text-[24px] leading-6'}`
      : `${rotate ? 'text-[38px] mt-[-4px]' : 'text-[34px]'} leading-12`

    return `${baseStyles} ${sizeStyle}`;
  }

  const getSubTitleSizeStyles = () => {
    const baseStyles = 'text-zinc-500 dark:text-zinc-400 font-logo2 lowercase';

    return `${baseStyles} ${size === 'small' ? 'text-2xl pl-1' : 'text-3xl mt-[-18px]'}`;
  }

  return (
    <div
      className={`logo-rectangle-container flex items-center justify-center flex-wrap ${size === 'small' ? 'flex-row' : 'flex-col'}`}
    >
      <div className='flex uppercase font-logo'>
        <span className={getTitleSizeStyles()}>{COMPANY_NAME.slice(0,2)}</span>
        <span className={`${getTitleSizeStyles(true)} rotateY`}>{COMPANY_NAME.slice(2,3)}</span>
        <span className={getTitleSizeStyles()}>{COMPANY_NAME.slice(3,5)}</span>
      </div>

      <span className={getSubTitleSizeStyles()}>{COMPANY_NAME.slice(5)}</span>
    </div>
  );
};

export default NimaxLogo;
