// UI
import { Input, InputGroup } from 'components/catalyst/input';
import { Select } from 'components/catalyst/select';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';

// Utils
import wording from 'utils/wording';

const ProjectsViewSearch = ({
  searchTerm,
  setSearchTerm,
  selectedStatus,
  setSelectedStatus,
}) => {
  const {
    PROJECTS_STATUS_ALL,
    PROJECTS_STATUS_PENDING,
    PROJECTS_STATUS_SOLD,
    PROJECTS_STATUS_PRE_CONSTRUCTION,
    PROJECTS_STATUS_PERMITTING,
    PROJECTS_STATUS_CONSTRUCTION,
    PROJECTS_STATUS_DONE,
    PROJECTS_SEARCH_PLACEHOLDER,
  } = wording;

  const statusOptions = [
    { value: 'all', label: PROJECTS_STATUS_ALL },
    { value: 'pending', label: PROJECTS_STATUS_PENDING },
    { value: 'sold', label: PROJECTS_STATUS_SOLD },
    { value: 'pre-construction', label: PROJECTS_STATUS_PRE_CONSTRUCTION },
    { value: 'permitting', label: PROJECTS_STATUS_PERMITTING },
    { value: 'construction', label: PROJECTS_STATUS_CONSTRUCTION },
    { value: 'done', label: PROJECTS_STATUS_DONE }
  ];

  return (
    <div className="flex flex-col sm:flex-row gap-4">
      <div className="flex-1">
        <InputGroup>
          <MagnifyingGlassIcon />
          <Input
            name="search"
            type="text"
            placeholder={PROJECTS_SEARCH_PLACEHOLDER}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-label="Search"
          />
        </InputGroup>
      </div>

      <Select
        name="status"
        className="max-w-48"
        value={selectedStatus}
        onChange={(e) => setSelectedStatus(e.target.value)}
      >
        {statusOptions.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default ProjectsViewSearch;
