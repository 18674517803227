// Moduel dependencies
import { useSelector } from 'react-redux';

// UI
import { Toaster } from 'react-hot-toast';

const Notification = () => {
  const { isDarkMode } = useSelector(state => state.ui);
  
  return (
    <Toaster
      position="top-right"
      toastOptions={{
        duration: 3000,
        style: {
          background: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : '#fff',
          color: isDarkMode ? '#fff' : '#363636',
          borderRadius: '0.375rem',
          padding: '0.75rem 1rem',
          fontSize: '14px',
          border: isDarkMode ? '1px solid' : '1px solid',
          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(9, 9, 11, 0.1)',
        },
      }}
    />
  );
};

export default Notification;
