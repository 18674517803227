// Module dependencies
import { useNavigate } from 'react-router-dom';
import { useMaps } from '../../contexts/MapsContext';

// UI
import Map from '../common/Map';
import { Box, BoxCentered } from 'components/common/StyledBoxs';
import Loader from 'components/Loader';

const WorkOrderLocations = ({ workOrders }) => {
  const navigate = useNavigate();

  const { isLoaded } = useMaps();

  const markers = workOrders.map(wo => ({
    position: {
      lat: parseFloat(wo.address.lat),
      lng: parseFloat(wo.address.lng)
    },
    title: `${wo.client?.name} - ${wo.address?.formattedAddress}`,
    projectId: wo._id
  }));

  return (
    <Box className="h-[70vh] overflow-hidden">
      {!isLoaded ? (
        <BoxCentered className='h-full'>
          <Loader />
        </BoxCentered>
      ) : (
        <Map
          markers={markers}
          onMarkerClick={(workOrderId) => {
            navigate(`/work-orders/${workOrderId}`);
          }}
          fitMarkers={true}
        />
      )}
    </Box>
  );
};

export default WorkOrderLocations;
