import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import workOrderService from './workOrderService';
import { getErrorMessage } from 'utils/commons';

const initialState = {
  workOrders: [],
  workOrder: null,
  workOrderMessages: [],
  workOrderActivity: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  isFetchingWorkorder: false,
  isFetchingAllWorkorders: false,
  isDeletingWorkOrder: false,
  isFetchingMessages: false,
  isFetchingActivity: false,
  isSendingMessage: false,
  message: '',
};

// Get all work orders
export const getWorkOrders = createAsyncThunk(
  'workOrders/getAll',
  async (_, thunkAPI) => {
    try {
      return await workOrderService.getWorkOrders();
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get single work order
export const getWorkOrder = createAsyncThunk(
  'workOrders/get',
  async (workOrderId, thunkAPI) => {
    try {
      return await workOrderService.getWorkOrder(workOrderId);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new work order
export const createWorkOrder = createAsyncThunk(
  'workOrders/create',
  async (workOrderData, thunkAPI) => {
    try {
      return await workOrderService.createWorkOrder(workOrderData);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update work order
export const updateWorkOrder = createAsyncThunk(
  'workOrders/update',
  async ({ workOrderId, workOrderData }, thunkAPI) => {
    try {
      const response = await workOrderService.updateWorkOrder(workOrderId, workOrderData);
      return response;
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete work order
export const deleteWorkOrder = createAsyncThunk(
  'workOrders/delete',
  async (workOrderId, thunkAPI) => {
    try {
      return await workOrderService.deleteWorkOrder(workOrderId);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Assign to project
export const assignToProject = createAsyncThunk(
  'workOrders/assignToProject',
  async ({ workOrderId, projectId }, thunkAPI) => {
    try {
      return await workOrderService.assignToProject(workOrderId, projectId);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Assign to user
export const assignToUser = createAsyncThunk(
  'workOrders/assignToUser',
  async ({ workOrderId, userId }, thunkAPI) => {
    try {
      return await workOrderService.assignToUser(workOrderId, userId);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMessages = createAsyncThunk(
  'workOrder/getMessages',
  async (workOrderId, thunkAPI) => {
    try {
      return await workOrderService.getWorkOrderMessages(workOrderId);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendMessage = createAsyncThunk(
  'workOrder/sendMessage',
  async ({ workOrderId, message }, thunkAPI) => {
    try {
      return await workOrderService.sendWorkOrderMessage(workOrderId, message);
    } catch (error) {
      const message = getErrorMessage(error);

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getWorkOrderActivity = createAsyncThunk(
  'workOrders/getActivity',
  async (workOrderId, thunkAPI) => {
    try {
      return await workOrderService.getWorkOrderActivity(workOrderId);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWorkOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrders = action.payload;
      })
      .addCase(getWorkOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getWorkOrder.pending, (state) => {
        state.isFetchingWorkorder = true;
      })
      .addCase(getWorkOrder.fulfilled, (state, action) => {
        state.isFetchingWorkorder = false;
        state.isSuccess = true;
        state.workOrder = action.payload;
      })
      .addCase(getWorkOrder.rejected, (state, action) => {
        state.isFetchingWorkorder = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createWorkOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWorkOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrders.push(action.payload);
      })
      .addCase(createWorkOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateWorkOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWorkOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        if (state.workOrders) {
          state.workOrders = state.workOrders.map((workOrder) =>
            workOrder._id === action.payload._id ? action.payload : workOrder
          );
        }
        if (state.workOrder && state.workOrder._id === action.payload._id) {
          state.workOrder = action.payload;
        }
      })
      .addCase(updateWorkOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteWorkOrder.pending, (state) => {
        state.isDeletingWorkOrder = true;
      })
      .addCase(deleteWorkOrder.fulfilled, (state, action) => {
        state.isDeletingWorkOrder = false;
        state.isSuccess = true;
      })
      .addCase(deleteWorkOrder.rejected, (state, action) => {
        state.isDeletingWorkOrder = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(assignToProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignToProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrders = state.workOrders.map((workOrder) =>
          workOrder._id === action.payload._id ? action.payload : workOrder
        );
      })
      .addCase(assignToProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(assignToUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignToUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.workOrders = state.workOrders.map((workOrder) =>
          workOrder._id === action.payload._id ? action.payload : workOrder
        );
      })
      .addCase(assignToUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getMessages.pending, (state) => {
        state.isFetchingMessages = true;
      })
      .addCase(getMessages.fulfilled, (state, action) => {
        state.isFetchingMessages = false;
        state.isSuccess = true;
        state.workOrderMessages = action.payload;
      })
      .addCase(getMessages.rejected, (state, action) => {
        state.isFetchingMessages = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(sendMessage.pending, (state) => {
        state.isSendingMessage = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isSendingMessage = false;
        state.isSuccess = true;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isSendingMessage = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getWorkOrderActivity.pending, (state) => {
        state.isFetchingActivity = true;
      })
      .addCase(getWorkOrderActivity.fulfilled, (state, action) => {
        state.isFetchingActivity = false;
        state.isSuccess = true;
        state.workOrderActivity = action.payload;
      })
      .addCase(getWorkOrderActivity.rejected, (state, action) => {
        state.isFetchingActivity = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = workOrderSlice.actions;
export default workOrderSlice.reducer;
