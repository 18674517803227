// UI
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Heading } from 'components/catalyst/heading';
import Card from 'components/common/Card';
import StatusBadge from 'components/StatusBadge';
import StatusSelect from 'components/StatusSelect';

// Utils
import wording from 'utils/wording';

const StatusCard = ({
  workOrder,
  isEditing,
  editedData,
  handleChange,
}) => {
  const {
    WO_DETAIL_STATUS,
    WO_DETAIL_REQUESTED_ON,
  } = wording;

  return (
    <Card>
      <Heading level={2} className='pb-2'>
        {WO_DETAIL_STATUS}
      </Heading>

      <DescriptionList>
        <DescriptionTerm>
          {WO_DETAIL_REQUESTED_ON}
        </DescriptionTerm>
        <DescriptionDetails>
          {new Date(workOrder?.dateRequested).toLocaleDateString()}
        </DescriptionDetails>

        <DescriptionTerm>
          {WO_DETAIL_STATUS}
        </DescriptionTerm>
        <DescriptionDetails>
          {isEditing ? (
            <StatusSelect
              name="status"
              value={editedData.status}
              handleChange={handleChange}
            />
          ) : (
            <StatusBadge status={workOrder?.status || 'pending'} />
          )}
        </DescriptionDetails>
      </DescriptionList>
    </Card>
  );
};

export default StatusCard;
