// Module dependencies
import { useMaps } from "contexts/MapsContext";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from "react-redux";
import { createWorkOrder } from "features/workOrders/workOrderSlice";
import { getCompanyUsers } from "features/users/userSlice";

// Utils
// import wording from "utils/wording";

// const { ERROR_REQUIRED_AREAS } = wording;

const useCreateWorkOrderForm = () => {
  const { technicians, users } = useSelector(state => state.users);
  const { isLoading } = useSelector(state => state.workOrders);

  const { register, handleSubmit, formState: { errors, isValid, isDirty }, getValues, setValue, watch, control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      client: {
        name: '',
        email: '',
        phone: '',
      },
      address: {
        formattedAddress: '',
        lat: null,
        lng: null,
        placeId: '',
      },
      dateRequested: new Date().toISOString().slice(0, 16),
      assignedTo: '',
      color: '',
      screenType: '',
      screwType: '',
      inspectionPriority: '',
      underWarranty: false,
      materialsList: '',
      tags: [],
      areaOfConcern: {
        gutter: false,
        screen: false,
        aluminum: false,
        panels: false,
        concrete: false,
        door: false,
        screws: false,
        other: '',
      },
      workPerformed: '',
      amountDue: 0,
      cashOnDelivery: false,
      picturesProvided: false,
    },
  });
  
  const { isLoaded } = useMaps();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Map States
  const [autocomplete, setAutocomplete] = useState(null);
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 25.7617, lng: -80.1918 });

  useEffect(() => {
    dispatch(getCompanyUsers());
  }, [dispatch]);

  const onSubmit = async (data) => {
    const cleanedData = removeEmptyFields(data);

    try {
      await dispatch(createWorkOrder({
        ...cleanedData,
        status: 'pending',
      })).unwrap();

      navigate('/work-orders');
    } catch (error) {
      console.error('Failed to create work order:', error);
    }
  }

  const removeEmptyFields = (obj) => {
    const result = {};
  
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
  
      if (value && typeof value === "object" && !Array.isArray(value)) {
        const cleanedObj = removeEmptyFields(value);
        if (Object.keys(cleanedObj).length > 0) {
          result[key] = cleanedObj;
        }
      } else if (Array.isArray(value)) {
        const cleanedArray = value.filter((item) => item != null && item !== '');
        if (cleanedArray.length > 0) {
          result[key] = cleanedArray;
        }
      } else if (value != null && value !== '') {
        result[key] = value;
      }
    });
  
    return result;
  };

  // const validateAreas = () => {
  //   const areaOfConcern = getValues('areaOfConcern');
    
  //   const {
  //     gutter,
  //     screen,
  //     aluminum,
  //     panels,
  //     concrete,
  //     door,
  //     screws,
  //     other,
  //   } = areaOfConcern;
  
  //   const hasSelectedArea =
  //     [gutter, screen, aluminum, panels, concrete, door, screws].some(Boolean) ||
  //     (other || '').trim() !== '';
  
  //   return hasSelectedArea || ERROR_REQUIRED_AREAS;
  // };

  // Map handlers
  const onLoad = useCallback((autocomplete) => {
    setAutocomplete(autocomplete);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = {
          formattedAddress: place.formatted_address,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          placeId: place.place_id,
        };
        
        setMapCenter({
          lat: location.lat,
          lng: location.lng
        });
        
        setValue('address.formattedAddress', location.formattedAddress);
        setValue('address.lat', location.lat);
        setValue('address.lng', location.lng);
        setValue('address.placeId', location.placeId);
        
        if (map) {
          map.panTo({ lat: location.lat, lng: location.lng });
          map.setZoom(15);
        }
      }
    }
  };

  const handleMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const handleCancelCreate = () => {
    navigate('/work-orders');
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    isValid,
    isDirty,
    getValues,
    watch,
    control,
    isLoading,

    // Map
    isLoaded,
    mapCenter,
    onLoad,
    onPlaceChanged,
    handleMapLoad,

    handleCancelCreate,
    users,
    technicians,
  }
}

export default useCreateWorkOrderForm;
