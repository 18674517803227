// Module dependencies
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMaps } from 'contexts/MapsContext';
import { getWorkOrder, updateWorkOrder, deleteWorkOrder } from 'features/workOrders/workOrderSlice';
import { getCompanyUsers } from 'features/users/userSlice';
import { fetchProjects } from 'features/projects/projectSlice';
import toast from 'react-hot-toast';
import workOrderService from 'features/workOrders/workOrderService';
import useCalendar from './useCalendar';
import { useBasicModal } from './useBasicModal';

// utils
import wording from 'utils/wording';

const {
  DOCUMENTS_DOWNLOADING,
  DOCUMENTS_DOWNLOAD_FAIL,
  DOCUMENTS_UPLOAD_FAIL,
  DOCUMENTS_UPLOAD_SUCCESS,
  DOCUMENTS_DELETE_FAIL,
  DOCUMENTS_DELETE_SUCCESS,
  WO_UPDATE_SUCCESS,
  WO_UPDATE_FAIL,
  WO_DELETE_SUCCESS,
  WO_DELETE_FAIL,
} = wording;

const useWorkOrderDetail = () => {
  const { id } = useParams();
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoaded } = useMaps();

  const {
    calendarRef,
    scheduleView,
    currentDate,
    handlePrevious,
    handleNext,
    handleViewChange,
  } = useCalendar();

  const { workOrder, isFetchingWorkorder, isLoading, isDeletingWorkOrder } = useSelector((state) => state.workOrders);
  const { projects = [] } = useSelector((state) => state.projects);
  const { users = [], technicians } = useSelector((state) => state.users);

  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 25.7617, lng: -80.1918 });
  const { workOrders = [] } = useSelector((state) => state.workOrders);
  const [activeTab, setActiveTab] = useState('overview');

  const [isDeleting, setIsDeleting] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState('');

  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileDescription, setFileDescription] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const {
    showModal: showUploadDialog,
    handleOpenModal: handleOpenUploadModal,
    handleCloseModal: handleCloseUploadModal,
  } = useBasicModal();

  const {
    showModal: showDeleteDocumentModal,
    handleOpenModal: handleOpenDeleteDocumentModal,
    handleCloseModal: handleCloseDeleteDocumentModal,
  } = useBasicModal();

  const {
    showModal: showDeleteWOModal,
    handleOpenModal: handleOpenDeleteWOModal,
    handleCloseModal: handleCloseDeleteWOModal,
  } = useBasicModal();

  useEffect(() => {
    if (id) {
      dispatch(getWorkOrder(id));
      dispatch(getCompanyUsers());
      dispatch(fetchProjects());
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (workOrder) {
      setEditedData({
        ...workOrder,
        client: { ...workOrder.client },
        areaOfConcern: { ...workOrder.areaOfConcern },
        assignedTo: workOrder.assignedTo ? workOrder.assignedTo._id : null,
        assignedToProject: workOrder.assignedToProject ? workOrder.assignedToProject._id : null,
        inspectionPriority: workOrder.inspectionPriority || 'Medium',
        screwType: workOrder.screwType || 'Regular',
        startDate: workOrder?.startDate,
        endDate: workOrder?.endDate,
      });
    }
  }, [workOrder]);

  useEffect(() => {
    if (workOrder?.address?.lat && workOrder?.address?.lng) {
      setMapCenter({
        lat: parseFloat(workOrder.address.lat),
        lng: parseFloat(workOrder.address.lng)
      });
    }
  }, [workOrder]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleBackToWorkOrder = () => {
    setEditedData(null);

    navigate('/work-orders');
  };

  const handleCancel = () => {
    setEditedData({ ...workOrder });
    setIsEditing(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setEditedData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setEditedData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleCheckboxChange = (name, checked) => {
    setEditedData(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleAreaOfConcernChange = (name, value) => {
    setEditedData(prev => ({
      ...prev,
      areaOfConcern: {
        ...prev.areaOfConcern,
        [name]: value
      }
    }));
  };

  const handleSave = async () => {
    try {
      const updatedData = {
        client: {
          name: editedData.client?.name,
          email: editedData.client?.email,
          phone: editedData.client?.phone,
        },
        address: {
          formattedAddress: editedData.address?.formattedAddress,
          lat: editedData.address?.lat,
          lng: editedData.address?.lng,
          placeId: editedData.address?.placeId,
        },
        status: editedData.status,
        underWarranty: editedData.underWarranty,
        color: editedData.color,
        screenType: editedData.screenType,
        screwType: editedData.screwType,
        inspectionRelated: editedData.inspectionRelated,
        inspectionPriority: editedData.inspectionPriority,
        areaOfConcern: {
          gutter: editedData.areaOfConcern?.gutter || false,
          screen: editedData.areaOfConcern?.screen || false,
          aluminum: editedData.areaOfConcern?.aluminum || false,
          panels: editedData.areaOfConcern?.panels || false,
          concrete: editedData.areaOfConcern?.concrete || false,
          door: editedData.areaOfConcern?.door || false,
          screws: editedData.areaOfConcern?.screws || false,
          other: editedData.areaOfConcern?.other || '',
        },
        picturesProvided: editedData.picturesProvided,
        additionalNotes: editedData.additionalNotes,
        workPerformed: editedData.workPerformed,
        amountDue: parseFloat(editedData.amountDue) || 0,
        cashOnDelivery: editedData.cashOnDelivery,
        assignedTo: editedData.assignedTo,
        assignedToProject: editedData.assignedToProject,
        materialsList: editedData.materialsList,
        startDate: editedData.startDate,
        endDate: editedData.endDate,
      };

      await dispatch(updateWorkOrder({ 
        workOrderId: id, 
        workOrderData: updatedData 
      })).unwrap();
      
      setIsEditing(false);

      await dispatch(getWorkOrder(id));

      toast.success(WO_UPDATE_SUCCESS);
    } catch (error) {
      toast.error(WO_UPDATE_FAIL);
      console.error('Update error:', error);
    }
  };

  const handleDeleteWorkOrder = async (workOrderID) => {
    try {
      handleCloseDeleteWOModal();
      
      await dispatch(
        deleteWorkOrder(workOrderID)
      );

      handleBackToWorkOrder();

      toast.success(WO_DELETE_SUCCESS);
    } catch (error) {
      toast.error(WO_DELETE_FAIL);

      console.error('Update error:', error);
    }
  };

  const getWorkOrderMarkers = () => {
    return workOrders
      .filter(wo => 
        wo._id !== id && // Exclude current work order
        wo.address?.lat && 
        wo.address?.lng
      )
      .map(wo => ({
        position: {
          lat: parseFloat(wo.address.lat),
          lng: parseFloat(wo.address.lng)
        },
        title: `${wo.client?.name} - ${wo.address?.formattedAddress}`,
        projectId: wo._id // We'll use this for navigation
      }));
  };

  const handleMarkerClick = (workOrderId) => {
    navigate(`/work-orders/${workOrderId}`);
  };

  const handleDownloadDocument = async (documentId) => {
    toast.success(DOCUMENTS_DOWNLOADING);
  
    try {
      const { url } = await workOrderService.getDocumentUrl(workOrder._id, documentId);

      toast.success(DOCUMENTS_DOWNLOADING);
      
      const link = document.createElement('a');
      link.href = url;
      link.download = 'fileName';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error(DOCUMENTS_DOWNLOAD_FAIL);
      console.error('Download error:', error);
    }
  };

  const handleDeleteButtonClick = (document) => {
    setDocumentToDelete(document);

    handleOpenDeleteDocumentModal();
  };

  const handleDeleteDocument = async () => {
    setIsDeleting(true);

    try {
      await workOrderService.deleteDocument(workOrder._id, documentToDelete._id);

      await dispatch(getWorkOrder(workOrder._id));

      toast.success(DOCUMENTS_DELETE_SUCCESS);

      handleCloseDeleteDocumentModal();
    } catch (error) {
      toast.error(DOCUMENTS_DELETE_FAIL);
  
      console.error('Delete error:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUploadDocument = async (e) => {
    e.preventDefault();

    if (!fileToUpload) return;

    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('description', fileDescription);
      
      await workOrderService.uploadDocument(workOrder._id, formData);
      await dispatch(getWorkOrder(workOrder._id));

      toast.success(DOCUMENTS_UPLOAD_SUCCESS);
  
      setFileDescription('');
      setFileToUpload(null)
      handleCloseUploadModal(false);
    } catch (error) {
      toast.error(DOCUMENTS_UPLOAD_FAIL);
      console.error('Upload error:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = ({ target}) => {
    setFileToUpload(target.files[0]);
  };

  const handleFileDescriptionChange = ({ target}) => {
    setFileDescription(target.value);
  };

  return {
    // Global
    workOrder,

    // States
    isFetchingWorkorder,
    isLoading,
    isEditing,
    projects,
    users,
    technicians,
    activeTab,
    setActiveTab,
    isDeleting,
    editedData,
  
    // Calendar
    calendarRef,
    scheduleView,
    currentDate,
    handlePrevious,
    handleNext,
    handleViewChange,

    // Map
    isLoaded,
    mapCenter,

    // Documents
    showUploadDialog,
    handleOpenUploadModal,
    handleCloseUploadModal,
    showDeleteDocumentModal,
    handleOpenDeleteDocumentModal,
    handleCloseDeleteDocumentModal,
    handleDownloadDocument,
    handleDeleteButtonClick,
    handleDeleteDocument,
    fileDescription,
    isUploading,
    handleFileChange,
    handleUploadDocument,
    handleFileDescriptionChange,
    
    handleSave,
    handleEdit,
    handleCancel,
    handleChange,
    handleCheckboxChange,
    handleAreaOfConcernChange,
    getWorkOrderMarkers,
    handleMarkerClick,
    handleBackToWorkOrder,

    // Delete
    isDeletingWorkOrder,
    showDeleteWOModal,
    handleOpenDeleteWOModal,
    handleCloseDeleteWOModal,
    handleDeleteWorkOrder,
    documentToDelete,
  };
};

export default useWorkOrderDetail;
