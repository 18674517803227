// UI
import { Heading } from 'components/catalyst/heading';
import { Text } from 'components/catalyst/text';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';

// Utils
import wording from 'utils/wording';
import NimaxLogo from 'components/NimaxLogo';

const LoginHeader = () => {
  const { COMPANY_SLOGAN } = wording;

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md gap-y-0.5 flex flex-col gap-y-1">
      <NimaxLogo />

      <Text className='text-center'>
        {COMPANY_SLOGAN}
      </Text>
    </div>
  );
};

export default LoginHeader;
