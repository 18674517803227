// UI
import { Text } from 'components/catalyst/text';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';

// Utils
import wording from "utils/wording";

const { WO_MESSAGES_EMPTY } = wording;

const WODetailMessagesEmpty = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-2 flex-grow h-80">
      <ChatBubbleOvalLeftEllipsisIcon
        className='h-8 w-8 text-zinc-900 dark:text-white'
      />

      <Text>
        {WO_MESSAGES_EMPTY}
      </Text>
    </div>
  );
};

export default WODetailMessagesEmpty;
