// Module dependencies
import { useRef } from "react";

// UI
import { Input } from "components/catalyst/input";
import { BadgeButton } from "components/catalyst/badge";
import { XMarkIcon } from "@heroicons/react/20/solid";

const TagInput = ({ value = [], onChange, placeholder = "Add tags..." }) => {
  const inputRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "," || e.key === " ") {
      e.preventDefault();
      addTag(e.target.value);
    } else if (e.key === "Backspace" && e.target.value === "" && value.length > 0) {
      removeTag(value.length - 1);
    }
  };

  const addTag = (inputValue) => {
    const trimmedInput = inputValue.trim().toLowerCase();
    if (trimmedInput && !value.includes(trimmedInput)) {
      onChange([...value, trimmedInput]);
    }
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const removeTag = (indexToRemove) => {
    onChange(value.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="flex flex-wrap gap-2">
      <Input
        ref={inputRef}
        type="text"
        onKeyDown={handleKeyDown}
        onBlur={(e) => addTag(e.target.value)}
        placeholder={value.length === 0 ? placeholder : ""}
      />

      {value.map((tag, index) => (
        <BadgeButton key={index} onClick={() => removeTag(index)} color="blue">
          {tag}
          <XMarkIcon className="w-4 h-4" />
        </BadgeButton>
      ))}
    </div>
  );
};

export default TagInput;
