import { axiosInstance } from 'config/axios';
import { endpoints } from '../../config/api';
import { getErrorMessage } from 'utils/commons';

// Add cache duration
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

// Update cache structure to include timestamp
const projectCache = new Map();
const budgetCache = new Map();

// Get all projects
const getProjects = async () => {
  const response = await axiosInstance.get(endpoints.projects.base);
  
  // Transform location data if needed
  const projects = response.data.map(project => ({
    ...project,
    location: {
      ...project.location,
      address: project.location?.address || 'N/A',
      city: project.location?.city || 'N/A',
      state: project.location?.state || 'N/A',
      country: project.location?.country || 'N/A'
    }
  }));

  return projects;
};

// Create new project
const createProject = async (projectData) => {
  const response = await axiosInstance.post(endpoints.projects.base, projectData);

  return response.data;
};

// Updated getProject method to include authentication
const getProject = async (projectId) => {
  // Check cache first
  const cachedData = projectCache.get(projectId);
  if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
    return cachedData.data;
  }

  const response = await axiosInstance.get(`${endpoints.projects.base}/${projectId}`);
  
  const project = {
    ...response.data,
    location: {
      ...response.data.location,
      address: response.data.location?.address || 'N/A',
      city: response.data.location?.city || 'N/A',
      state: response.data.location?.state || 'N/A',
      country: response.data.location?.country || 'N/A'
    }
  };

  // Cache the result with timestamp
  projectCache.set(projectId, {
    data: project,
    timestamp: Date.now()
  });
  
  return project;
};

// Updated updateProject method to include authentication
const updateProject = async (projectId, updates) => {
  // Ensure contactsInfo is properly structured if it exists
  const updatedData = {
    ...updates
  };

  // Only include contactsInfo if it exists in updates
  if (updates.contactsInfo !== undefined) {
    // Ensure it's an array and properly structured
    updatedData.contactsInfo = Array.isArray(updates.contactsInfo) 
      ? updates.contactsInfo.map(contact => ({
          name: contact.name || '',
          lastName: contact.lastName || '',
          phone: contact.phone || '',
          email: contact.email || ''
        }))
      : [];
  }

  const response = await axiosInstance.put(
    `${endpoints.projects.base}/${projectId}`, 
    updatedData, 
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  
  // Clear cache after successful update
  clearCache();
  
  return response.data;
};

const getCompanyUsers = async () => {
  const response = await axiosInstance(endpoints.users.company);
  
  return response.data.map(user => ({
    value: user._id,
    label: `${user.firstName} ${user.lastName}`.trim() || user.email
  }));
};

// Add this method to your projectService
const createBudget = async (budgetData) => {
  const response = await axiosInstance.post(endpoints.budgets.base, budgetData);

  return response.data;
};

const updateBudget = async (budgetId, budgetData) => {
  const response = await axiosInstance.put(
    endpoints.budgets.update(budgetId),
    budgetData,
  );

  return response.data;
};

const updateBudgetStatus = async (budgetId, status) => {
  const response = await axiosInstance.patch(
    endpoints.budgets.status(budgetId),
    { status },
  );

  return response.data;
};

const getBudgetHistory = async (budgetId) => {
  const response = await axiosInstance.get(`${endpoints.budgets.base}/${budgetId}/history`);

  return response.data;
};

const getBudgetDetails = async (budgetId) => {
  const cachedData = budgetCache.get(budgetId);

  if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
    return cachedData.data;
  }

  const response = await axiosInstance.get(endpoints.budgets.details(budgetId));
  
  budgetCache.set(budgetId, {
    data: response.data,
    timestamp: Date.now()
  });
  
  return response.data;
};

const uploadProjectDocument = async (projectId, file, description) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('description', description);

  const response = await axiosInstance.post(
    `${endpoints.projects.documents(projectId)}/upload`,
    formData,
  );

  return response.data;
};

const deleteProjectDocument = async (projectId, documentId) => {
  const response = await axiosInstance.delete(
    `${endpoints.projects.documents(projectId)}/documents/${documentId}`,
  );
  return response.data;
};

const getDocumentUrl = async (projectId, documentId) => {
  try {
    const response = await axiosInstance.get(
      `${endpoints.projects.documents(projectId)}/documents/${documentId}/url`
    );

    return response.data;
  } catch (error) {
    console.error('Error getting document URL:', error);
    throw error;
  }
};

// Update clearCache to be more specific
const clearCache = (type) => {
  if (!type) {
    projectCache.clear();
    budgetCache.clear();
  } else if (type === 'project') {
    projectCache.clear();
  } else if (type === 'budget') {
    budgetCache.clear();
  }
};

const assignUserToSubtask = async (projectId, data) => {
  try {
    const response = await axiosInstance.patch(
      `${endpoints.projects.base}/${projectId}/subtask/assign`,
      data,
    );

    return response.data;
  } catch (error) {
    console.error(getErrorMessage(error));

    throw error;
  }
};

const projectService = {
  getProjects,
  createProject,
  getProject,
  updateProject,
  getCompanyUsers,
  createBudget,
  updateBudget,
  updateBudgetStatus,
  getBudgetHistory,
  getBudgetDetails,
  uploadProjectDocument,
  deleteProjectDocument,
  getDocumentUrl,
  clearCache,
  assignUserToSubtask
};

export default projectService;
