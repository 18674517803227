// Module dependencies
import { Controller } from "react-hook-form";

// UI
import { Checkbox, CheckboxField } from "components/catalyst/checkbox";
import { Description, ErrorMessage, Field, Label } from "components/catalyst/fieldset";
import { Input } from "components/catalyst/input";
import { Select } from "components/catalyst/select";
import { Strong } from "components/catalyst/text";
import { Textarea } from "components/catalyst/textarea";
import TagInput from 'components/workOrder/TagInput';

// Utils
import wording from "utils/wording";
import { colorOptions, inspectionOptions, screenTypeOptions, screwTypeOptions } from "./utils";

const ServiceDetailForm = ({
  register,
  errors,
  control,
  // technicians,
  users,
}) => {
  const {
    CREATE_WO_SERVICE_DETAIL,
    CREATE_WO_COLOR_LABEL,
    CREATE_WO_SCREEN_TYPE_LABEL,
    CREATE_WO_UNDER_WARRANTY,
    INSPECTION_PRIORITY,
    CREATE_WO_SCREW_TYPE_LABEL,
    CREATE_WO_MATERIALS_LIST_LABEL,
    CREATE_WO_MATERIALS_LIST_PLACEHOLDER,
    CREATE_WO_REQUESTED_ON_LABEL,
    CREATE_WO_TAGS,
    CREATE_WO_TAGS_DESCRIPTION,
    CREATE_WO_TAGS_PLACEHOLDER,
    ERROR_REQUIRED_FIELD,
    // WO_DETAIL_TECHNICIAN,
    WORK_ORDERS_ASSIGNED_TO,
  } = wording;

  return (
    <div className="space-y-4">
      <Strong>
        {CREATE_WO_SERVICE_DETAIL}
      </Strong>

      <Field>
        <Label>{CREATE_WO_REQUESTED_ON_LABEL}</Label>

        <Input
          type="datetime-local"
          {...register('dateRequested')}
        />
      </Field>

      <Field>
        {/* <Label>{WO_DETAIL_TECHNICIAN}</Label> */}
        <Label>{WORK_ORDERS_ASSIGNED_TO}</Label>

        <Select
          {...register('assignedTo')}
        >
          <option value={''}>{''}</option>

          {users.map(tech => (
            <option 
              key={tech.id} 
              value={tech.id}
            >
              {`${tech.firstName} ${tech.lastName}`}
            </option>
          ))}
        </Select>
      </Field>

      <Field>
        <Label>{CREATE_WO_COLOR_LABEL}</Label>

        <Select
          {...register('color')}
        >
          {colorOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Field>

      <Field>
        <Label>{CREATE_WO_SCREEN_TYPE_LABEL}</Label>
        <Select
          {...register('screenType')}
        >
          {screenTypeOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Field>

      <Field>
        <Label>{CREATE_WO_SCREW_TYPE_LABEL}</Label>
        <Select
          {...register('screwType')}
        >
          {screwTypeOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Field>

      <Field>
        <Label>{INSPECTION_PRIORITY}</Label>
        <Select
          {...register('inspectionPriority')}
        >
          {inspectionOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>

        {errors.inspectionPriority?.type === 'required' && (
          <ErrorMessage>{ERROR_REQUIRED_FIELD}</ErrorMessage>
        )}
      </Field>

      <CheckboxField>
        <Controller
          name="underWarranty"
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              onChange={(checked) => field.onChange(checked)}
            />
          )}
        />
        <Label>{CREATE_WO_UNDER_WARRANTY}</Label>
      </CheckboxField>

      <Field>
        <Label>{CREATE_WO_MATERIALS_LIST_LABEL}</Label>
        <Textarea
          {...register('materialsList')}
          placeholder={CREATE_WO_MATERIALS_LIST_PLACEHOLDER}
          rows={4}
          className="resize-none"
        />
      </Field>

      <Field>
        <Label>
          {CREATE_WO_TAGS}
        </Label>
        <Description className='pb-[10px]'>
          {CREATE_WO_TAGS_DESCRIPTION}
        </Description>

        <Controller
          name="tags"
          control={control}
          render={({ field }) => (
            <TagInput
              value={field.value}
              onChange={field.onChange}
              placeholder={CREATE_WO_TAGS_PLACEHOLDER}
            />
          )}
        />
      </Field>
    </div>
  );
};

export default ServiceDetailForm;
