// Module dependencies
import useProjects from 'hooks/useProjects';

// UI
import Loader from 'components/Loader';
import ProjectsViewHeader from 'components/projectsPage/ProjectsViewHeader';
import ProjectsViewSearch from 'components/projectsPage/ProjectsViewSearch';
import ProjectsViewTable from 'components/projectsPage/ProjectsViewTable';
import Breadcrumb from 'components/Breadcrumb';

// Utils
import wording from 'utils/wording';

const Projects = () => {
  const projectsHandlers = useProjects();

  const { isLoading } = projectsHandlers;

  const { SIDEBAR_PROJECTS } = wording;

  const pages = [
    { name: SIDEBAR_PROJECTS, href: '/projects', current: false },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-6">
          <Breadcrumb pages={pages} />

          <ProjectsViewHeader {...projectsHandlers} />

          <ProjectsViewSearch {...projectsHandlers} />

          <ProjectsViewTable {...projectsHandlers} />
        </div>
      )}
    </>
  );
};

export default Projects;
