// UI
import { Button } from "components/catalyst/button";
import { Heading } from "components/catalyst/heading";
import { ChevronLeftIcon, PhoneIcon, EnvelopeIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import LoadingButton from "components/common/LoadingButton";
import { Text } from "components/catalyst/text";

// Utils
import wording from "utils/wording";
import { generatePDF } from "utils/work-orders";

const WODetailsHeader = ({
  isEditing,
  handleSave,
  handleEdit,
  handleCancel,
  handleBackToWorkOrder,
  isLoading,
  workOrder,
}) => {
  const {
    WO_DETAIL_TITLE,
    WO_DETAIL_SAVE_CHANGES,
    WO_DETAIL_EDIT,
    WO_DETAIL_CANCEL,
    WORK_ORDERS_BACK_TO_WO,
    WORK_ORDERS_DOWNLOAD_PDF,
    GLOBAL_DASH,
  } = wording;

  return (
    <div className='flex justify-between'>
      <div className="flex flex-col">
        <Heading className='capitalize'>{workOrder?.client?.name || WO_DETAIL_TITLE}</Heading>
        <div className="flex">
          {workOrder?.client?.phone && (
            <div className="flex gap-2">
              <PhoneIcon className="w-4 text-zinc-500 dark:text-zinc-400"  />

              <Text>{workOrder.client.phone}</Text>
            </div>
          )}

          {workOrder?.client?.email && workOrder.client?.phone && (
            <Text className='px-2'>{GLOBAL_DASH}</Text>
          )}

          {workOrder?.client?.email && (
            <div className="flex gap-2">
              <EnvelopeIcon className="w-4 text-zinc-500 dark:text-zinc-400" />

              <Text>{workOrder.client.email}</Text>
            </div>
          )}
        </div>
      </div>

      <div className='flex gap-2 items-center'>
        <LoadingButton
          color={isEditing ? 'green' : 'blue' }
          disabled={isLoading}
          isLoading={isLoading}
          label={isEditing ? WO_DETAIL_SAVE_CHANGES : WO_DETAIL_EDIT}
          loadingLabel={isEditing ? WO_DETAIL_SAVE_CHANGES : WO_DETAIL_EDIT}
          onClick={() => {
            if (isEditing) {
              handleSave();
            } else {
              handleEdit();
            }
          }}
        />

        <Button
          onClick={() => generatePDF(workOrder)}
          outline
        >
          <ArrowDownTrayIcon />
          {WORK_ORDERS_DOWNLOAD_PDF}
        </Button>

        <Button
          outline
          onClick={() => {
            if (isEditing) {
              handleCancel();
            } else {
              handleBackToWorkOrder();
            }
          }}
        >
          {!isEditing && <ChevronLeftIcon />}
          {isEditing ? WO_DETAIL_CANCEL : WORK_ORDERS_BACK_TO_WO}
        </Button>
      </div>
    </div>
  );
};

export default WODetailsHeader;
