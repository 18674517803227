// Module dependencies
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

// UI
import StatusBadge from 'components/StatusBadge';
import { Badge } from 'components/catalyst/badge';
import { Checkbox } from 'components/catalyst/checkbox';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/catalyst/table';

// Utils
import { formatCurrency } from 'utils/commons';
import wording from 'utils/wording';

const ProjectsViewTable = ({
  filteredProjects,
  isSelectionMode,
  selectedProjects,
  handleToggleSelection,
}) => {
  const navigate = useNavigate();

  const {
    PROJECT_TABLE_SELECT,
    PROJECT_TABLE_PROJECT,
    PROJECT_TABLE_STATUS,
    PROJECT_TABLE_LOCATION,
    PROJECT_TABLE_BUDGET,
    PROJECT_TABLE_PROGRESS,
  } = wording;

  const handleRowClick = (e, projectId) => {
    if (isSelectionMode) {
      e.preventDefault();
      handleToggleSelection(projectId);
    } else {
      navigate(`/projects/${projectId}`);
    }
  };

  const tableColumns = [
    {
      label: PROJECT_TABLE_SELECT,
      renderIf: isSelectionMode,
    },
    {
      label: PROJECT_TABLE_PROJECT,
    },
    {
      label: PROJECT_TABLE_STATUS,
    },
    {
      label: PROJECT_TABLE_LOCATION,
    },
    {
      label: PROJECT_TABLE_BUDGET,
    },
    {
      label: PROJECT_TABLE_PROGRESS,
    },
  ];

  return (
    <div>
      <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
        <TableHead>
          <TableRow>
            {tableColumns.map(({ label, renderIf }) => (
              <Fragment key={label}>
                {(renderIf === undefined || renderIf) && (
                  <TableHeader>{label}</TableHeader>
                )}
              </Fragment>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredProjects.map(({ _id, name, tags, status, location, budgetId, stages }) => (
            <TableRow
              key={_id}
              onClick={(e) => handleRowClick(e, _id)}
              className="cursor-pointer hover:bg-gray-50 hover:dark:bg-zinc-800/50"
            >
              {isSelectionMode && (
                <TableCell>
                  <Checkbox
                    checked={selectedProjects.has(_id)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleToggleSelection(_id)}
                  />
                </TableCell>
              )}
              <TableCell>
                <div className="font-medium">
                  {name}
                </div>
            
                {(tags && tags.length > 0) && (
                  <div className="mt-1 flex flex-wrap gap-1">
                    {tags.map((tag, index) => (
                      <Badge key={index} color="sky" className='capitalize'>{tag}</Badge>
                    ))}
                  </div>
                )}
              </TableCell>

              <TableCell>
                <StatusBadge status={status} />
              </TableCell>

              <TableCell>
                {location.address}
              </TableCell>

              <TableCell>
                {budgetId ? formatCurrency(budgetId.totalAmount) : 'N/A'}
              </TableCell>

              <TableCell>
                  <div className="flex items-center">
                    <div className="flex-1 h-2 bg-sky-100 dark:bg-sky-500/15 rounded-full">
                      <div
                        className="h-2 bg-sky-600 rounded-full"
                        style={{
                          width: `${stages?.reduce((acc, stage) => 
                            acc + (stage.completed ? 1 : 0), 0) / 
                            (stages?.length || 1) * 100}%`
                        }}
                      />
                    </div>
                    <span className="ml-2 text-sm text-gray-600">
                      {Math.round(
                        (stages?.reduce((acc, stage) => 
                          acc + (stage.completed ? 1 : 0), 0) / 
                          (stages?.length || 1) * 100)
                      )}%
                    </span>
                  </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProjectsViewTable;
