// Module dependencies
import { Fragment } from 'react';

// UI
import WODetailsOverviewTab from './WODetailOverviewTab';
import WODetailsClietTab from './WODetailClientTab';
import WODetailDocumentsTab from './WODetailDocumentsTab';
import WODetailScheduleTab from './WODetailScheduleTab';
import WODetailManageTab from './WODetailManageTab';
import WODetailMessagesTab from './WODetailMessagesTab';
import WODetailActivityTab from './WODetailActivityTab';

// Utils
import wording from 'utils/wording';

const {
  WO_DETAIL_OVERVIEW,
  WO_DETAIL_CLIENT_DETAILS,
  DOCUMENTS_TITLE,
  WO_DETAIL_SCHEDULE,
  WO_DETAIL_MANAGE,
  WO_DETAIL_MESSAGES,
  WO_DETAIL_ACTIVITY_LOG,
} = wording;

const WODetailsTabs = ({ workOrderDetailHandlers }) => {
  const {
    activeTab,
    setActiveTab,
  } = workOrderDetailHandlers;

  const tabList = [
    {
      label: WO_DETAIL_OVERVIEW,
      value: 'overview',
      component: <WODetailsOverviewTab workOrderDetailHandlers={workOrderDetailHandlers} />,
    },
    {
      label: WO_DETAIL_CLIENT_DETAILS,
      value: 'client-details',
      component: <WODetailsClietTab workOrderDetailHandlers={workOrderDetailHandlers} />,
    },
    {
      label: DOCUMENTS_TITLE,
      value: 'documents',
      component: <WODetailDocumentsTab {...workOrderDetailHandlers} />,
    },
    {
      label: WO_DETAIL_SCHEDULE,
      value: 'schedule',
      component: <WODetailScheduleTab {...workOrderDetailHandlers} />,
    },
    {
      label: WO_DETAIL_MESSAGES,
      value: 'messages',
      component: <WODetailMessagesTab {...workOrderDetailHandlers} />,
    },
    {
      label: WO_DETAIL_ACTIVITY_LOG,
      value: 'activity-log',
      component: <WODetailActivityTab {...workOrderDetailHandlers} />,
    },
    {
      label: WO_DETAIL_MANAGE,
      value: 'manage',
      component: <WODetailManageTab {...workOrderDetailHandlers} />,
    },
  ];

  return (
    <div className='h-full'>
      <div className="mb-6 border-b border-gray-200 dark:border-gray-800">
        <nav className="-mb-px flex space-x-8">
          {tabList.map(({ label, value }) => (
            <button
              key={value}
              onClick={() => setActiveTab(value)}
              className={`
                py-4 px-1 border-b-2 font-medium text-sm whitespace-nowrap
                ${activeTab === value
                  ? 'border-blue-500 text-blue-600 dark:border-white dark:text-white'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 hover:dark:text-gray-100'
                }
              `}
            >
              {label}
            </button>
          ))}
        </nav>
      </div>

      {tabList.map(({ value, component }) => (
        <Fragment key={value}>
          {value === activeTab && component}
        </Fragment>
      ))}
    </div>
  );
};

export default WODetailsTabs;
