const wording = {
  // Globals
  GLOBAL_CANCEL: 'Cancel',
  GLOBAL_CONFIRM: 'Confirm',
  GLOBAL_HOME: 'Home',
  GLOBAL_NA: 'N/A',
  GLOBAL_NO_PROJECT: 'No Project',
  GLOBAL_UNASSIGNED: 'Unassigned',
  GLOBAL_MONTH: 'Month',
  GLOBAL_WEEK: 'Week',
  GLOBAL_YES: 'Yes',
  GLOBAL_NO: 'No',
  GLOBAL_DASH: '-',
  GLOBAL_DELETE: 'Delete',
  GLOBAL_DELETING: 'Deleting...',
  GLOBAL_UPLOAD: 'Upload',
  GLOBAL_UPLOADING: 'Uploading...',
  GLOBAL_ASTERISK: '*',
  GLOBAL_NEW: 'New',
  GLOBAL_SOON: 'Soon',
  GLOBAL_COMING_SOON: 'Coming Soon',

  // Branding
  COMPANY_NAME: 'NIMAX BUILDER',
  COMPANY_SLOGAN: 'Construction Project Management',

  // Login Form
  LOGIN_EMAIL_LABEL: 'Email address',
  LOGIN_PASSWORD_LABEL: 'Password',
  LOGIN_REGISTER_ACCOUNT: 'Register new account',
  LOGIN_FORGOT_PASSWORD: 'Forgot password?',
  LOGIN_BUTTON: 'Sign in',
  SIGNING_IN_BUTTON: 'Signing in...',

  // Password Management
  PASSWORD_RESET: 'Reset your password',
  PASSWORD_RESET_LINK: 'Send reset link',
  PASSWORD_BACK_TO_LOGIN: 'Back to login',
  PASSWORD_RESETING: 'Sending...',
  PASSWORD_RESET_SUCCESS: 'If an account exists with this email, a password reset link will be sent.',

  PASSWORD_NEW_TITLE: 'Set new password',
  PASSWORD_NEW_LABEL_PASSWORD: 'New password',
  PASSWORD_NEW_LABEL_CONFIRM_PASSWORD: 'Confirm password',
  PASSWORD_NEW_PLACEHOLDER_PASSWORD: 'Enter your new password',
  PASSWORD_NEW_PLACEHOLDER_CONFIRM_PASSWORD: 'Confirm your new password',
  PASSWORD_NEW_ERROR_REQUIRED: 'Password is required',
  PASSWORD_NEW_ERROR_MIN_LENGTH: 'Password must be at least 8 characters',
  PASSWORD_NEW_ERROR_CONFIRM_REQUIRED: 'Please confirm your password',
  PASSWORD_NEW_ERROR_MISMATCH: 'Passwords do not match',
  PASSWORD_NEW_SUBMITTING: 'Resetting...',
  PASSWORD_NEW_BUTTON: 'Reset password',
  PASSWORD_NEW_SUCCESS: 'Password reset successfully',

  PASSWORD_CHANGE_TITLE: 'Change Password',
  PASSWORD_CHANGE_LABEL_CURRENT_PASSWORD: 'Current Password',
  PASSWORD_CHANGE_LABEL_NEW_PASSWORD: 'New Password',
  PASSWORD_CHANGE_LABEL_CONFIRM_PASSWORD: 'Confirm New Password',
  PASSWORD_CHANGE_PLACEHOLDER_CURRENT_PASSWORD: 'Enter your current password',
  PASSWORD_CHANGE_PLACEHOLDER_NEW_PASSWORD: 'Enter your new password',
  PASSWORD_CHANGE_PLACEHOLDER_CONFIRM_PASSWORD: 'Confirm your new password',
  PASSWORD_CHANGE_ERROR_MISMATCH: 'New passwords do not match',
  PASSWORD_CHANGE_SUCCESS: 'Password changed successfully',
  PASSWORD_CHANGE_ERROR_GENERIC: 'Failed to change password',
  PASSWORD_CHANGE_BUTTON_LOADING: 'Changing Password...',
  PASSWORD_CHANGE_BUTTON: 'Change Password',
  CHANGE_PASSWORD: 'Change Password',

  // Error Messages
  ERROR_MESSAGE: 'An error occurred. Please try again.',
  ERROR_SESSION_EXPIRED: 'Your session has expired. Please log in again to continue.',
  ERROR_REQUIRED_FIELD: 'This field is required.',
  ERROR_ENDATE_GREATER: 'The end date must be later than the start date.',
  ERROR_REQUIRED_AREAS: 'At least one area must be selected or described.',
  ERROR_INVALID_EMAIL: 'Please enter a valid email.',
  ERROR_INVALID_PHONE: 'Please enter a valid phone number.',
  ERROR_INVALID_AMOUNT: 'Please enter a valid amount.',
  ERROR_INVALID_PASS_LENGTH: 'Password must be at least 8 characters long.',

  // Copyright
  COPYRIGHT_TEXT: (year) => `© ${year} NIMAX BUILDER. All rights reserved.`,

  // Sidebar
  SIDEBAR_COMPANY_NAME: 'NIMAX BUILDER',
  SIDEBAR_DASHBOARD: 'Dashboard',
  SIDEBAR_PROJECTS: 'Projects',
  SIDEBAR_SCHEDULING: 'Scheduling',
  SIDEBAR_WORK_ORDERS: 'Work Orders',
  SIDEBAR_PENDING_LOCATIONS: 'Locations',
  SIDEBAR_GLOBAL_SCHEDULE: 'Global Schedule',
  SIDEBAR_TEAM: 'Team',
  SIDEBAR_DOCUMENTS: 'Documents',
  SIDEBAR_SUPPORT: 'Support',
  SIDEBAR_CHANGELOG: 'Changelog',
  SIDEBAR_MY_PROFILE: 'My profile',
  SIDEBAR_THEME: 'Theme',
  SIDEBAR_SIGN_OUT: 'Sign out',
  SIDEBAR_VERSION: '© NIMAX - Version',

  // Logout Modal
  LOGOUT_MODAL_TITLE: 'Confirm Logout',
  LOGOUT_MODAL_MESSAGE: 'Are you sure you want to log out?',
  LOGOUT_MODAL_CANCEL_BUTTON: 'Cancel',
  LOGOUT_MODAL_CONFIRM_BUTTON: 'Logout',

  // Projects Page
  PROJECTS_TITLE: 'Projects',
  PROJECTS_CANCEL_SELECTION: 'Cancel Selection',
  PROJECTS_SELECT_PROJECTS: 'Select Projects',
  PROJECTS_SELECT_ALL: 'Select All',
  PROJECTS_DESELECT_ALL: 'Deselect All',
  PROJECTS_EXPORT_SELECTED: (count) => `Export Selected (${count}) Projects`,
  PROJECTS_EXPORT_ALL: 'Export All Projects',
  PROJECTS_VIEW_MAP: 'View Map',
  PROJECTS_NEW_PROJECT: 'New Project',

  // Search and Filters
  PROJECTS_SEARCH_PLACEHOLDER: 'Search projects...',
  PROJECTS_FILTER_STATUS_PLACEHOLDER: 'Filter by status',

  // Project Status Options
  PROJECTS_STATUS_ALL: 'All Status',
  PROJECTS_STATUS_PENDING: 'Pending',
  PROJECTS_STATUS_SOLD: 'Sold',
  PROJECTS_STATUS_PRE_CONSTRUCTION: 'Pre-Construction',
  PROJECTS_STATUS_PERMITTING: 'Permitting',
  PROJECTS_STATUS_CONSTRUCTION: 'Construction',
  PROJECTS_STATUS_DONE: 'Done',

  // Table Headers
  PROJECTS_TABLE_PROJECT: 'Project',
  PROJECTS_TABLE_STATUS: 'Status',
  PROJECTS_TABLE_LOCATION: 'Location',
  PROJECTS_TABLE_BUDGET: 'Budget',
  PROJECTS_TABLE_PROGRESS: 'Progress',

  // Table Columns
  PROJECT_TABLE_SELECT: 'Select',
  PROJECT_TABLE_PROJECT: 'Project',
  PROJECT_TABLE_STATUS: 'Status',
  PROJECT_TABLE_LOCATION: 'Location',
  PROJECT_TABLE_BUDGET: 'Budget',
  PROJECT_TABLE_PROGRESS: 'Progress',

  // Project Details
  PROJECTS_LOCATION: 'Location:',
  PROJECTS_BUDGET: 'Budget:',
  PROJECTS_PROGRESS: 'Progress:',

  // Empty States
  PROJECTS_NO_BUDGET: 'N/A',

  // Map
  PROJECTS_MAP_TITLE: 'Projects Map',

  // Work Orders Page
  WORK_ORDERS_TITLE: 'Work Orders',
  WORK_ORDERS_SEARCH_PLACEHOLDER: 'Search work orders...',
  WORK_ORDERS_CREATE_BUTTON: 'Create Work Order',
  WORK_ORDERS_SHOW_PENDING_LOCATIONS: 'Pending Locations',
  WORK_ORDERS_GLOBAL_SCHEDULE: 'Global Schedule',
  WORK_ORDERS_SCHEDULE: 'Work Orders Schedule',
  WORK_ORDERS_PENDING_LOCATIONS: (status, count) => `${status} Work Order Locations (${count})`,
  WORK_ORDERS_BACK_TO_WO: 'Back to Work Orders',

  WORK_ORDERS_TAGS: 'Tags',
  WORK_ORDERS_AVAILABLE_TAGS: 'Available tags:',

  WORK_ORDERS_CLIENT: 'Client',
  WORK_ORDERS_DATE_REQUESTED: 'Date Requested',
  WORK_ORDERS_ADDRESS: 'Address',
  WORK_ORDERS_STATUS: 'Status',
  WORK_ORDERS_AMOUNT_DUE: 'Amount Due',
  WORK_ORDERS_CREATED_BY: 'Created By',
  WORK_ORDERS_ASSIGNED_TO: 'Assigned To',
  WORK_ORDERS_PROJECT: 'Project',
  WORK_ORDERS_ACTIONS: 'Actions',
  WORK_ORDERS_DOWNLOAD_PDF: 'Download PDF',
  WORK_ORDERS_VIEW: 'View Order',
  WORK_ORDERS_INCLUDE_COMPLETED: 'Display completed',

  // Create Work Orders Page
  CREATE_WO_WORK_ORDERS: "Work Orders",
  CREATE_WO_CREATE_WORK_ORDER: "Create Work Order",
  CREATE_WO_CREATING_WORK_ORDER: "Creating Work Order",
  CREATE_WO_CANCEL: "Cancel",

  // Client Information
  CREATE_WO_CLIENT: "Client Information",
  CREATE_WO_NAME_LABEL: "Name *",
  CREATE_WO_NAME_PLACEHOLDER: "Enter client name",
  CREATE_WO_EMAIL_LABEL: "Email",
  CREATE_WO_EMAIL_PLACEHOLDER: "Enter client email",
  CREATE_WO_PHONE_LABEL: "Phone",
  CREATE_WO_PHONE_PLACEHOLDER: "(555) 555-5555",
  CREATE_WO_ADDRESS_LABEL: "Address *",
  CREATE_WO_ADDRESS_PLACEHOLDER: "Search for address...",

  // Service Details
  CREATE_WO_SERVICE_DETAIL: "Service Details",
  CREATE_WO_COLOR_LABEL: "Color",
  CREATE_WO_WHITE: "White",
  CREATE_WO_BRONZE: "Bronze",
  CREATE_WO_SCREEN_TYPE_LABEL: "Screen Type",
  CREATE_WO_SCREEN_TYPE_REGULAR: "Regular 14x18",
  CREATE_WO_SCREEN_TYPE_NO_SEE_UMS: "No-See-Ums 20/20",
  CREATE_WO_SCREEN_TYPE_SUPER_17X14: "17x14 Super Screen",
  CREATE_WO_SCREEN_TYPE_SUPER_17X20: "17x20 Super Screen",
  CREATE_WO_UNDER_WARRANTY: "Under Warranty",
  CREATE_WO_SCREW_TYPE_LABEL: 'Screws',
  CREATE_WO_TAGS: "Tags",
  CREATE_WO_TAGS_DESCRIPTION: "Tags help organize and filter work orders",
  CREATE_WO_TAGS_PLACEHOLDER: "Add tags (press Enter, space or comma to add)",
  CREATE_WO_INSPECTION_LOW: "Low",
  CREATE_WO_INSPECTION_MEDIUM: "Medium",
  CREATE_WO_INSPECTION_HIGH: "High",
  CREATE_WO_REQUESTED_ON_LABEL: 'Requested On',

  // Areas of Concern
  CREATE_WO_AREAS_OF_CONCERN: "Areas of Concern",
  CREATE_WO_OTHER_CONCERNS: "Other Concerns",
  CREATE_WO_OTHER_CONCERNS_PLACEHOLDER: "Describe other concerns...",
  CREATE_WO_AREA_GUTTER: "gutter",
  CREATE_WO_AREA_SCREEN: "screen",
  CREATE_WO_AREA_ALUMINUM: "aluminum",
  CREATE_WO_AREA_PANELS: "panels",
  CREATE_WO_AREA_CONCRETE: "concrete",
  CREATE_WO_AREA_DOOR: "door",
  CREATE_WO_AREA_SCREWS: "screws",

  // Additional Information
  CREATE_WO_ADDITIONAL_INFORMATION: "Additional Information",
  CREATE_WO_WORK_PERFORMED_LABEL: "Work Performed",
  CREATE_WO_WORK_PERFORMED_PLACEHOLDER: "Describe the work performed...",
  CREATE_WO_AMOUNT_DUE_LABEL: "Amount Due",
  CREATE_WO_AMOUNT_DUE_PLACEHOLDER: "Enter the amount due",
  CREATE_WO_CASH_ON_DELIVERY: "Cash on Delivery",
  CREATE_WO_PICTURES_PROVIDED: "Pictures Provided",
  CREATE_WO_CREATE_WORK_ORDER_BUTTON: "Create Work Order",

  // Add these new wordings
  INSPECTION_PRIORITY: 'Inspection Priority',
  INSPECTION_PRIORITY_HIGH: 'High',
  INSPECTION_PRIORITY_MEDIUM: 'Medium',
  INSPECTION_PRIORITY_LOW: 'Low',

  CREATE_WO_MATERIALS_LIST_LABEL: 'Materials List',
  CREATE_WO_MATERIALS_LIST_PLACEHOLDER: 'List the materials needed for this project...',

  CREATE_WO_START_DATE_LABEL: 'Start Date *',
  CREATE_WO_END_DATE_LABEL: 'End Date *',

  // Work Oder Details
  WO_DETAIL_WORK_ORDERS: "Work Orders",
  WO_DETAIL_WORK_ORDER_NUMBER: 'Work Order #',
  WO_DETAIL_TITLE: "Work Order Details",
  WO_DETAIL_CREATED_ON: "Created on",
  WO_DETAIL_SAVE_CHANGES: "Save Changes",
  WO_DETAIL_CANCEL: "Cancel",
  WO_DETAIL_EDIT: "Edit Work Order",
  WO_DETAIL_BACK: "Back",
  WO_DETAIL_STATUS: "Status",
  WO_DETAIL_PENDING: "Pending",
  WO_DETAIL_IN_PROGRESS: "In Progress",
  WO_DETAIL_COMPLETED: "Completed",
  WO_DETAIL_CANCELLED: "Cancelled",
  WO_DETAIL_ASSIGNMENT: "Assignment",
  WO_DETAIL_WORK_DETAILS: "Work Details",
  WO_DETAIL_SERVICE_SPEC: "Service Specifications",
  WO_DETAIL_COLOR: "Color",
  WO_DETAIL_SCREEN_TYPE: "Screen Type",
  WO_DETAIL_SCREW_TYPE: "Screw Type",
  WO_DETAIL_UNDER_WARRANTY: "Under Warranty",
  WO_DETAIL_INSPECTION_PRIORITY: "Inspection Priority",
  WO_DETAIL_HIGH: "High",
  WO_DETAIL_MEDIUM: "Medium",
  WO_DETAIL_LOW: "Low",
  WO_DETAIL_MATERIALS_LIST: "Materials List",
  WO_DETAIL_START_DATE: "Start Date",
  WO_DETAIL_END_DATE: "End Date",
  WO_DETAIL_CLIENT_INFORMATION: "Client Information",
  WO_DETAIL_NAME: "Name",
  WO_DETAIL_EMAIL: "Email",
  WO_DETAIL_PHONE: "Phone",
  WO_DETAIL_ADDRESS: "Address",
  WO_DETAIL_LOCATION: "Location",
  WO_DETAIL_AREAS_OF_CONCERN: "Areas of Concern",
  WO_DETAIL_NO_AREAS_SELECTED: "No areas specified",
  WO_DETAIL_OTHER_CONCERNS: "Other Concerns",
  WO_DETAIL_WORK_PERFORMED: "Work Performed",
  WO_DETAIL_AMOUNT_DUE: "Amount Due",
  WO_DETAIL_TECHNICIAN: "Technician",
  WO_DETAIL_UNASSIGNED: "Unassigned",
  WO_DETAIL_ASSIGNED_TO: "Assigned To",
  WO_DETAIL_PROJECT: "Project",
  WO_DETAIL_NO_PROJECT: "No Project",
  WO_DETAIL_CLIENT_DETAILS: "Client Details",
  WO_DETAIL_SCHEDULE: "Schedule",
  WO_DETAIL_OVERVIEW: "Overview",
  WO_DETAIL_MANAGE: "Manage",
  WO_DETAIL_MESSAGES: "Messages",
  WO_DETAIL_NO_SCHEDULE: "No schedule information available",
  WO_DETAIL_UPLOADED_BY: (name) => `Uploaded by ${name}`,
  WO_DETAIL_UPLOAD: 'Uploaded',
  WO_DETAIL_FILE_SIZE_MB: "{{size}} MB",

  WO_DETAIL_FILE: "File",
  WO_DETAIL_FILE_DESCRIPTION: "Description",


  WO_DETAIL_MANAGE_DELETE_DESCRIPTION: "Deleting this work order will permanently remove all associated data and cannot be reversed.",
  WO_DETAIL_MANAGE_DELETE_CONFIRM: "Are you sure you want to delete this work order? This action cannot be undone.",
  WO_DETAIL_MANAGE_DELETE: "Delete Work Order",
  WO_DETAIL_MANAGE_DELETING: "Deleting...",

  WO_UPDATE_SUCCESS: 'Work order updated successfully',
  WO_UPDATE_FAIL: 'Failed to update work order',
  WO_DELETE_SUCCESS: 'Work order deleted successfully',
  WO_DELETE_FAIL: 'Failed to delete work order',

  WO_MESSAGES_EMPTY: 'No messages yet',
  WO_MESSAGES_PLACEHOLDER: 'Type your message... Use @ to mention users',
  WO_MESSAGES_FAILED: "Failed to send message",
  WO_MESSAGES_SUCCESS: "Message sended successfully",
  WO_MESSAGES_SEND: "Send",
  WO_MESSAGES_SENDING: "Sending...",

  // Work Order Details
  WO_DETAIL_REQUESTED_ON: 'Requested On',

  // Documents
  DOCUMENTS_TITLE: "Documents",
  DOCUMENTS_COMPANY_TITLE: "Company Documents",
  DOCUMENTS_EMPTY_STATE: "No documents uploaded yet",
  DOCUMENTS_DELETE: "Delete Document",
  DOCUMENTS_UPLOAD: "Upload Document",
  DOCUMENTS_CONFIRM_DELETE: "Are you sure you want to delete this document? This action cannot be undone.",
  DOCUMENTS_DOWNLOADING: "Downloading document...",
  DOCUMENTS_DOWNLOAD_FAIL: "Failed to download document",
  DOCUMENTS_DOWNLOAD_SUCCESS: "Document downloaded successfully",
  DOCUMENTS_UPLOAD_FAIL: "Failed to upload document",
  DOCUMENTS_UPLOAD_SUCCESS: "Document uploaded successfully",
  DOCUMENTS_DELETE_FAIL: "Failed to delete document",
  DOCUMENTS_DELETE_SUCCESS: "Document deleted successfully",

  // Activity Log
  WO_DETAIL_ACTIVITY_LOG: 'Activity Log',
  WO_ACTIVITY_LOG_TITLE: 'Activity Log',
  WO_DETAIL_ACTIVITY_LOG_ERROR: 'Failed to load activity logs',
};

export const translate = (key, params) => {
  let translation = wording[key];

  for (const param in params) {
    translation = translation.replace(`\${${param}}`, params[param]);
  }

  return translation;
}

export default wording;
