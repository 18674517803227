import { Heading } from 'components/catalyst/heading';
import Card from 'components/common/Card';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';

import wording from 'utils/wording';
import { Input } from 'components/catalyst/input';
import { BoxCentered } from 'components/common/StyledBoxs';
import Loader from 'components/Loader';
import Map from 'components/common/Map';

const ClientLocationCard = ({
  workOrder,
  isEditing,
  editedData,
  handleChange,
  isLoaded,
  mapCenter,
  getWorkOrderMarkers,
  handleMarkerClick,
}) => {
  const {
    WO_DETAIL_LOCATION,
    WO_DETAIL_ADDRESS,
  } = wording;

  return (
    <Card className="bg-white">
      <Heading level={2} className='pb-2'>
        {WO_DETAIL_LOCATION}
      </Heading>

      <DescriptionList>
        <DescriptionTerm>
          {WO_DETAIL_ADDRESS}
        </DescriptionTerm>

        <DescriptionDetails>
          {isEditing ? (
            <Input
              type="text"
              name="address.formattedAddress"
              value={editedData.address?.formattedAddress || ''}
              onChange={handleChange}
            />
          ) : (
            <>{workOrder?.address?.formattedAddress}</>
          )}
        </DescriptionDetails>
      </DescriptionList>

      <div className="h-[400px] rounded-lg overflow-hidden border border-gray-200 dark:border-gray-800">
        {!isLoaded ? (
          <BoxCentered className='h-full'>
            <Loader />
          </BoxCentered>
        ) : (
          <div style={{ height: '100%', width: '100%' }}>
            <Map
              center={mapCenter}
              marker={workOrder.address ? {
                position: {
                  lat: parseFloat(workOrder.address.lat),
                  lng: parseFloat(workOrder.address.lng)
                },
                title: workOrder.address.formattedAddress
              } : null}
              markers={getWorkOrderMarkers()}
              onMarkerClick={handleMarkerClick}
              fitMarkers={false}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ClientLocationCard;
