import { useSelector } from 'react-redux';
import ProjectWizard from '../components/projectsPage/ProjectWizard';

const NewProject = () => {
  const { user } = useSelector((state) => state.auth);

  if (!user) return null;
  return (
    <div className="container mx-auto px-4">
      <ProjectWizard />
    </div>
  );
};

export default NewProject; 