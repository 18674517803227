// Module dependencies
import { logout, reset } from 'features/auth/authSlice';
import { useDispatch } from 'react-redux';

// UI
import { Button } from 'components/catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from 'components/catalyst/dialog';
import { Text } from 'components/catalyst/text';

// Utils
import wording from 'utils/wording';

const LogoutModal = ({
  isOpen,
  handleCloseModal,
}) => {
  const dispatch = useDispatch();

  const {
    LOGOUT_MODAL_TITLE,
    LOGOUT_MODAL_MESSAGE,
    LOGOUT_MODAL_CANCEL_BUTTON,
    LOGOUT_MODAL_CONFIRM_BUTTON,
  } = wording;

  const handleLogout = () => {
    dispatch(logout());

    dispatch(reset());
  
    handleCloseModal();
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <DialogTitle>{LOGOUT_MODAL_TITLE}</DialogTitle>

      <DialogBody>
        <Text>
          {LOGOUT_MODAL_MESSAGE}
        </Text>
      </DialogBody>
      
      <DialogActions>
        <Button plain onClick={handleCloseModal} className='cursor-pointer'>
          {LOGOUT_MODAL_CANCEL_BUTTON}
        </Button>

        <Button onClick={handleLogout} color='rose' className='cursor-pointer'>
          {LOGOUT_MODAL_CONFIRM_BUTTON}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
