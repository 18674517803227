import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import Card from '../components/common/Card';
import { fetchProjects } from '../features/projects/projectSlice';
import { getWorkOrders } from '../features/workOrders/workOrderSlice';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projects, isLoading } = useSelector((state) => state.projects);
  const { workOrders, isWorkOrdersLoading } = useSelector((state) => state.workOrders);
  const { user } = useSelector((state) => state.auth);
  const [sortOrder, setSortOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 5;
  const [filterStatus, setFilterStatus] = useState('all');
  const [filters, setFilters] = useState({
    projectId: null,
    search: ''
  });

  const calculateWorkOrderKPIs = () => {
    if (!workOrders?.length) return {
      totalWorkOrders: 0,
      activeWorkOrders: 0,
      completedWorkOrders: 0,
      urgentWorkOrders: 0,
      averageResolutionTime: 0,
      customerSatisfaction: 0
    };

    const stats = workOrders.reduce((acc, wo) => {
      // Update counts
      acc.totalWorkOrders++;
      
      if (wo.status === 'completed') {
        acc.completedWorkOrders++;
        
        // Calculate resolution time for completed work orders
        if (wo.completedAt && wo.createdAt) {
          const resolutionTime = new Date(wo.completedAt) - new Date(wo.createdAt);
          acc.totalResolutionTime += resolutionTime / (1000 * 60 * 60 * 24); // Convert to days
        }
      } else {
        acc.activeWorkOrders++;
      }

      // Count urgent work orders
      if (wo.priority === 'urgent') {
        acc.urgentWorkOrders++;
      }

      // Calculate customer satisfaction if available
      if (wo.customerRating) {
        acc.totalSatisfaction += wo.customerRating;
        acc.ratedOrders++;
      }

      return acc;
    }, {
      totalWorkOrders: 0,
      activeWorkOrders: 0,
      completedWorkOrders: 0,
      urgentWorkOrders: 0,
      totalResolutionTime: 0,
      totalSatisfaction: 0,
      ratedOrders: 0
    });

    // Calculate averages
    stats.averageResolutionTime = stats.completedWorkOrders > 0 
      ? stats.totalResolutionTime / stats.completedWorkOrders 
      : 0;
    
    stats.customerSatisfaction = stats.ratedOrders > 0 
      ? stats.totalSatisfaction / stats.ratedOrders 
      : 0;

    return stats;
  };

  const workOrderKPIs = calculateWorkOrderKPIs();

  const workOrderStatusChart = {
    labels: ['Open', 'In Progress', 'Pending', 'Completed'],
    datasets: [{
      data: [
        workOrders.filter(wo => wo.status === 'open').length,
        workOrders.filter(wo => wo.status === 'in_progress').length,
        workOrders.filter(wo => wo.status === 'pending').length,
        workOrders.filter(wo => wo.status === 'completed').length
      ],
      backgroundColor: [
        'rgba(59, 130, 246, 0.8)',   // Blue-500
        'rgba(245, 158, 11, 0.8)',   // Amber-500
        'rgba(99, 102, 241, 0.8)',   // Indigo-500
        'rgba(16, 185, 129, 0.8)'    // Emerald-500
      ],
      borderColor: [
        'rgba(59, 130, 246, 1)',
        'rgba(245, 158, 11, 1)',
        'rgba(99, 102, 241, 1)',
        'rgba(16, 185, 129, 1)'
      ],
      borderWidth: 1
    }]
  };

  const isProjectComplete = (project) => {
    return project.stages.every(stage => stage.completed);
  };

  const isProjectDelayed = (project) => {
    const currentDate = new Date();
    const endDate = new Date(project.timeline?.estimatedEndDate);
    return currentDate > endDate && !isProjectComplete(project);
  };

  const getCurrentStage = (project) => {
    const currentStage = project.stages.find(stage => stage.isCurrentStage);
    return currentStage ? currentStage.name.replace('_', ' ') : 'Not Started';
  };

  const getStatusClass = (project) => {
    if (isProjectDelayed(project)) return 'bg-red-50 text-red-900 border border-red-100';
    if (project.stages[project.stages.length - 1].completed) return 'bg-sky-50 text-sky-900 border border-sky-100';
    return 'bg-blue-50 text-blue-900 border border-blue-100';
  };

  const projectStatusChart = {
    labels: ['On Track', 'Delayed', 'At Risk', 'Completed'],
    datasets: [{
      data: [
        projects.filter(p => {
          const currentDate = new Date();
          const endDate = new Date(p.timeline?.estimatedEndDate);
          return !isProjectComplete(p) && currentDate <= endDate;
        }).length,
        
        projects.filter(p => {
          const currentDate = new Date();
          const endDate = new Date(p.timeline?.estimatedEndDate);
          return !isProjectComplete(p) && currentDate > endDate;
        }).length,
        
        projects.filter(p => 
          !isProjectComplete(p) && 
          p.stages.find(s => s.isCurrentStage)?.tasks.some(t => !t.completed)
        ).length,
        
        projects.filter(p => isProjectComplete(p)).length
      ],
      backgroundColor: [
        'rgba(74, 222, 128, 0.8)',   // Green-400 with more opacity
        'rgba(250, 204, 21, 0.8)',   // Yellow-400 with more opacity
        'rgba(248, 113, 113, 0.8)',  // Red-400 with more opacity
        'rgba(96, 165, 250, 0.8)'    // Blue-400 with more opacity
      ],
      borderColor: [
        'rgba(74, 222, 128, 1)',     // Solid Green-400
        'rgba(250, 204, 21, 1)',     // Solid Yellow-400
        'rgba(248, 113, 113, 1)',    // Solid Red-400
        'rgba(96, 165, 250, 1)'      // Solid Blue-400
      ],
      borderWidth: 1,
      hoverBackgroundColor: [
        'rgba(74, 222, 128, 0.9)',
        'rgba(250, 204, 21, 0.9)',
        'rgba(248, 113, 113, 0.9)',
        'rgba(96, 165, 250, 0.9)'
      ],
      hoverBorderColor: '#ffffff',
      hoverBorderWidth: 2,
    }]
  };

  useEffect(() => {
    dispatch(fetchProjects());
    dispatch(getWorkOrders());
  }, [dispatch]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 20
    },
    plugins: {
      title: {
        display: true,
        text: 'Project Status Distribution',
        padding: {
          top: 10,
          bottom: 30
        },
        font: {
          size: 16,
          weight: 'bold',
          family: "'Inter', sans-serif"
        }
      },
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded',
          padding: 20,
          font: {
            size: 12,
            family: "'Inter', sans-serif"
          },
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return chart.data.labels.map((label, index) => {
              const value = datasets[0].data[index];
              const total = datasets[0].data.reduce((acc, curr) => acc + curr, 0);
              const percentage = ((value / total) * 100).toFixed(1);
              return {
                text: `${label} (${value}) - ${percentage}%`,
                fillStyle: datasets[0].backgroundColor[index],
                strokeStyle: datasets[0].borderColor[index],
                lineWidth: 2,
                hidden: false,
                index: index
              };
            });
          }
        }
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        titleColor: '#1F2937',
        bodyColor: '#1F2937',
        bodyFont: {
          size: 13,
          family: "'Inter', sans-serif"
        },
        titleFont: {
          size: 14,
          weight: 'bold',
          family: "'Inter', sans-serif"
        },
        padding: 12,
        boxPadding: 8,
        usePointStyle: true,
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: function(context) {
            const value = context.raw;
            const total = context.dataset.data.reduce((acc, curr) => acc + curr, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${value} projects (${percentage}%)`;
          }
        }
      }
    },
    elements: {
      arc: {
        borderWidth: 2,
        borderColor: '#ffffff',
        hoverBorderWidth: 4
      }
    },
    cutout: '60%',
    rotation: -90,
    circumference: 360,
  };

  const getStageColor = (project) => {
    // Default colors that can be used for any stage
    const colorSchemes = [
      'bg-blue-100 text-blue-800 border-blue-200',
      'bg-yellow-100 text-yellow-800 border-yellow-200',
      'bg-purple-100 text-purple-800 border-purple-200',
      'bg-orange-100 text-orange-800 border-orange-200',
      'bg-indigo-100 text-indigo-800 border-indigo-200',
      'bg-green-100 text-green-800 border-green-200',
    ];

    // Special cases
    if (project.currentStage === 'ISSUES') {
      return 'bg-red-100 text-red-800 border-red-200';
    }
    if (project.currentStage === 'COMPLETE') {
      return 'bg-green-100 text-green-800 border-green-200';
    }

    // Get the index of current stage in project's stages array
    const stageIndex = project.stages.indexOf(project.currentStage);
    // Use modulo to cycle through colors if we have more stages than colors
    return colorSchemes[stageIndex % colorSchemes.length] || 'bg-gray-100 text-gray-800 border-gray-200';
  };

  const needsAction = (project) => {
    // Check for uncompleted tasks - make sure to handle undefined/null tasks array
    const uncompletedTasks = project.tasks?.filter(task => task.completed === false) || [];
    const hasUncompletedTasks = uncompletedTasks.length > 0;
    
    // Check for ISSUES stage
    const hasIssues = project.currentStage === 'ISSUES';
    
    return hasUncompletedTasks || hasIssues;
  };

  const calculateTaskProgress = (project) => {
    // Get all tasks from all stages
    const allTasks = project.stages?.flatMap(stage => {
      // Get main tasks and their subtasks
      const tasks = stage.tasks || [];
      const allTasksWithSubtasks = tasks.flatMap(task => {
        const subtasks = task.subtasks || [];
        return [task, ...subtasks];
      });
      return allTasksWithSubtasks;
    }) || [];

    // Count total and completed tasks
    const totalTasks = allTasks.length;
    const completedTasks = allTasks.filter(task => task.completed).length;

    return {
      total: totalTasks,
      completed: completedTasks,
      percentage: totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0
    };
  };

  const getActionLabel = (project) => {
    if (project.currentStage === 'ISSUES') return 'Issues Reported';
    
    const taskProgress = calculateTaskProgress(project);
    if (taskProgress.total > 0) {
      return `${taskProgress.completed}/${taskProgress.total} Tasks Complete`;
    }
    
    return 'No Tasks';
  };

  const getTasksStatusDisplay = (project) => {
    const taskProgress = calculateTaskProgress(project);
    if (taskProgress.total === 0) return null;

    const uncompletedTasks = taskProgress.total - taskProgress.completed;
    if (uncompletedTasks > 0) {
      return (
        <div className="flex items-center gap-2 bg-red-50 text-red-700 px-3 py-2 rounded-md border border-red-200">
          {uncompletedTasks} {uncompletedTasks === 1 ? 'task' : 'tasks'} pending
        </div>
      );
    }

    return (
      <div className="flex items-center gap-2 bg-green-50 text-green-700 px-3 py-2 rounded-md border border-green-200">
        All tasks complete
      </div>
    );
  };

  const isCompleted = (project) => {
    return project?.currentStage === project.stages?.[project.stages.length - 1];
  };

  const getCardClassName = (project) => {
    const baseClass = "transition-shadow";
    return isCompleted(project) 
      ? `${baseClass} opacity-75 bg-gray-50` 
      : `${baseClass} hover:shadow-lg`;
  };

  const getProgressBarColor = (project) => {
    return isCompleted(project) ? 'bg-green-600' : 'bg-blue-600';
  };

  const getActionRequiredTasks = (project) => {
    const uncompletedTasks = project.tasks?.filter(task => task.completed === false) || [];
    return uncompletedTasks;
  };

  const calculateStageProgress = (project) => {
    if (!project.stages) return { total: 0, completed: 0, percentage: 0 };

    // Get all stages excluding ISSUES
    const stages = project.stages.filter(stage => stage.name !== 'ISSUES');
    
    const totalStages = stages.length;
    const completedStages = stages.filter(stage => stage.completed).length;

    return {
      total: totalStages,
      completed: completedStages,
      percentage: totalStages > 0 ? Math.round((completedStages / totalStages) * 100) : 0
    };
  };

  const calculateProgress = (project) => {
    const totalTasks = project.stages.reduce((acc, stage) => {
      return acc + stage.tasks.reduce((taskAcc, task) => {
        return taskAcc + 1 + (task.subtasks?.length || 0);
      }, 0);
    }, 0);

    const completedTasks = project.stages.reduce((acc, stage) => {
      return acc + stage.tasks.reduce((taskAcc, task) => {
        const completedSubtasks = task.subtasks?.filter(subtask => subtask.completed).length || 0;
        return taskAcc + (task.completed ? 1 : 0) + completedSubtasks;
      }, 0);
    }, 0);

    return totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;
  };

  const getStageStatusDisplay = (project) => {
    const stageProgress = calculateStageProgress(project);
    if (stageProgress.total === 0) return null;

    return (
      <div className="text-sm text-gray-600">
        {stageProgress.completed}/{stageProgress.total} Stages Complete
      </div>
    );
  };

  // Calculate budget statistics with proper null checks
  const calculateBudgetStats = () => {
    if (!projects?.length) return { 
      totalBudget: 0, 
      averageBudget: 0,
      budgetsByStatus: {
        draft: 0,
        submitted: 0,
        approved: 0,
        rejected: 0
      }
    };
    
    const budgetStats = projects.reduce((acc, project) => {
      const budget = project.budgetId;
      if (budget) {
        acc.totalBudget += budget.totalAmount || 0;
        const status = budget.status || 'draft';
        acc.budgetsByStatus[status] = (acc.budgetsByStatus[status] || 0) + 1;
      }
      return acc;
    }, { 
      totalBudget: 0, 
      budgetsByStatus: {
        draft: 0,
        submitted: 0,
        approved: 0,
        rejected: 0
      }
    });

    return {
      ...budgetStats,
      averageBudget: budgetStats.totalBudget / projects.length
    };
  };

  // Get chart data from budget statistics with proper null checks
  const chartData = (() => {
    const budgetStats = projects?.reduce((acc, project) => {
      const status = project.budgetId?.status || 'draft';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {
      draft: 0,
      submitted: 0,
      approved: 0,
      rejected: 0
    }) || {
      draft: 0,
      submitted: 0,
      approved: 0,
      rejected: 0
    };

    return {
      labels: ['Draft', 'Submitted', 'Approved', 'Rejected'],
      datasets: [{
        data: [
          budgetStats.draft || 0,
          budgetStats.submitted || 0,
          budgetStats.approved || 0,
          budgetStats.rejected || 0
        ],
        backgroundColor: ['#94A3B8', '#3B82F6', '#10B981', '#EF4444'],
        borderColor: ['#64748B', '#2563EB', '#059669', '#DC2626'],
        borderWidth: 1,
      }],
    };
  })();

  // Add budget statistics to the dashboard with null checks
  const budgetStats = calculateBudgetStats();

  // Update the getProjectBudget function
  const getProjectBudget = (project) => {
    // Check if budgetId exists and has totalAmount
    if (project.budgetId && typeof project.budgetId === 'object') {
      return project.budgetId.totalAmount ? 
        `$${project.budgetId.totalAmount.toLocaleString()}` : 
        'No budget set';
    }
    // If budgetId is just an ID or doesn't exist
    return 'No budget set';
  };

  // Enhanced KPI calculations based on actual data structure
  const calculateKPIs = () => {
    if (!projects?.length) return {
      totalProjects: 0,
      activeProjects: 0,
      delayedProjects: 0,
      totalBudget: 0,
      budgetUtilization: 0,
      riskProjects: 0
    };

    return projects.reduce((acc, project) => {
      // Update counts
      acc.totalProjects++;
      
      // Check if project is active (not in final stage)
      const isActive = project.stages.some(stage => stage.isCurrentStage);
      if (isActive) acc.activeProjects++;
      
      // Check for delays - using isProjectDelayed function
      if (isProjectDelayed(project)) {
        acc.delayedProjects++;
      }

      // Budget calculations
      if (project.budgetId) {
        acc.totalBudget += project.budgetId.totalAmount || 0;
        const completedStages = project.stages.filter(stage => stage.completed).length;
        const totalStages = project.stages.length;
        const estimatedUtilization = (completedStages / totalStages) * (project.budgetId.totalAmount || 0);
        acc.budgetUtilization += estimatedUtilization;
      }

      // Updated High Risk assessment:
      // 1. Has incomplete tasks in current stage
      // 2. Less than 5 days remaining (changed from more than)
      const currentStage = project.stages.find(stage => stage.isCurrentStage);
      const hasIncompleteTasks = currentStage?.tasks.some(task => !task.completed);
      
      if (hasIncompleteTasks && project.timeline?.estimatedEndDate) {
        const currentDate = new Date();
        const endDate = new Date(project.timeline.estimatedEndDate);
        const daysRemaining = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
        
        if (daysRemaining <= 5 && daysRemaining >= 0) {  // Changed condition
          acc.riskProjects++;
        }
      }

      return acc;
    }, {
      totalProjects: 0,
      activeProjects: 0,
      delayedProjects: 0,
      totalBudget: 0,
      budgetUtilization: 0,
      riskProjects: 0
    });
  };

  const kpis = calculateKPIs();

  // Add this helper function
  const calculateBudgetPerformance = (project) => {
    if (!project.budgetId?.totalAmount) return 0;

    // Calculate project progress percentage
    const totalStages = project.stages.length;
    const completedStages = project.stages.filter(stage => stage.completed).length;
    const progressPercentage = (completedStages / totalStages);

    // Expected budget at this stage
    const expectedBudget = project.budgetId.totalAmount * progressPercentage;

    // Calculate actual costs from budget components
    const actualCosts = 
      (project.budgetId.materialCosts || 0) +
      (project.budgetId.officeOverhead || 0) +
      (project.budgetId.permitCosts || 0) +
      (project.budgetId.draftsmanPay || 0) +
      (project.budgetId.installerPay || 0) +
      (project.budgetId.salesmenPay || 0);

    // Calculate variance percentage
    const variance = ((actualCosts - expectedBudget) / expectedBudget) * 100;
    
    return Number(variance.toFixed(1));
  };

  // Update the budget performance chart
  const budgetPerformanceChart = {
    labels: projects
      .filter(p => p.stages.some(s => s.completed)) // Only include projects with progress
      .slice(0, 5)
      .map(p => p.name),
    datasets: [{
      label: 'Budget Variance (%)',
      data: projects
        .filter(p => p.stages.some(s => s.completed))
        .slice(0, 5)
        .map(p => calculateBudgetPerformance(p)),
      backgroundColor: (context) => {
        const value = context.raw;
        return value > 10 ? 'rgba(248, 113, 113, 0.8)' :   // Red-400 for over budget
               value < -10 ? 'rgba(74, 222, 128, 0.8)' :   // Green-400 for under budget
               'rgba(250, 204, 21, 0.8)';                  // Yellow-400 for on budget
      }
    }]
  };

  // Add this helper function at the top
  const calculateTotalDuration = (project) => {
    return project.stages.reduce((total, stage) => {
      return total + stage.tasks.reduce((stageTotal, task) => {
        return stageTotal + task.subtasks.reduce((taskTotal, subtask) => {
          return taskTotal + (subtask.duration || 0);
        }, 0);
      }, 0);
    }, 0);
  };

  const filteredProjects = projects.filter(project => {
    if (filterStatus !== 'all' && project.status !== filterStatus) {
      return false;
    }
    
    if (filters.search) {
      const searchTerm = filters.search.toLowerCase();
      const projectName = project.name.toLowerCase();
      const assignees = project.stages.flatMap(stage => 
        stage.tasks.flatMap(task => 
          task.subtasks.flatMap(subtask => 
            subtask.assignees?.map(assignee => 
              `${assignee.firstName} ${assignee.lastName}`.toLowerCase()
            ) || []
          )
        )
      );
      return projectName.includes(searchTerm) || assignees.some(assignee => assignee.includes(searchTerm));
    }
    return true;
  });

  const sortedProjects = [...filteredProjects].sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
  });

  const paginatedProjects = (() => {
    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    return sortedProjects.slice(indexOfFirstProject, indexOfLastProject);
  })();

  const totalPages = Math.ceil(sortedProjects.length / projectsPerPage);

  const PaginationControls = () => {
    return (
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 mt-4 rounded-lg">
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{((currentPage - 1) * projectsPerPage) + 1}</span> to{' '}
              <span className="font-medium">
                {Math.min(currentPage * projectsPerPage, sortedProjects.length)}
              </span> of{' '}
              <span className="font-medium">{sortedProjects.length}</span> projects
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span className="sr-only">Previous</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                </svg>
              </button>
              {[...Array(totalPages)].map((_, idx) => (
                <button
                  key={idx}
                  onClick={() => setCurrentPage(idx + 1)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    currentPage === idx + 1
                      ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                      : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0'
                  }`}
                >
                  {idx + 1}
                </button>
              ))}
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span className="sr-only">Next</span>
                <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                </svg>
              </button>
            </nav>
          </div>
        </div>
      </div>
    );
  };

  // Update the getTimeRemaining function to include urgency levels
  const getTimeRemaining = (endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    const diffTime = end - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays < 0) {
      return {
        text: `${Math.abs(diffDays)} days overdue`,
        className: 'bg-red-50 text-red-700 border border-red-100',
        icon: (
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        )
      };
    } else if (diffDays <= 3) {
      return {
        text: `${diffDays} days remaining`,
        className: 'bg-red-50 text-red-700 border border-red-100',
        icon: (
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        )
      };
    } else if (diffDays <= 7) {
      return {
        text: `${diffDays} days remaining`,
        className: 'bg-amber-50 text-amber-700 border border-amber-100',
        icon: (
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        )
      };
    } else {
      return {
        text: `${diffDays} days remaining`,
        className: 'bg-sky-50 text-sky-700 border border-sky-100',
        icon: (
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        )
      };
    }
  };

  // Add this function to calculate Work Order priority distribution
  const workOrderPriorityChart = {
    labels: ['Low', 'Medium', 'High', 'Urgent'],
    datasets: [{
      data: [
        workOrders.filter(wo => wo.priority === 'low').length,
        workOrders.filter(wo => wo.priority === 'medium').length,
        workOrders.filter(wo => wo.priority === 'high').length,
        workOrders.filter(wo => wo.priority === 'urgent').length
      ],
      backgroundColor: [
        'rgba(74, 222, 128, 0.8)',   // Green-400
        'rgba(250, 204, 21, 0.8)',   // Yellow-400
        'rgba(249, 115, 22, 0.8)',   // Orange-500
        'rgba(239, 68, 68, 0.8)'     // Red-500
      ],
      borderColor: [
        'rgba(74, 222, 128, 1)',
        'rgba(250, 204, 21, 1)',
        'rgba(249, 115, 22, 1)',
        'rgba(239, 68, 68, 1)'
      ],
      borderWidth: 1
    }]
  };

  // Add this helper function near the other calculation functions
  const getUpcomingDeadlines = () => {
    if (!workOrders?.length) return [];
    
    const today = new Date();
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(today.getDate() + 7);
    
    return workOrders
      .filter(wo => {
        if (!wo.endDate || wo.status === 'completed') return false;
        const endDate = new Date(wo.endDate);
        return endDate > today && endDate <= sevenDaysFromNow;
      })
      .sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
  };

  return (
    <div className="container mx-auto px-4 py-6">
      {/* KPI Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <Card>
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600">Active Projects</p>
                <h3 className="text-2xl font-bold text-blue-600">{kpis.activeProjects}</h3>
              </div>
              <div className="p-3 bg-blue-50 rounded-full">
                <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-xs text-gray-500">
                {((kpis.activeProjects / kpis.totalProjects) * 100).toFixed(1)}% of total projects
              </p>
            </div>
          </div>
        </Card>

        <Card>
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600">Budget Utilization</p>
                <h3 className="text-2xl font-bold text-sky-600">
                  ${kpis.budgetUtilization.toLocaleString()}
                </h3>
              </div>
              <div className="p-3 bg-sky-50 rounded-full">
                <svg className="w-6 h-6 text-sky-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-xs text-gray-500">
                {((kpis.budgetUtilization / kpis.totalBudget) * 100).toFixed(1)}% of total budget
              </p>
            </div>
          </div>
        </Card>

        <Card>
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600">High Risk Projects</p>
                <h3 className="text-2xl font-bold text-amber-600">{kpis.riskProjects}</h3>
              </div>
              <div className="p-3 bg-amber-50 rounded-full">
                <svg className="w-6 h-6 text-amber-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-xs text-gray-500">
                {((kpis.riskProjects / kpis.totalProjects) * 100).toFixed(1)}% of total projects
              </p>
            </div>
          </div>
        </Card>

        <Card>
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600">Delayed Projects</p>
                <h3 className="text-2xl font-bold text-red-600">{kpis.delayedProjects}</h3>
              </div>
              <div className="p-3 bg-red-50 rounded-full">
                <svg className="w-6 h-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-xs text-gray-500">
                {((kpis.delayedProjects / kpis.totalProjects) * 100).toFixed(1)}% of total projects
              </p>
            </div>
          </div>
        </Card>
      </div>

      {/* Main Content Section - Projects and Charts side by side */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Projects Section - Left Side */}
        <div className="lg:w-[60%]">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Pombo's Contracting Projects</h2>
            <div className="flex items-center gap-3">
              {/* Filter Dropdown */}
              <div className="relative">
                <select
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="appearance-none w-36 px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="all">All Projects</option>
                  <option value="pending">Pending</option>
                  <option value="sold">Sold</option>
                  <option value="pre-construction">Pre-Construction</option>
                  <option value="permitting">Permitting</option>
                  <option value="construction">Construction</option>
                  <option value="done">Done</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>

              {/* Existing Sort Button */}
              <button
                onClick={() => setSortOrder(prev => prev === 'desc' ? 'asc' : 'desc')}
                className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                {sortOrder === 'desc' ? 'Newest First' : 'Oldest First'}
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {sortedProjects.length > 0 ? (
              paginatedProjects.map((project) => (
                <div 
                  key={project._id} 
                  onClick={() => navigate(`/projects/${project._id}`)}
                  className="cursor-pointer"
                >
                  <Card className={`hover:shadow-lg transition-shadow bg-white border-l-4 border-y border-r border-gray-100 ${
                    calculateProgress(project) === 100
                      ? 'border-l-green-500'
                      : project.timeline?.estimatedEndDate 
                        ? (() => {
                            const timeStatus = getTimeRemaining(project.timeline.estimatedEndDate);
                            if (timeStatus.text.includes('overdue')) return 'border-l-red-500';
                            if (parseInt(timeStatus.text) <= 3) return 'border-l-red-500';
                            if (parseInt(timeStatus.text) <= 7) return 'border-l-amber-500';
                            return 'border-l-sky-500';
                          })()
                        : 'border-l-slate-500'
                  }`}>
                    <div className="p-4">
                      <div className="flex justify-between items-start gap-4 mb-3">
                        <div className="flex-1 min-w-0">
                          <div className="flex items-center gap-2">
                            <h3 className="text-base font-medium text-gray-900 truncate">{project.name}</h3>
                            {needsAction(project) && (
                              <span className="text-xs bg-amber-50 text-amber-700 border border-amber-100 px-1.5 py-0.5 rounded">
                                Action Required
                              </span>
                            )}
                          </div>
                          <div className="flex items-center gap-2 mt-1">
                            <span className={`text-xs font-medium px-1.5 py-0.5 rounded ${getStatusClass(project)}`}>
                              Status: {project.status.charAt(0).toUpperCase() + project.status.slice(1).replace('-', ' ')}
                            </span>
                            <span className={`text-xs font-medium px-1.5 py-0.5 rounded bg-blue-50 text-blue-700 border border-blue-100`}>
                              Duration: {calculateTotalDuration(project)} days
                            </span>
                          </div>
                        </div>
                        <div className="text-right flex flex-col items-end gap-1">
                          <span className="text-sm font-medium text-gray-900">
                            ${project.budgetId?.totalAmount?.toLocaleString() || '0'}
                          </span>
                          {project.budgetId?.status && (
                            <span className={`text-xs px-1.5 py-0.5 rounded ${
                              project.budgetId.status === 'approved' ? 'bg-sky-50 text-sky-700 border border-sky-100' :
                              project.budgetId.status === 'rejected' ? 'bg-red-50 text-red-700 border border-red-100' :
                              project.budgetId.status === 'submitted' ? 'bg-blue-50 text-blue-700 border border-blue-100' :
                              'bg-gray-50 text-gray-700 border border-gray-100'
                            }`}>
                              Budget: {project.budgetId.status.charAt(0).toUpperCase() + project.budgetId.status.slice(1)}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="mt-2 space-y-1">
                        {project.stages.find(stage => stage.isCurrentStage)?.tasks.map((task, index) => (
                          <div key={index} className="bg-slate-50 rounded p-2 border border-slate-200">
                            {/* Main task */}
                            <div className="flex items-center justify-between">
                              <div className="flex items-center gap-2">
                                {task.completed ? (
                                  <svg className="w-3.5 h-3.5 text-emerald-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                  </svg>
                                ) : (
                                  <svg className="w-3.5 h-3.5 text-slate-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                  </svg>
                                )}
                                <span className={`text-xs font-medium ${task.completed ? 'text-slate-600' : 'text-slate-900'}`}>
                                  {task.name}
                                </span>
                              </div>
                              <span className={`text-xs px-1.5 py-0.5 rounded ${
                                task.completed 
                                  ? 'bg-emerald-50 text-emerald-700 border border-emerald-100' 
                                  : 'bg-amber-50 text-amber-700 border border-amber-100'
                              }`}>
                                {task.completed ? 'Complete' : 'In Progress'}
                              </span>
                            </div>

                            {/* Subtasks */}
                            {task.subtasks?.length > 0 && (
                              <div className="mt-1 ml-4 pl-2 border-l border-slate-200 space-y-1">
                                {task.subtasks.map((subtask, idx) => (
                                  <div key={idx} className="flex items-center justify-between py-0.5">
                                    <div className="flex items-center gap-2">
                                      <div className="relative">
                                        <div className="absolute top-1/2 -left-2 w-2 h-px bg-slate-200"></div>
                                        <div className={`w-2 h-2 rounded-full ${
                                          subtask.completed ? 'bg-emerald-500' : 'bg-slate-300'
                                        }`}></div>
                                      </div>
                                      <div className="flex flex-col">
                                        <span className={`text-xs ${
                                          subtask.completed ? 'text-slate-500' : 'text-slate-700'
                                        }`}>
                                          {subtask.name}
                                          <span className="ml-2 text-xs text-slate-400">
                                            ({subtask.duration} {subtask.duration === 1 ? 'day' : 'days'})
                                          </span>
                                        </span>
                                        {subtask.assignees && subtask.assignees.length > 0 && (
                                          <div className="flex flex-wrap gap-1 mt-0.5">
                                            {subtask.assignees.map((assignee, assigneeIdx) => (
                                              <span
                                                key={assigneeIdx}
                                                className="inline-flex items-center px-1.5 py-0.5 rounded-full text-[10px] font-medium bg-blue-50 text-blue-700 border border-blue-100"
                                              >
                                                {assignee.firstName} {assignee.lastName}
                                              </span>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <span className={`text-[10px] px-1.5 py-0.5 rounded ${
                                      subtask.completed 
                                        ? 'bg-emerald-50 text-emerald-600 border border-emerald-100' 
                                        : 'bg-slate-50 text-slate-600 border border-slate-200'
                                    }`}>
                                      {subtask.completed ? 'Done' : 'Todo'}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>

                      <div className="mt-3">
                        <div className="flex justify-between items-center mb-1">
                          <div className="flex items-center gap-3 text-xs text-gray-600">
                            <span>Progress: {calculateProgress(project)}%</span>
                            <span>•</span>
                            <span>Stages: {calculateStageProgress(project).completed}/{calculateStageProgress(project).total}</span>
                          </div>
                          {project.tags?.length > 0 && (
                            <div className="flex gap-1">
                              {project.tags.map((tag, index) => (
                                <span 
                                  key={index}
                                  className="bg-purple-50 text-purple-700 border border-purple-100 px-1.5 py-0.5 rounded text-xs"
                                >
                                  {tag}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="w-full bg-gray-100 rounded-full h-1.5">
                          <div
                            className={`h-1.5 rounded-full transition-all duration-300 ${
                              calculateProgress(project) === 100 
                                ? 'bg-emerald-500'  // 100% complete - Green
                                : calculateProgress(project) > 75 
                                  ? 'bg-blue-500'   // >75% complete - Blue
                                  : calculateProgress(project) >= 25 
                                    ? 'bg-blue-500'  // ≥25% complete - Blue
                                    : 'bg-slate-400' // <25% complete - Slate (changed from yellow-300)
                            }`}
                            style={{ width: `${calculateProgress(project)}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              ))
            ) : (
              <div className="text-center py-8">
                <p className="text-gray-500">No projects found matching the selected filter.</p>
              </div>
            )}
          </div>
          <PaginationControls />
        </div>

        {/* Charts Section - Right Side */}
        <div className="lg:w-[40%]">
          <div className="space-y-6 lg:sticky lg:top-4">
            {/* Project Status Chart */}
            <Card>
              <div className="h-[400px]">
                <Pie 
                  data={projectStatusChart} 
                  options={{
                    ...chartOptions,
                    layout: {
                      padding: 0
                    },
                    plugins: {
                      ...chartOptions.plugins,
                      legend: {
                        ...chartOptions.plugins.legend,
                        position: 'bottom',
                        labels: {
                          ...chartOptions.plugins.legend.labels,
                          padding: 15,
                          boxWidth: 15,
                          font: {
                            size: 11,
                            family: "'Inter', sans-serif"
                          }
                        }
                      },
                      title: {
                        ...chartOptions.plugins.title,
                        padding: {
                          top: 5,
                          bottom: 15
                        }
                      }
                    }
                  }}
                />
              </div>
            </Card>

            {/* Budget Performance Chart */}
            <Card>
              <div className="p-4">
                <h3 className="text-lg font-semibold mb-4">Budget Performance (Top 5)</h3>
                <div className="h-[300px]">
                  <Bar 
                    data={budgetPerformanceChart}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: 'Budget Variance (%)'
                          }
                        }
                      },
                      plugins: {
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              const value = context.raw;
                              return value > 0 
                                ? `Over budget by ${value}%`
                                : value < 0
                                  ? `Under budget by ${Math.abs(value)}%`
                                  : 'On budget';
                            }
                          }
                        }
                      }
                    }}
                  />
                </div>
                <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                  <h4 className="font-medium text-gray-900 mb-2">Understanding Budget Performance</h4>
                  <ul className="space-y-2 text-sm text-gray-600">
                    <li className="flex items-center gap-2">
                      <span className="w-3 h-3 rounded-full bg-red-500"></span>
                      <span>Red bars indicate project is over budget ({`>`}10% variance)</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="w-3 h-3 rounded-full bg-yellow-500"></span>
                      <span>Yellow bars indicate project is near budget (±10% variance)</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="w-3 h-3 rounded-full bg-green-500"></span>
                      <span>Green bars indicate project is under budget ({`>`}10% savings)</span>
                    </li>
                  </ul>
                  <p className="mt-3 text-sm text-gray-600">
                    Budget variance is calculated by comparing actual costs against expected costs based on project progress. 
                    A positive percentage indicates overspending, while a negative percentage indicates cost savings.
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      {/* Work Order Metrics Section */}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mb-6">
        <Card className="md:col-span-3">
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600">Active Work Orders</p>
                <h3 className="text-2xl font-bold text-blue-600">{workOrderKPIs.activeWorkOrders}</h3>
              </div>
              <div className="p-3 bg-blue-50 rounded-full">
                <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-xs text-gray-500">
                {((workOrderKPIs.activeWorkOrders / workOrderKPIs.totalWorkOrders) * 100).toFixed(1)}% of total work orders
              </p>
            </div>
          </div>
        </Card>

        <Card className="md:col-span-6">
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <div>
                <p className="text-sm text-gray-600">Upcoming Deadlines</p>
                <h3 className="text-2xl font-bold text-amber-600">
                  {getUpcomingDeadlines().length}
                </h3>
              </div>
              <div className="p-3 bg-amber-50 rounded-full">
                <svg className="w-6 h-6 text-amber-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            
            <div className="space-y-3">
              {getUpcomingDeadlines().slice(0, 4).map((wo) => {
                console.log('Work Order Data:', wo); // This will help us see the actual structure
                const daysLeft = Math.ceil((new Date(wo.endDate) - new Date()) / (1000 * 60 * 60 * 24));
                return (
                  <div 
                    key={wo._id}
                    onClick={() => navigate(`/work-orders/${wo._id}`)}
                    className="flex flex-col p-3 bg-amber-50 rounded-lg border border-amber-100 cursor-pointer hover:shadow-md transition-shadow duration-200 hover:border-amber-200"
                  >
                    <div className="flex items-start justify-between mb-2">
                      <div className="min-w-0 flex-1">
                        <div className="flex items-center gap-2">
                          <p className="text-base font-medium text-amber-900 truncate">
                            {wo.client?.name || 'No client'}
                          </p>
                          <svg 
                            className="w-4 h-4 text-amber-600 opacity-50" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                          </svg>
                        </div>
                        <div className="flex items-center gap-2 mt-1">
                          <span className="text-amber-700 text-sm">
                            {daysLeft} {daysLeft === 1 ? 'day' : 'days'} remaining
                          </span>
                        </div>
                      </div>
                      <span className={`text-xs px-2.5 py-1.5 rounded-full whitespace-nowrap ml-2 ${
                        daysLeft <= 2 
                          ? 'bg-red-100 text-red-700' 
                          : daysLeft <= 4
                            ? 'bg-amber-100 text-amber-700'
                            : 'bg-yellow-100 text-yellow-700'
                      }`}>
                        {wo.priority}
                      </span>
                    </div>
                    
                    {/* Assigned Team Members */}
                    <div className="mt-2 flex flex-wrap gap-1">
                      {wo.assignedTo ? (
                        <div
                          key={wo.assignedTo._id}
                          className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium bg-white text-gray-700 border border-gray-200"
                        >
                          {wo.assignedTo.avatar && (
                            <img
                              src={wo.assignedTo.avatar}
                              alt={`${wo.assignedTo.firstName} ${wo.assignedTo.lastName}`}
                              className="w-4 h-4 rounded-full"
                            />
                          )}
                          <span>{wo.assignedTo.firstName} {wo.assignedTo.lastName}</span>
                        </div>
                      ) : (
                        <span className="text-xs text-gray-500">No team members assigned</span>
                      )}
                    </div>
                    
                    {/* Status Badge */}
                    <div className="mt-2 flex items-center gap-2">
                      <span className={`text-xs px-2 py-1 rounded-full ${
                        wo.status === 'completed' 
                          ? 'bg-green-100 text-green-700'
                          : wo.status === 'in_progress'
                            ? 'bg-blue-100 text-blue-700'
                            : 'bg-gray-100 text-gray-700'
                      }`}>
                        {wo.status?.replace('_', ' ').charAt(0).toUpperCase() + wo.status?.slice(1).replace('_', ' ')}
                      </span>
                      {wo.type && (
                        <span className="text-xs px-2 py-1 rounded-full bg-purple-100 text-purple-700">
                          {wo.type}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
              {getUpcomingDeadlines().length > 4 && (
                <p className="text-sm text-gray-500 text-center pt-1">
                  +{getUpcomingDeadlines().length - 4} more upcoming deadlines
                </p>
              )}
            </div>
          </div>
        </Card>

        <Card className="md:col-span-3">
          {/* Other card content */}
        </Card>
      </div>

      {/* Work Order Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        <Card>
          <div className="h-[300px]">
            <Pie 
              data={workOrderStatusChart}
              options={{
                ...chartOptions,
                plugins: {
                  ...chartOptions.plugins,
                  title: {
                    ...chartOptions.plugins.title,
                    text: 'Work Order Status Distribution'
                  }
                }
              }}
            />
          </div>
        </Card>

        <Card>
          <div className="h-[300px]">
            <Pie 
              data={workOrderPriorityChart}
              options={{
                ...chartOptions,
                plugins: {
                  ...chartOptions.plugins,
                  title: {
                    ...chartOptions.plugins.title,
                    text: 'Work Order Priority Distribution'
                  }
                }
              }}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
