import { Button } from 'components/catalyst/button';
import { Dialog, DialogActions, DialogBody } from 'components/catalyst/dialog';
import wording from 'utils/wording';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Strong, Text } from 'components/catalyst/text';
import LoadingButton from 'components/common/LoadingButton';

const DeleteDocumentDialog = ({
  showDeleteDocumentModal,
  handleCloseDeleteDocumentModal,
  isDeletingDocument,
  handleDelete,
  documentToDelete,
}) => {
  const {
    DOCUMENTS_DELETE,
    DOCUMENTS_CONFIRM_DELETE,
    GLOBAL_DELETE,
    GLOBAL_DELETING,
    GLOBAL_CANCEL,
  } = wording;

  return (
    <Dialog open={showDeleteDocumentModal} onClose={handleCloseDeleteDocumentModal}>
      <div className='flex items-center justify-between'>
        <Strong>
          {DOCUMENTS_DELETE}
        </Strong>

        <Button
          plain
          onClick={handleCloseDeleteDocumentModal}
        >
          <XMarkIcon />
        </Button>
      </div>

      <DialogBody>
        <Text>
          {DOCUMENTS_CONFIRM_DELETE}
        </Text>

        <Text className='pt-1'>
          <Strong>
            {documentToDelete?.name}
          </Strong>
        </Text>
      </DialogBody>
      
      <DialogActions>
        <Button onClick={handleCloseDeleteDocumentModal} outline>
          {GLOBAL_CANCEL}
        </Button>

        <LoadingButton
          onClick={handleDelete}
          disabled={isDeletingDocument}
          isLoading={isDeletingDocument}
          label={GLOBAL_DELETE}
          loadingLabel={GLOBAL_DELETING}
          color='rose'
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDocumentDialog;
