export const API_URL = process.env.REACT_APP_API_URL;

// Helper function to construct API endpoints
export const endpoints = {
  auth: {
    base: '/api/users',
    login: '/api/users/login',
    forgot: '/api/users/forgot-password',
    reset: '/api/users/reset-password',
    change: '/api/users/change-password',
  },
  projects: {
    base: '/api/projects',
    documents: (projectId) => `/api/documents/${projectId}`,
  },
  workOrders: {
    base: '/api/workorders',
    workOrder: (workOrderId) => `/api/workorders/${workOrderId}`,
    status: (workOrderId) => `/api/workorders/${workOrderId}/status`,
    messages: (workOrderId) => `/api/workorders/${workOrderId}/messages`,
    assignProject: (workOrderId) => `/api/workorders/${workOrderId}/assign-project`,
    assignUser: (workOrderId) => `/api/workorders/${workOrderId}/assign-user`,
    documents: (workOrderId) => `/api/workorders/${workOrderId}/documents`,
    logs: (workOrderId) => `/api/workorders/${workOrderId}/logs`,
  },
  templates: {
    base: '/api/templates',
  },
  companies: {
    base: '/api/companies',
    documents: '/api/company-documents',
    documentsDelete: (documentId) => `/api/company-documents/${documentId}`,
    documentsUrl: (documentId) => `/api/company-documents/${documentId}/url`,
  },
  users: {
    base: '/api/users',
    company: '/api/users/company',
    search: (query) => `/api/users/search?q=${query}`,
    asAdmin: (userId) => `/api/users/update-user-as-admin/${userId}`
  },
  budgets: {
    base: '/api/budgets',
    details: (budgetId) => `/api/budgets/${budgetId}`,
    update: (budgetId) => `/api/budgets/${budgetId}`,
    status: (budgetId) => `/api/budgets/${budgetId}/status`,
    history: (budgetId) => `/api/budgets/${budgetId}/history`,
  },
};