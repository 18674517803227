// UI
import { Button } from 'components/catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from 'components/catalyst/dialog';
import { Text } from 'components/catalyst/text';

// Utils
import wording from 'utils/wording';

const WODeleteModal = ({
  showDeleteWOModal,
  handleDeleteWorkOrder,
  handleCloseDeleteWOModal,
  workOrder,
}) => {
  const {
    WO_DETAIL_MANAGE_DELETE_CONFIRM,
    WO_DETAIL_MANAGE_DELETE,
    GLOBAL_CANCEL,
  } = wording;

  return (
    <Dialog open={showDeleteWOModal} onClose={handleCloseDeleteWOModal}>
      <DialogTitle>{WO_DETAIL_MANAGE_DELETE}</DialogTitle>

      <DialogBody>
        <Text>
          {WO_DETAIL_MANAGE_DELETE_CONFIRM}
        </Text>
      </DialogBody>
      
      <DialogActions>
        <Button
          color='white'
          onClick={handleCloseDeleteWOModal}
          className='cursor-pointer'
        >
          {GLOBAL_CANCEL}
        </Button>

        <Button
          onClick={() => handleDeleteWorkOrder(workOrder?._id)}
          color='rose'
          className='cursor-pointer'
        >
          {WO_DETAIL_MANAGE_DELETE}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WODeleteModal;
