// UI
import { Button } from 'components/catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from 'components/catalyst/dialog';
import LoadingButton from 'components/common/LoadingButton';
import { Field, FieldGroup, Fieldset, Label } from 'components/catalyst/fieldset';
import { Input } from 'components/catalyst/input';
import { Textarea } from 'components/catalyst/textarea';
import { XMarkIcon } from '@heroicons/react/24/outline';

// Utils
import wording from 'utils/wording';

const UploadDocumentDialog = ({
  showUploadDialog,
  handleCloseUploadModal,
  isUploadingDocument,
  register,
  handleSubmit,
  onSubmit,
  isValid,
}) => {
  const {
    DOCUMENTS_UPLOAD,
    WO_DETAIL_FILE_DESCRIPTION,
    GLOBAL_CANCEL,
    WO_DETAIL_FILE,
    GLOBAL_UPLOAD,
    GLOBAL_UPLOADING,
  } = wording;

  return (
    <Dialog open={showUploadDialog} onClose={handleCloseUploadModal}>
      <DialogTitle>
        <div className='flex items-center justify-between'>
          {DOCUMENTS_UPLOAD}

          <Button
            plain
            onClick={handleCloseUploadModal}
          >
            <XMarkIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogBody>
        <form>
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label>{WO_DETAIL_FILE}</Label>

                <Input
                  type="file"
                  {...register('files', {
                    required: true,
                  })}
                />
              </Field>
            </FieldGroup>

            <FieldGroup>
              <Field>
                <Label>{WO_DETAIL_FILE_DESCRIPTION}</Label>

                <Textarea
                  name="description"
                  {...register('description')}
                  rows={3}
                />
              </Field>
            </FieldGroup>
          </Fieldset>
        </form>
      </DialogBody>
      
      <DialogActions>
        <Button onClick={handleCloseUploadModal} outline>
          {GLOBAL_CANCEL}
        </Button>

        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          disabled={isUploadingDocument || !isValid}
          isLoading={isUploadingDocument}
          label={GLOBAL_UPLOAD}
          loadingLabel={GLOBAL_UPLOADING}
          color='green'
        />
      </DialogActions>
    </Dialog>
  );
};

export default UploadDocumentDialog;
