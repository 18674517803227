import { endpoints } from 'config/api';
import { axiosInstance, axiosPublicInstance } from 'config/axios';

// Login user
const login = async (userData) => {
  const response = await axiosPublicInstance.post(endpoints.auth.login, userData);

  if (response.data) {
    localStorage.setItem('nimax_user', JSON.stringify(response.data));
    localStorage.setItem('nimax_auth_token', response.data.token);
  }

  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem('nimax_user');
  localStorage.removeItem('nimax_auth_token');
};

const changePassword = async (currentPassword, newPassword) => {
  await axiosInstance.put(
    endpoints.auth.change, 
    {
      currentPassword,
      newPassword,
    },
  );
};

const forgotPassword = async (email) => {
  await axiosPublicInstance.put(
    endpoints.auth.forgot, 
    {
      email,
    },
  );
};

const resetPassword = async (token, password) => {
  await axiosPublicInstance.put(
    endpoints.auth.reset, 
    {
      token,
      password,
    },
  );
};

const authService = {
  login,
  logout,
  changePassword,
  forgotPassword,
  resetPassword,
};

export default authService;
