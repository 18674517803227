// Module dependencies
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";

// UI
import { Heading } from "components/catalyst/heading";
import { PlusIcon, MapIcon, DocumentTextIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Button } from "components/catalyst/button";

// Utils
import wording from "utils/wording";

const ProjectsViewHeader = ({ 
  filteredProjects,
  isSelectionMode,
  setIsSelectionMode,
  selectedProjects,
  setIsMapOpen,
  exportAllProjectsBudgets,
  handleSelectAllProjects,
}) => {
  const navigate = useNavigate();

  const {
    PROJECTS_TITLE,
    PROJECTS_CANCEL_SELECTION,
    PROJECTS_SELECT_PROJECTS,
    PROJECTS_SELECT_ALL,
    PROJECTS_DESELECT_ALL,
    PROJECTS_EXPORT_SELECTED,
    PROJECTS_EXPORT_ALL,
    PROJECTS_VIEW_MAP,
    PROJECTS_NEW_PROJECT,
  } = wording;

  const buttonsList = [
    {
      id: 'toggle-selection',
      label: () => (isSelectionMode ? PROJECTS_CANCEL_SELECTION : PROJECTS_SELECT_PROJECTS),
      onClick: () => setIsSelectionMode(!isSelectionMode),
      icon: <CheckIcon />,
      outline: true,
    },
    {
      id: 'select-all',
      label: () =>
        selectedProjects.size === filteredProjects.length ? PROJECTS_DESELECT_ALL : PROJECTS_SELECT_ALL,
      onClick: handleSelectAllProjects,
      icon: null,
      renderIf: isSelectionMode,
      outline: true,
    },
    {
      id: 'export-projects',
      label: () => isSelectionMode
        ? PROJECTS_EXPORT_SELECTED(selectedProjects.size)
        : PROJECTS_EXPORT_ALL,
      onClick: exportAllProjectsBudgets,
      icon: <DocumentTextIcon />,
      outline: true,
    },
    {
      id: 'view-map',
      label: PROJECTS_VIEW_MAP,
      onClick: () => setIsMapOpen(true),
      icon: <MapIcon />,
      outline: true,
    },
    {
      id: 'new-project',
      label: PROJECTS_NEW_PROJECT,
      onClick: () => navigate('/projects/new'),
      icon: <PlusIcon />,
      outline: false,
    },
  ];

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
      <Heading>
        {PROJECTS_TITLE}
      </Heading>

      <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-3">
        {buttonsList.map(({ renderIf, id, onClick, outline, label, icon }) => (
          <Fragment key={id}>
            {(renderIf === undefined || renderIf) && (
              <Button
                onClick={onClick}
                className="flex items-center justify-center gap-2 w-full sm:w-auto cursor-pointer"
                outline={outline}
              >
                {icon}
                {typeof label === 'function' ? label() : label}
              </Button>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default ProjectsViewHeader;
