import { axiosInstance } from 'config/axios';
import { endpoints } from '../../config/api';

const getTemplates = async () => {
  const response = await axiosInstance.get(endpoints.templates.base);
  return response.data;
};

const createTemplate = async (templateData) => {
  const validateSubtasks = (stages) => {
    for (const stage of stages) {
      for (const task of stage.tasks) {
        for (const subtask of (task.subtasks || [])) {
          if (!subtask.duration || subtask.duration < 0.5) {
            throw new Error('All subtasks must have a valid duration (minimum 0.5 days)');
          }
          if (subtask.estimatedBudget === undefined || subtask.estimatedBudget < 0) {
            throw new Error('All subtasks must have a valid estimated budget (minimum 0)');
          }
          subtask.estimatedBudget = Number(subtask.estimatedBudget);
        }
      }
    }
  };

  try {
    validateSubtasks(templateData.stages);
    
    const response = await axiosInstance.post(endpoints.templates.base, templateData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to create template');
  }
};

const updateTemplate = async (templateData) => {
  const validateSubtasks = (stages) => {
    for (const stage of stages) {
      for (const task of stage.tasks) {
        for (const subtask of (task.subtasks || [])) {
          if (!subtask.duration || subtask.duration < 0.5) {
            throw new Error('All subtasks must have a valid duration (minimum 0.5 days)');
          }
          if (subtask.estimatedBudget === undefined || subtask.estimatedBudget < 0) {
            throw new Error('All subtasks must have a valid estimated budget (minimum 0)');
          }
          subtask.estimatedBudget = Number(subtask.estimatedBudget);
        }
      }
    }
  };

  if (!templateData._id) {
    throw new Error('Template ID is required');
  }

  try {
    validateSubtasks(templateData.stages);

    const response = await axiosInstance.put(
      `${endpoints.templates.base}/${templateData._id}`,
      templateData,
    );

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Failed to update template');
  }
};

const templateService = {
  getTemplates,
  createTemplate,
  updateTemplate
};

export default templateService;
