import wording from "utils/wording";

const {
  CREATE_WO_WHITE,
  CREATE_WO_BRONZE,
  CREATE_WO_SCREEN_TYPE_REGULAR,
  CREATE_WO_SCREEN_TYPE_NO_SEE_UMS,
  CREATE_WO_SCREEN_TYPE_SUPER_17X14,
  CREATE_WO_SCREEN_TYPE_SUPER_17X20,
  CREATE_WO_INSPECTION_LOW,
  CREATE_WO_INSPECTION_MEDIUM,
  CREATE_WO_INSPECTION_HIGH,
  CREATE_WO_AREA_GUTTER,
  CREATE_WO_AREA_SCREEN,
  CREATE_WO_AREA_ALUMINUM,
  CREATE_WO_AREA_PANELS,
  CREATE_WO_AREA_CONCRETE,
  CREATE_WO_AREA_DOOR,
  CREATE_WO_AREA_SCREWS,
  GLOBAL_DASH,
} = wording;

const colorOptions = [
  {
    label: GLOBAL_DASH,
    value: "",
  },
  {
    label: CREATE_WO_WHITE,
    value: "White",
  },
  {
    label: CREATE_WO_BRONZE,
    value: "Bronze",
  },
];

const screenTypeOptions = [
  {
    label: GLOBAL_DASH,
    value: "",
  },
  {
    label: CREATE_WO_SCREEN_TYPE_REGULAR,
    value: "Regular 14x18",
  },
  {
    label: CREATE_WO_SCREEN_TYPE_NO_SEE_UMS,
    value: "No-See-Ums 20/20",
  },
  {
    label: CREATE_WO_SCREEN_TYPE_SUPER_17X14,
    value: "17x14 Super Screen",
  },
  {
    label: CREATE_WO_SCREEN_TYPE_SUPER_17X20,
    value: "17x20 Super Screen",
  },
];

const inspectionOptions = [
  {
    label: GLOBAL_DASH,
    value: "",
  },
  {
    label: CREATE_WO_INSPECTION_LOW,
    value: "Low",
  },
  {
    label: CREATE_WO_INSPECTION_MEDIUM,
    value: "Medium",
  },
  {
    label: CREATE_WO_INSPECTION_HIGH,
    value: "High",
  },
];

const screwTypeOptions = [
  { label: GLOBAL_DASH, value: "" },
  { value: "Stainless", label: "Stainless" },
  { value: "Regular", label: "Regular" },
];

const areasList = [
  {
    label: CREATE_WO_AREA_GUTTER,
    value: `areaOfConcern.${CREATE_WO_AREA_GUTTER}`,
  },
  {
    label: CREATE_WO_AREA_SCREEN,
    value: `areaOfConcern.${CREATE_WO_AREA_SCREEN}`,
  },
  {
    label: CREATE_WO_AREA_ALUMINUM,
    value: `areaOfConcern.${CREATE_WO_AREA_ALUMINUM}`,
  },
  {
    label: CREATE_WO_AREA_PANELS,
    value: `areaOfConcern.${CREATE_WO_AREA_PANELS}`,
  },
  {
    label: CREATE_WO_AREA_CONCRETE,
    value: `areaOfConcern.${CREATE_WO_AREA_CONCRETE}`,
  },
  {
    label: CREATE_WO_AREA_DOOR,
    value: `areaOfConcern.${CREATE_WO_AREA_DOOR}`,
  },
  {
    label: CREATE_WO_AREA_SCREWS,
    value: `areaOfConcern.${CREATE_WO_AREA_SCREWS}`,
  },
];

export const WORK_ORDER_STATUSES = {
  PENDING: 'pending',
  IN_PROGRESS: 'in-progress',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  ON_SCHEDULE: 'on-schedule',
};

export const getStatusColor = (status) => {
  const colors = {
    [WORK_ORDER_STATUSES.PENDING]: 'bg-yellow-100 text-yellow-800',
    [WORK_ORDER_STATUSES.IN_PROGRESS]: 'bg-blue-100 text-blue-800',
    [WORK_ORDER_STATUSES.COMPLETED]: 'bg-green-100 text-green-800',
    [WORK_ORDER_STATUSES.CANCELLED]: 'bg-red-100 text-red-800',
    [WORK_ORDER_STATUSES.ON_SCHEDULE]: 'bg-purple-100 text-purple-800',
  };
  
  return colors[status] || colors[WORK_ORDER_STATUSES.PENDING];
};

export const getStatusLabel = (status) => {
  const labels = {
    [WORK_ORDER_STATUSES.PENDING]: 'Pending',
    [WORK_ORDER_STATUSES.IN_PROGRESS]: 'In Progress',
    [WORK_ORDER_STATUSES.COMPLETED]: 'Completed',
    [WORK_ORDER_STATUSES.CANCELLED]: 'Cancelled',
    [WORK_ORDER_STATUSES.ON_SCHEDULE]: 'On Schedule',
  };
  
  return labels[status] || labels[WORK_ORDER_STATUSES.PENDING];
};

export {
  colorOptions,
  screenTypeOptions,
  inspectionOptions,
  screwTypeOptions,
  areasList,
};
