import { axiosInstance } from 'config/axios';
import { endpoints } from '../../config/api';

// Document upload and management
const uploadDocument = async (formData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  
  const { data } = await axiosInstance.post(
    endpoints.companies.documents,
    formData,
    config
  );

  return data;
};

const getDocuments = async () => {
  const { data } = await axiosInstance.get(
    endpoints.companies.documents,
  );

  return data;
};

const deleteDocument = async (documentId) => {
  const { data } = await axiosInstance.delete(
    endpoints.companies.documentsDelete(documentId)
  );

  return data;
};

const getDocumentUrl = async (documentId) => {
  const { data } = await axiosInstance.get(
    endpoints.companies.documentsUrl(documentId)
  );

  return data;
};

// Company management
const getCompanyDetails = async () => {
  const response = await axiosInstance.get(
    `${endpoints.companies.base}/me`,
  );
  return response.data;
};

const updateCompany = async (companyData) => {
  const response = await axiosInstance.put(
    `${endpoints.companies.base}/me`,
    companyData,
  );
  return response.data;
};

export const companyService = {
  uploadDocument,
  getDocuments,
  deleteDocument,
  getDocumentUrl,
  getCompanyDetails,
  updateCompany
};
