import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid'
import { formatFullDate, generateInitials } from 'utils/commons';
import { Strong, Text } from 'components/catalyst/text';

const MessageCard = ({ message, length, index }) => {
  return (
    <li key={message.createdAt}>
      <div className="relative pb-8">
        {index !== length - 1 ? (
          <span aria-hidden="true" className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" />
        ) : null}

        <div className="relative flex items-start space-x-3">
          <div className="relative">
            <div
              className='flex size-10 items-center justify-center rounded-full bg-white dark:bg-zinc-900 border dark:border-zinc-400 ring-8 ring-white dark:ring-zinc-900'
            >
              <Text>
                <Strong className='dark:!text-white !text-zinc-900'>
                  {generateInitials(message.userId?.name)}
                </Strong>
              </Text>
            </div>

            <span className="absolute -bottom-2 -right-1 rounded-tl">
              <ChatBubbleLeftEllipsisIcon aria-hidden="true" className="size-5 text-zinc-600 dark:text-zinc-100" />
            </span>
          </div>

          <div className="min-w-0 flex-1">
            <div>
              <div>
                <Text className='!leading-5'>
                  <Strong>
                    {message.userId.name}
                  </Strong>
                </Text>
              </div>

              <Text className='!leading-5'>
                {formatFullDate(message.createdAt)}
              </Text>
            </div>
            <div className="mt-2">
              <Text className='!leading-5'>{message.message}</Text>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default MessageCard;
