// Module depenencies
import { XMarkIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from 'features/auth/authSlice';

// UI
import { Dialog, DialogActions, DialogBody, DialogTitle } from 'components/catalyst/dialog'
import { Button } from 'components/catalyst/button';
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from 'components/catalyst/fieldset';
import { Input } from 'components/catalyst/input';
import LoadingButton from 'components/common/LoadingButton';

// Utils
import wording from 'utils/wording';

const {
  PASSWORD_CHANGE_TITLE,
  PASSWORD_CHANGE_LABEL_CURRENT_PASSWORD,
  PASSWORD_CHANGE_LABEL_NEW_PASSWORD,
  PASSWORD_CHANGE_LABEL_CONFIRM_PASSWORD,
  PASSWORD_CHANGE_PLACEHOLDER_CURRENT_PASSWORD,
  PASSWORD_CHANGE_PLACEHOLDER_NEW_PASSWORD,
  PASSWORD_CHANGE_PLACEHOLDER_CONFIRM_PASSWORD,
  PASSWORD_CHANGE_ERROR_MISMATCH,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_BUTTON_LOADING,
  PASSWORD_CHANGE_BUTTON,
  ERROR_MESSAGE,
  ERROR_REQUIRED_FIELD,
  ERROR_INVALID_PASS_LENGTH,
  GLOBAL_CANCEL,
} = wording;

const ChangePasswordModal = ({ isOpen, onClose }) => {
  const { isLoading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const { register, handleSubmit, formState: { errors, isValid }, getValues, reset } = useForm({
    mode: 'onBlur',
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data) => {
    if (!isValid) {
      return
    }
    
    const { currentPassword, newPassword } = data;

    try {
      await dispatch(
        changePassword({
          currentPassword,
          newPassword
        })
      ).unwrap();

      toast.success(PASSWORD_CHANGE_SUCCESS);

      handleClose();
    } catch (error) {
      toast.error(error.response?.data?.message || ERROR_MESSAGE);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>
        <div className='flex items-center justify-between'>
          {PASSWORD_CHANGE_TITLE}

          <Button
            plain
            onClick={handleClose}
          >
            <XMarkIcon />
          </Button>
        </div>
      </DialogTitle>

      <DialogBody>
        <form>
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label>{PASSWORD_CHANGE_LABEL_CURRENT_PASSWORD}</Label>

                <Input
                  type="password"
                  name="currentPassword"
                  {...register('currentPassword', {
                    required: ERROR_REQUIRED_FIELD,
                  })}
                  placeholder={PASSWORD_CHANGE_PLACEHOLDER_CURRENT_PASSWORD}
                />

                {errors.currentPassword && (
                  <ErrorMessage>{errors.currentPassword.message}</ErrorMessage>
                )}
              </Field>

              <Field>
                <Label>{PASSWORD_CHANGE_LABEL_NEW_PASSWORD}</Label>

                <Input
                  type="password"
                  name="newPassword"
                  {...register('newPassword', {
                    required: ERROR_REQUIRED_FIELD,
                    validate: (value) =>
                      validator.isLength(value, { min: 8 }) || ERROR_INVALID_PASS_LENGTH,
                  })}
                  placeholder={PASSWORD_CHANGE_PLACEHOLDER_NEW_PASSWORD}
                />

                {errors.newPassword && (
                  <ErrorMessage>{errors.newPassword.message}</ErrorMessage>
                )}
              </Field>

              <Field>
                <Label>{PASSWORD_CHANGE_LABEL_CONFIRM_PASSWORD}</Label>

                <Input
                  type="password"
                  name="confirmPassword"
                  {...register('confirmPassword', {
                    required: ERROR_REQUIRED_FIELD,
                    validate: (value) =>
                      value === getValues('newPassword') || PASSWORD_CHANGE_ERROR_MISMATCH,
                  })}
                  placeholder={PASSWORD_CHANGE_PLACEHOLDER_CONFIRM_PASSWORD}
                />

                {errors.confirmPassword && (
                  <ErrorMessage>{errors.confirmPassword.message}</ErrorMessage>
                )}
              </Field>
            </FieldGroup>
          </Fieldset>
        </form>
      </DialogBody>

      <DialogActions>
        <Button outline onClick={handleClose}>
          {GLOBAL_CANCEL}
        </Button>

        <LoadingButton
          disabled={isLoading || !isValid}
          onClick={handleSubmit(onSubmit)}
          color='green'
          isLoading={isLoading}
          label={PASSWORD_CHANGE_BUTTON}
          loadingLabel={PASSWORD_CHANGE_BUTTON_LOADING}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
