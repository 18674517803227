// Module dependencies
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MapsProvider } from './contexts/MapsContext';

// UI
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ProjectView from './pages/ProjectView';
import Projects from './pages/Projects';
import NewProject from './pages/NewProject';
import WorkOrders from './pages/WorkOrders';
import CreateWorkOrder from './pages/WorkOrders/CreateWorkOrder';
import WorkOrderDetails from './pages/WorkOrders/WorkOrderDetails';
import Team from './pages/Team';
import Schedule from './pages/Schedule';
import Documents from './pages/Documents';
import MainLayout from 'components/layout/MainLayout';
import ForgotPassword from './pages/Login/ForgotPassword';
import ResetPassword from './pages/Login/ResetPassword';
import { featureConfig } from 'config/features';
import Notification from 'components/Notification';
import GlobalScheduleView from 'pages/WorkOrders/GlobalSchedulleView';
import PendingLocationsView from 'pages/WorkOrders/PendingLocationsView';

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  return user ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <MapsProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          >
            {featureConfig.dashboard ? (
              <Route index element={<Navigate to="/dashboard" replace />} />
            ) : (
              <Route index element={<Navigate to="/work-orders" replace />} />
            )}

            {featureConfig.dashboard && (
              <Route path="dashboard" element={<Dashboard />} />
            )}

            {featureConfig.projects && (
              <>
                <Route path="projects" element={<Projects />} />
                <Route path="projects/:projectId" element={<ProjectView />} />
                <Route path="projects/new" element={<NewProject />} />
              </>
            )}

            {featureConfig.workOrders && (
              <>
                <Route path="work-orders" element={<WorkOrders />} />
                <Route path="work-orders/new" element={<CreateWorkOrder />} />
                <Route path="work-orders/global-schedule" element={<GlobalScheduleView />} />
                <Route path="work-orders/pending-locations" element={<PendingLocationsView />} />
                <Route path="work-orders/:id" element={<WorkOrderDetails />} />
              </>
            )}

            {featureConfig.team && (
              <Route path="team" element={<Team />} />
            )}

            {featureConfig.schedule && (
              <Route path="schedule" element={<Schedule />} />
            )}

            {featureConfig.documents && (
              <Route path="documents" element={<Documents />} />
            )}

          
            {featureConfig.dashboard ? (
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            ) : (
              <Route path="*" element={<Navigate to="/work-orders" replace />} />
            )}
          </Route>

          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>

        <Notification />
      </Router>
    </MapsProvider>
  );
};

export default App;
