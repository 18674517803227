// UI
import { Button } from 'components/catalyst/button';
import { Heading } from 'components/catalyst/heading';
import Card from 'components/common/Card';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Text } from 'components/catalyst/text';
import UploadDocumentDialog from './UploadDocumentDialog';
import Loader from 'components/Loader';
import DocumentCard from 'components/documents/DocumentCard';

// Utils
import wording from 'utils/wording';

const WODetailDocumentsTab = ({
  workOrder,
  isLoading,
  handleOpenUploadModal,
  handleCloseUploadModal,
  handleDownloadDocument,
  handleDeleteButtonClick,
  showDeleteDocumentModal,
  handleCloseDeleteDocumentModal,
  isDeleting,
  handleDeleteDocument,
  showUploadDialog,
  isUploading,
  handleFileChange,
  handleUploadDocument,
  handleFileDescriptionChange,
  fileDescription,
  documentToDelete,
}) => {
  const {
    DOCUMENTS_TITLE,
    DOCUMENTS_UPLOAD,
    DOCUMENTS_EMPTY_STATE,
  } = wording;
  
  return (
    <>
      {isLoading ? (
        <div className='flex items-center justify-center w-full h-96'>
          <Loader />
        </div>
      ) : (
        <>
          <Card>
            <div className='flex justify-between items-center w-full'>
              <Heading level={2} className='pb-2'>
                {DOCUMENTS_TITLE}
              </Heading>

              <Button
                color='blue'
                onClick={handleOpenUploadModal}
              >
                <PlusIcon />
                {DOCUMENTS_UPLOAD}
              </Button>
            </div>

            <div className="py-4">
              {workOrder?.documents && workOrder?.documents.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {workOrder.documents.map((document) => (
                    <DocumentCard
                      key={document._id}
                      document={document}
                      handleDownload={handleDownloadDocument}
                      handleDeleteButtonClick={handleDeleteButtonClick}
                      showDeleteDocumentModal={showDeleteDocumentModal}
                      handleCloseDeleteDocumentModal={handleCloseDeleteDocumentModal}
                      isDeletingDocument={isDeleting}
                      handleDelete={handleDeleteDocument}
                      documentToDelete={documentToDelete}
                      pageKey='workorder-detail'
                    />
                  ))}
                </div>
              ) : (
                <Text>
                  {DOCUMENTS_EMPTY_STATE}
                </Text>
              )}
            </div>
          </Card>

          <UploadDocumentDialog
            showUploadDialog={showUploadDialog}
            handleCloseUploadModal={handleCloseUploadModal}
            isUploading={isUploading}
            handleFileChange={handleFileChange}
            handleUploadDocument={handleUploadDocument}      
            handleFileDescriptionChange={handleFileDescriptionChange}      
            fileDescription={fileDescription}      
          />
        </>
      )}
    </>
  );
};

export default WODetailDocumentsTab;
